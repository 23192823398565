import gql from 'graphql-tag';

export default gql`
  mutation UpdateSeparatedArtifact(
    $id: ID!
    $title: String
    $voivodeship_id: ID
    $county_id: ID
    $community_id: ID
    $city_id: ID
    $azp_id: ID
    $ordinal_number_in_azp: String
    $ordinal_number_in_city: String
    $research_date: Date
    $research_executive: String
    $field_inventory_number: String
    $excavation: String
    $section: String
    $hectare: String
    $are: Int
    $are_quarter: String
    $coordinates: String
    $natural_layer: String
    $mechanical_layer: String
    $object_number: Int
    $object_part: String
    $object_layer: String
    $artifact_type_id: ID
    $natural_resource_id: ID
    $description: String
    $dimensions: String
    $implementation_technique: String
    $preserved: Boolean!
    $preservation_state: String
    $value: String
    $literature: String
    $chronology_id: Int
    $archaeological_culture_id: String
    $inventory_entry_at: Date
    $notes: [NoteInput]
    $rentals: [RentalInput]
    $photos: [PhotoInput]
    $main_photo: PhotoInput
    $shelving_number: Int
    $shelf_number: Int
  ) {
    updateSeparatedArtifact(
      id: $id
      title: $title
      voivodeship_id: $voivodeship_id
      county_id: $county_id
      community_id: $community_id
      city_id: $city_id
      azp_id: $azp_id
      ordinal_number_in_azp: $ordinal_number_in_azp
      ordinal_number_in_city: $ordinal_number_in_city
      research_date: $research_date
      research_executive: $research_executive
      field_inventory_number: $field_inventory_number
      excavation: $excavation
      section: $section
      hectare: $hectare
      are: $are
      are_quarter: $are_quarter
      coordinates: $coordinates
      natural_layer: $natural_layer
      mechanical_layer: $mechanical_layer
      object_number: $object_number
      object_part: $object_part
      object_layer: $object_layer
      artifact_type_id: $artifact_type_id
      natural_resource_id: $natural_resource_id
      description: $description
      dimensions: $dimensions
      implementation_technique: $implementation_technique
      preserved: $preserved
      preservation_state: $preservation_state
      value: $value
      literature: $literature
      chronology_id: $chronology_id
      archaeological_culture_id: $archaeological_culture_id
      inventory_entry_at: $inventory_entry_at
      notes: $notes
      rentals: $rentals
      photos: $photos
      main_photo: $main_photo
      shelving_number: $shelving_number
      shelf_number: $shelf_number
    ) {
      id
      status
      catalog_id
      short_catalog_id
      title
      voivodeship {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      county {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      community {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      city {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      azp {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      ordinal_number_in_azp
      ordinal_number_in_city
      research_date
      research_executive
      field_inventory_number
      excavation
      section
      hectare
      are
      are_quarter
      coordinates
      natural_layer
      mechanical_layer
      object_number
      object_part
      object_layer
      artifact_type {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      natural_resource {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      description
      dimensions
      implementation_technique
      preserved
      preservation_state
      value
      literature
      chronology {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      archaeological_culture {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      inventory_entry_at
      type
      notes {
        id
        created_by_email
        content
        created_at
      }
      rentals {
        id
        rental_date
        renter
        return_date
      }
      created_by_id
      created_by_email
      status
      comment
      created_at
      published_at
      updated_at
      main_photo {
        id
        path
        description
      }
      photos {
        id
        path
        description
      }
      shelving_number
      shelf_number
    }
  }
`;
