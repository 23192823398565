// @flow
import { memo, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const track = (location: any) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID, {
      debug: !!process.env.REACT_APP_DEBUG,
    });
  }, []);

  useEffect(() => track(location), [location]);
};

export default (memo(Analytics): any);
