// @flow
import gql from 'graphql-tag';

export default (gql`
  query AllArtifactsExport($columns: [String!]!, $filter: ArtifactFilter, $order: String) {
    allArtifactsExport(columns: $columns, filter: $filter, order: $order) {
      csv_url
    }
  }
`: any);
