// @flow
import type { NotesType, NoteInputType } from '../../types';

const parseNotes = (notes: NotesType[]): NoteInputType[] => {
  if (notes) {
    return notes.map((note: NotesType): NoteInputType => ({ id: note.id, content: note.content }));
  }

  return [];
};

export default parseNotes;
