// @flow

export default {
  '/': {
    pl: '228',
    en: '230',
    de: '232',
  },
  '/about/faq': {
    pl: '234',
    en: '236',
    de: '238',
  },
  '/about/privacy': {
    pl: '212',
    en: '214',
    de: '216',
  },
  '/about/rules': {
    pl: '218',
    en: '220',
    de: '222',
  },
};
