// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'antd';
import styled, { css, withTheme } from 'styled-components';
import type { Theme } from 'styled-components';
import STATUS from '../../lib/constants/artifactStatus';
import { accessibility } from '../../lib/constants/themeModes';
import { darkBlueColor, biggerFontSize } from '../../lib/style/themes';
import type { StatusType } from '../../lib/types';

type PropsType = {
  status: StatusType,
  theme: Theme,
};

const StatusFormatter = (props: PropsType): React.Node => {
  const { status } = props;

  let translation;
  switch (status) {
    case STATUS.new:
      translation = (
        <StyledTag color="cyan">
          <FormattedMessage id="general.status.new" defaultMessage="Nowy" />
        </StyledTag>
      );
      break;
    case STATUS.draft:
      translation = (
        <StyledTag color="magenta">
          <FormattedMessage id="general.status.draft" defaultMessage="Szkic" />
        </StyledTag>
      );
      break;
    case STATUS.ready:
      translation = (
        <StyledTag color="orange">
          <FormattedMessage id="general.status.ready" defaultMessage="Do akceptacji" />
        </StyledTag>
      );
      break;
    case STATUS.published:
      translation = (
        <StyledTag color="blue">
          <FormattedMessage id="general.status.published" defaultMessage="Opublikowany" />
        </StyledTag>
      );
      break;
    case STATUS.rejected:
      translation = (
        <StyledTag color="red">
          <FormattedMessage id="general.status.rejected" defaultMessage="Odrzucony" />
        </StyledTag>
      );
      break;
    case STATUS.archived:
      translation = (
        <StyledTag color="green">
          <FormattedMessage id="general.status.archived" defaultMessage="Archiwum" />
        </StyledTag>
      );
      break;
    case STATUS.hidden:
      translation = (
        <StyledTag color="#afacac">
          <FormattedMessage id="general.status.hidden" defaultMessage="Ukryty" />
        </StyledTag>
      );
      break;
    case STATUS.missing:
      translation = (
        <StyledTag color="purple">
          <FormattedMessage id="general.status.missing" defaultMessage="Zaginiony" />
        </StyledTag>
      );
      break;
    case STATUS.destroyed:
      translation = (
        <StyledTag color="magenta">
          <FormattedMessage id="general.status.destroyed" defaultMessage="Zniszczony" />
        </StyledTag>
      );
      break;
    case STATUS.rented:
      translation = (
        <StyledTag color="cyan">
          <FormattedMessage id="general.status.rented" defaultMessage="Wypożyczony" />
        </StyledTag>
      );
      break;
    case STATUS.delegated:
      translation = (
        <StyledTag color="volcano">
          <FormattedMessage id="general.status.delegated" defaultMessage="Przekazany" />
        </StyledTag>
      );
      break;
    default:
      translation = <span>{status}</span>;
      break;
  }

  return translation;
};

const StyledTag = styled(Tag)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow !important;
      background-color: ${darkBlueColor} !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: black !important;
        background-color: white !important;
        border: 2px solid red !important;
      }
    `};
`;

export default (withTheme(StatusFormatter): React$ComponentType<$Diff<React$ElementConfig<any>, any>>);
