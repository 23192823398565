// @flow
import * as React from 'react';
import { Menu, Drawer } from 'antd';
import { Icon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import withUser from '../withUser';
import Breakpoints from '../../lib/constants/breakpoints';
import { darkBlueColor, biggerFontSize, smallerFontSize } from '../../lib/style/themes';
import routePaths from '../../lib/constants/routePaths';
import withRouter from '../../lib/utils/withRouter';

const MenuItem = Menu.Item;
const { SubMenu } = Menu;

type PropsType = {
  location: {
    pathname: string,
  },
};

type StateType = {
  showDrawer: boolean,
};

class NavigationDrawer extends React.PureComponent<PropsType, StateType> {
  state = {
    showDrawer: false,
  };

  showDrawer = () => {
    this.setState({
      showDrawer: true,
    });
  };

  onClose = () => {
    this.setState({
      showDrawer: false,
    });
  };

  render(): React.Node {
    const {
      location: { pathname },
    } = this.props;

    const artifactMassEditRegExp = pathToRegexp(routePaths.adminMassArtifactEdit);
    const isArtifactMassEditPath = artifactMassEditRegExp.exec(pathname);

    const artifactSeparatedEditRegExp = pathToRegexp(routePaths.adminSeparatedArtifactEdit);
    const isArtifactSeparatedEditPath = artifactSeparatedEditRegExp.exec(pathname);

    const artifactHistoricalEditRegExp = pathToRegexp(routePaths.adminHistoricalArtifactEdit);
    const isArtifactHistoricalEditPath = artifactHistoricalEditRegExp.exec(pathname);


    const artifactDetailRegExp = pathToRegexp(routePaths.artifactsDetails);
    const isArtifactDetailPath = artifactDetailRegExp.exec(pathname);

    const artifactsByCatalogIdDetailsRegExp = pathToRegexp(routePaths.artifactsByCatalogIdDetails);
    const isArtifactByCatalogIdDetailPath = artifactsByCatalogIdDetailsRegExp.exec(pathname);

    const dictionaryRegExp = pathToRegexp(routePaths.adminDictionary);
    const isDictionaryPath = dictionaryRegExp.exec(pathname);

    let selectedKeys: [string | null] = [pathname];

    const { showDrawer } = this.state;

    if (
      isArtifactMassEditPath
      || isArtifactSeparatedEditPath
      || isArtifactHistoricalEditPath
      || isArtifactDetailPath
      || isArtifactByCatalogIdDetailPath
      || isDictionaryPath) {
      selectedKeys = [null];
    }

    return (
      <React.Fragment>
        <StyledHamburgerIcon
          type={showDrawer ? 'menu-unfold' : 'menu-fold'}
          onClick={showDrawer ? this.onClose : this.showDrawer}
        />
        <StyledDrawer title="MENU" placement="right" closable={false} onClose={this.onClose} visible={showDrawer}>
          <Menu mode="inline" theme="dark" defaultSelectedKeys={selectedKeys} selectedKeys={selectedKeys}>
            <StyledSubMenu
              title={(
                <span>
                  <StyledIcon type="eye" />
                  <FormattedMessage id="Navigation.about.subMenu" defaultMessage="O archiwum" />
                </span>
)}
              tabIndex="0"
              key={routePaths.aboutFaq}
            >
              <MenuItem key={routePaths.aboutFaq}>
                <Link to={routePaths.aboutFaq} onClick={this.onClose}>
                  <FormattedMessage id="Navigation.about.faq" defaultMessage="Jak korzystać" />
                </Link>
              </MenuItem>
              <MenuItem key={routePaths.contact}>
                <a
                  href={routePaths.contact}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FormattedMessage id="Navigation.about.contact" defaultMessage="Formularz kontaktowy" />
                </a>
              </MenuItem>
            </StyledSubMenu>
            <StyledMenuItem key={routePaths.artifactsListHistorical} tabIndex="0">
              <Link to={routePaths.artifactsListHistorical} tabIndex="-1" onClick={this.onClose}>
                <Icon type="table" style={{ fontSize: '14px' }} />
                <FormattedMessage id="Navigation.listHistoricalView" defaultMessage="Katalog historyczny" />
              </Link>
            </StyledMenuItem>
            <StyledMenuItem key={routePaths.artifactsListArchaeological} tabIndex="0">
              <Link to={routePaths.artifactsListArchaeological} tabIndex="-1" onClick={this.onClose}>
                <Icon type="table" style={{ fontSize: '14px' }} />
                <FormattedMessage id="Navigation.listArchaeologicalView" defaultMessage="Katalog archeologiczny" />
              </Link>
            </StyledMenuItem>
          </Menu>
        </StyledDrawer>
      </React.Fragment>
    );
  }
}

const StyledDrawer = styled(Drawer)`
  margin-top: 70px;
  .ant-drawer-body {
    padding: 0;
  }

  @media (max-width: ${Breakpoints.tablet}) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }

  .ant-drawer-wrapper-body {
    height: calc(100% - 70px) !important;
  }
  .ant-drawer-content {
    background-color: ${darkBlueColor};
  }
  .ant-menu-item {
    height: auto !important;
  }
`;

const StyledSubMenu = styled(SubMenu)`
  font-weight: 600;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 14px 25px 4px !important;
  font-weight: 600;
`;

const StyledIcon = styled(Icon)`
  font-size: ${smallerFontSize} !important;
`;

const StyledHamburgerIcon = styled(Icon)`
  font-size: ${biggerFontSize} !important;
  position: absolute;
  color: white !important;
  top: 0;
  margin: 28px 0;
  right: 20px;
  display: none !important;
  @media (max-width: ${Breakpoints.tablet}) {
    display: flex !important;
  }
`;

export default (withRouter(withUser(injectIntl(NavigationDrawer))): any);
