// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';

import ReactHtmlParser from 'react-html-parser';
import { injectIntl, IntlShape } from 'react-intl';
import { Query } from 'react-apollo';
// Queries
import GET_STATIC_HTML from '../../lib/queries/getStaticHtml';
// Utils
import staticPageIds from '../../lib/constants/staticPageIds';
// Types
import type { StaticHTMLResponseType, QueryRenderProps } from '../../lib/types';

import { pageContainerStyle } from '../../lib/style/pageStyle';
import BreadCrumb from '../../components/BreadCrumb';
import LoadingSpin from '../../components/LoadingSpin';
import NoDataBecauseError from '../../components/NoDataBecauseError';

import { accessibility } from '../../lib/constants/themeModes';
import withRouter from '../../lib/utils/withRouter';

type PropsTypes = {
  className: string,
  intl: typeof IntlShape,
  location: {
    pathname: string,
  },
};

const parseHtml = (node: any): ?React.Node => {
  // remove <script> tags from HTML
  if (node.type === 'tag' && node.name === 'script') {
    return null;
  }

  return undefined;
};

class StaticPage extends React.PureComponent<PropsTypes, void> {
  render(): React.Node {
    // const { className } = this.props;

    const {
      className,
      intl: { locale },
      location: { pathname },
    } = this.props;

    return (
      <Query query={GET_STATIC_HTML} variables={{ id: staticPageIds[pathname][locale] }}>
        {({ loading, error, data }: QueryRenderProps<StaticHTMLResponseType>): React.Node => {
          if (loading) return <LoadingSpin tip="Loading..." />;
          if (error) return <NoDataBecauseError />;

          const htmlContent = data && data.staticHTML && data.staticHTML.content.rendered;

          return (
            <React.Fragment>
              <BreadCrumb breadcrumbs={pathname.substr(1).split('/')} />
              <StyledPageContainer>
                <StyledPageContent tabIndex="0">
                  <div className={className}>{ReactHtmlParser(htmlContent, { transform: parseHtml })}</div>
                </StyledPageContent>
              </StyledPageContainer>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

const StyledPageContent = styled.div`
  padding: 24px 32px;
  background-color: #fff;
  flex: 1;
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: #001528;
    `};
`;

const StyledPageContainer = styled.div`
  ${pageContainerStyle};
`;

const StyledStaticPage = styled(StaticPage)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
    line-height: 1.4;
    margin: 0 0 0.75em;
    font-weight: bold;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .alignleft {
    float: left;
    margin: 0.5em 1em 0.5em 0;
  }

  .alignright {
    float: right;
    margin: 0.5em 0 0.5em 1em;
  }

  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  h1,
  h2 {
    display: block;
  }

  h2 {
    font-size: 1.5em;
    margin-block-start: 0.92em;
    margin-block-end: 0.92em;
  }

  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  p,
  h4,
  h5,
  h6,
  li {
    font-size: 16px;
    line-height: 1.5;
  }

  a {
    color: rgba(0, 21, 41, 0.7);
    text-decoration: underline;
  }

  a:hover {
    color: rgba(0, 21, 41, 1);
    text-decoration: underline;
  }

  blockquote {
    margin: 20px 40px;
    color: #999;
    border-left: 2px solid #999;
    padding-left: 20px;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: #001528;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span,
      a,
      p,
      li,
      strong {
        color: yellow !important;
      }

      h1,
      h2 {
        font-size: 30px;
      }

      h3 {
        font-size: 20px;
      }

      p,
      li,
      span,
      a,
      strong,
      h4,
      h5,
      h6 {
        font-size: 18px !important;
      }
    `};
`;

export default (withRouter(injectIntl(StyledStaticPage)): any);
