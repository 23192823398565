// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Col, Row, Empty } from 'antd';
import GET_MASS_ARTIFACT from '../../../lib/queries/getMassArtifact';
import { pageContainerStyle } from '../../../lib/style/pageStyle';
import { StyledCard, StyledDetailsName, StyledDetail } from '../../../components/StyledComponents/StyledCard';
import StyledH2 from '../../../components/StyledComponents/StyledH2';
import type {
  ArtifactType, UserType, QueryRenderProps,
} from '../../../lib/types';
import artifactDataTranslationsMessages from '../../../lib/constants/artifactDataTranslationsMessages';

import LoadingSpin from '../../../components/LoadingSpin';
import ArtifactsDetailsCtaSection from './ArtifactsDetailsCtaSection';
import WarehouseLocationSection from './Sections/WarehouseLocationSection';
import AcceptRejectBar from './AcceptRejectBar';
import ContentForLoggedInUsers from '../../../components/ContentForLoggedInUsers';
import RenderButtonInBredcrumb from '../../../components/RenderButtonInBredcrumb';
import BreadCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';
import logo from '../../../lib/images/logo.svg';
import TranslatedValue from '../../../components/TranslatedValue/TranslatedValue';
import USER_LEVEL from '../../../lib/constants/userLevel';
import ARTIFACT_STATUS_TYPE from '../../../lib/constants/artifactStatus';
import LinkTo from '../../../components/LinkTo';
import NoDataBecauseError from '../../../components/NoDataBecauseError';
import ArtifactMassDetailedSection from './Sections/ArtifactMassDetailedSection';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  artifactIdUrl: number,
  handlePrint: Function,
  intl: typeof IntlShape,
  params: { artifactId: number, shortCatalogId?: number, type: number },
  prepareNotes: Function,
  prepareRentals: Function,
  user: ?UserType,
};

class ArtifactsMassDetails extends React.PureComponent<PropsType, void> {
  render(): React.Node {
    const {
      artifactIdUrl,
      handlePrint,
      user,
      prepareNotes,
      prepareRentals,
      params: { shortCatalogId: shortCatalogIdUrl, type: typeUrl },
    } = this.props;

    const url = window.location.href;

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['artifactsArchaeologicalList', 'artifactDetails']} />
        <StyledPageContainer>
          <Query
            query={GET_MASS_ARTIFACT}
            variables={{
              id: artifactIdUrl,
              isAdmin: user && !!user.email,
              shortCatalogId: shortCatalogIdUrl && typeUrl + shortCatalogIdUrl,
            }}
          >
            {({
              loading,
              error,
              data,
            }: QueryRenderProps<{
              massArtifact: ArtifactType,
            }>): React.Node => {
              if (loading) return <LoadingSpin tip="Loading..." />;
              if (error) return <NoDataBecauseError />;
              if (!data || !data.massArtifact) {
                return <Empty />;
              }

              const {
                // inventory_entry_at: inventoryEntryAt,
                // natural_resource: naturalResource,
                animal_bones: animalBones,
                archaeological_culture: archaeoCulture,
                are_quarter: areQuarter,
                are,
                azp,
                building_ceramics_fragments: buildingCeramicsFragments,
                catalog_id: catalogId,
                chronology,
                city,
                clay_dishes_fragments: clayDishesFragments,
                community,
                county,
                excavation,
                field_inventory_number: fieldInventoryNumber,
                flints,
                glass_fragments: glassFragments,
                glass_slag: glassSlag,
                hectare,
                human_bones: humanBones,
                id,
                iron_slag: ironSlag,
                leather_object_fragments: leatherObjectFragments,
                mechanical_layer: mechanicalLayer,
                metal_objects: metalObjects,
                natural_layer: naturalLayer,
                notes,
                object_layer: objectLayer,
                object_number: objectNumber,
                object_part: objectPart,
                ordinal_number_in_azp: ordinalNumberInAzp,
                ordinal_number_in_city: ordinalNumberInCity,
                other_mass_artifact_elements: otherMassArtifactElements,
                rentals,
                research_date: researchDate,
                research_executive: researchExecutive,
                section,
                shells,
                stove_tiles_fragments: stoveTilesFragments,
                threshing_floors: threshingFloors,
                title,
                type,
                voivodeship,
                shelving_number: shelvingNumber,
                shelf_number: shelfNumber,
              } = data.massArtifact;

              const preparedNotes = notes && prepareNotes(notes);
              const preparedRental = prepareRentals(rentals);

              const emptyMassArtifactValue = <FormattedMessage id="general.emptyValue" defaultMessage="brak" />;
              const artifactType = type && type.toLowerCase();

              const showAcceptRejectBar = user
                && user.level === USER_LEVEL.superAdmin
                && (data.massArtifact && data.massArtifact.status === ARTIFACT_STATUS_TYPE.ready);

              const detailsData = {
                otherMassArtifactElements,
                clayDishesFragments,
                stoveTilesFragments,
                buildingCeramicsFragments,
                threshingFloors,
                humanBones,
                animalBones,
                shells,
                ironSlag,
                glassSlag,
                glassFragments,
                metalObjects,
                flints,
                leatherObjectFragments,
                emptyMassArtifactValue,
                type,
              };

              return (
                <React.Fragment>
                  <RenderButtonInBredcrumb>
                    <ArtifactsDetailsCtaSection
                      artifactId={artifactIdUrl || id}
                      type={artifactType}
                      handlePrint={handlePrint}
                    />
                  </RenderButtonInBredcrumb>
                  <StyledH2 tabIndex="0">
                    <img src={logo} alt="Logo" />
                    <span>
                      {title} {catalogId && `- ${catalogId}`}
                    </span>
                  </StyledH2>
                  <Row gutter={12}>
                    <Col span={24}>
                      <StyledCard
                        className="show-print"
                        bordered={false}
                        title={<FormattedMessage id="ArtifactsDetailsContainer.url" defaultMessage="Link url" />}
                        tabIndex="0"
                      >
                        {url}
                      </StyledCard>
                    </Col>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="ArtifactsDetails.sectionDiscoveryPlaceTitle"
                            defaultMessage="Miejsce odkrycia - informacje ogólne"
                          />
)}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.voivodeship} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(voivodeship && <TranslatedValue value={voivodeship} />) || emptyMassArtifactValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.county} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(county && <TranslatedValue value={county} />) || emptyMassArtifactValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.community} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(community && <TranslatedValue value={community} />) || emptyMassArtifactValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.city} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(city && <TranslatedValue value={city} />) || emptyMassArtifactValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.ordinal_number_in_city} />
                        </StyledDetailsName>
                        <StyledDetail>: {ordinalNumberInCity || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.azp} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(azp && <TranslatedValue value={azp} />) || emptyMassArtifactValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.ordinal_number_in_azp} />
                        </StyledDetailsName>
                        <StyledDetail>: {ordinalNumberInAzp || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        {azp
                          && azp.value_pl
                          && ordinalNumberInAzp && (
                            <LinkTo
                              serviceUrl={process.env.REACT_APP_PLATFORM_ADDRESS}
                              linkPattern="sites/:azp/:ordinalNumberInAzp"
                              linkParams={{
                                azp: azp.value_pl,
                                ordinalNumberInAzp,
                              }}
                            >
                              <FormattedMessage
                                id="artifactsDetails.showAzpLink"
                                defaultMessage="Zobacz stanowisko archeologiczne"
                              />
                            </LinkTo>
                        )}
                      </StyledCard>
                    </Col>

                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="ArtifactsDetails.sectionDiscoveryDetailsTitle"
                            defaultMessage="Miejsce odkrycia - informacje szczegółowe"
                          />
)}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.excavation} />
                        </StyledDetailsName>
                        <StyledDetail>: {excavation || emptyMassArtifactValue}</StyledDetail>
                        <br />

                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.section} />
                        </StyledDetailsName>
                        <StyledDetail>: {section || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.hectare} />
                        </StyledDetailsName>
                        <StyledDetail>: {hectare || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.are} />
                        </StyledDetailsName>
                        <StyledDetail>: {are || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.are_quarter} />
                        </StyledDetailsName>
                        <StyledDetail>: {areQuarter || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.natural_layer} />
                        </StyledDetailsName>
                        <StyledDetail>: {naturalLayer || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.mechanical_layer} />
                        </StyledDetailsName>
                        <StyledDetail>: {mechanicalLayer || emptyMassArtifactValue}</StyledDetail>
                        <br />
                      </StyledCard>
                    </Col>
                  </Row>
                  <ContentForLoggedInUsers>
                    <WarehouseLocationSection
                      shelvingNumber={shelvingNumber}
                      shelfNumber={shelfNumber}
                      emptyValue={emptyMassArtifactValue}
                    />
                  </ContentForLoggedInUsers>
                  <ArtifactMassDetailedSection data={detailsData} />
                  <Row gutter={12}>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={<FormattedMessage id="ArtifactsDetails.sectionObjectTitle" defaultMessage="Obiekt" />}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage id="ArtifactsDetails.objectNumber" defaultMessage="Nr obiektu" />
                        </StyledDetailsName>
                        <StyledDetail>: {objectNumber || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage id="ArtifactsDetails.objectPart" defaultMessage="Część obiektu" />
                        </StyledDetailsName>
                        <StyledDetail>: {objectPart || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage id="ArtifactsDetails.objectLayer" defaultMessage="Warstwa obiektu" />
                        </StyledDetailsName>
                        <StyledDetail>: {objectLayer || emptyMassArtifactValue}</StyledDetail>
                        <br />
                      </StyledCard>
                    </Col>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={
                          <FormattedMessage id="ArtifactsDetails.sectionResearchesTitle" defaultMessage="Badania" />
                        }
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage id="ArtifactsDetails.researchesDate" defaultMessage="Data badań" />
                        </StyledDetailsName>
                        <StyledDetail>: {researchDate || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage
                            id="ArtifactsDetails.researchesExecutive"
                            defaultMessage="Wykonawca badań"
                          />
                        </StyledDetailsName>
                        <StyledDetail>: {researchExecutive || emptyMassArtifactValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage
                            id="ArtifactsDetails.researchesFieldInventoryNumbers"
                            defaultMessage="Nr inwentarza polowego"
                          />
                        </StyledDetailsName>
                        <StyledDetail>: {fieldInventoryNumber || emptyMassArtifactValue}</StyledDetail>
                        <br />
                      </StyledCard>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col lg={24}>
                      <StyledCard
                        bordered={false}
                        title={
                          <FormattedMessage id="ArtifactsDetails.sectionOriginTitle" defaultMessage="Pochodzenie" />
                        }
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <Row gutter={72}>
                          <Col xs={24} sm={12} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage id="ArtifactsDetails.archeoCultures" defaultMessage="Kultura" />
                            </StyledDetailsName>
                            <StyledDetail>
                              :{' '}
                              {(archaeoCulture && <TranslatedValue value={archaeoCulture} />) || emptyMassArtifactValue}
                            </StyledDetail>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage id="ArtifactsDetails.chronology" defaultMessage="Chronologia" />
                            </StyledDetailsName>
                            <StyledDetail>
                              : {(chronology && <TranslatedValue value={chronology} />) || emptyMassArtifactValue}
                            </StyledDetail>
                          </Col>
                        </Row>
                      </StyledCard>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col lg={24}>
                      <StyledCard
                        bordered={false}
                        title={
                          <FormattedMessage id="ArtifactsDetails.sectionRentalsTitle" defaultMessage="Wypożyczenia" />
                        }
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetail>{preparedRental}</StyledDetail>
                      </StyledCard>
                    </Col>
                  </Row>
                  <ContentForLoggedInUsers>
                    <StyledCard
                      className="no-print"
                      bordered={false}
                      title={<FormattedMessage id="ArtifactsDetails.creationTime" defaultMessage="Notatki" />}
                      headStyle={{ padding: '0 32px' }}
                      bodyStyle={{ padding: '24px 32px' }}
                    >
                      {preparedNotes}
                    </StyledCard>
                    {showAcceptRejectBar && (
                      <StyledCard>
                        <AcceptRejectBar />
                      </StyledCard>
                    )}
                  </ContentForLoggedInUsers>
                </React.Fragment>
              );
            }}
          </Query>
        </StyledPageContainer>
      </React.Fragment>
    );
  }
}

const StyledPageContainer = styled.div`
  ${pageContainerStyle};

  @media print {
    .ant-col-lg-12 {
      width: 50% !important;
      float: left;
    }
  }
`;

export default (withRouter(
  withUser(injectIntl(ArtifactsMassDetails)),
): React$ComponentType<$Diff<React$ElementConfig<any>, any>>);
