// @flow
import gql from 'graphql-tag';

export type TokenType = {
  value: String,
};

export default (gql`
  query($code: String!) {
    token(code: $code) {
      value
    }
  }
`: any);
