import gql from 'graphql-tag';

export default gql`
  query AllArtifactChanges($filter: ArtifactChangeFilter, $paginator: Paginator) {
    allArtifactChanges(filter: $filter, paginator: $paginator) {
      total
      data {
        id
        changed_by_email
        created_at
      }
    }
  }
`;
