// @flow
import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Query } from 'react-apollo';
import CURRENT_USER_QUERY from '../../lib/queries/CurrentUser';
import type { UserType, QueryRenderProps } from '../../lib/types';
import LoadingSpin from '../LoadingSpin';
import withUser from '../withUser';

type Props = {|
  children?: React.Node,
  level?: number,
  user: UserType
|};

const PrivateRoute = ({ children, user, level }: Props): React.Node => (
  /* https://github.com/apollographql/apollo-link-state/issues/236 */
  <Query query={CURRENT_USER_QUERY} fetchPolicy="cache-first">
    {({ loading, error, data }: QueryRenderProps<{ current_user: UserType }>): ?React.Node => {
      if (loading) return <LoadingSpin tip="Loading..." />;
      if (error) return 'Error {error}';

      if (level && user.level !== level) {
        return (
          <Navigate to="/401" />
        );
      }

      if (data && data.current_user && data.current_user.name) {
        if (children) return children;

        return <Outlet />;
      }

      return (
        <Navigate to="/401" />
      );
    }}
  </Query>
);

PrivateRoute.defaultProps = {
  children: null,
  level: null,
};

export default (withUser(PrivateRoute): any);
