// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css, withTheme } from 'styled-components';
import type { Theme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { Icon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import ContentForLoggedInUsers from '../../../../components/ContentForLoggedInUsers/ContentForLoggedInUsers';
import { accessibility } from '../../../../lib/constants/themeModes';
import { darkBlueColor, biggerFontSize } from '../../../../lib/style/themes';
import PAGE_CONFIG from '../../../../config/pageConfig';
import LinkTo from '../../../../components/LinkTo/LinkTo';

const ArtifactsDetailsCtaSection = ({
  artifactId,
  type,
  handlePrint,
}: {
  artifactId: string,
  handlePrint: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  theme: Theme,
  type: string,
}): React.Node => {
  const path = window.location.pathname;

  return (
    <React.Fragment>
      <ContentForLoggedInUsers>
        <React.Fragment>
          <Link to={`/admin/${type}artifact/${artifactId}/edit`} state={{ prevPath: path }}>
            <StyledButton type="primary">
              <Icon type="edit" />
              &nbsp;&nbsp;
              <FormattedMessage id="general.edit" defaultMessage="Edytuj" />
            </StyledButton>
          </Link>
          <LinkTo linkPattern={PAGE_CONFIG.artifactsChangeLogPage.path} linkParams={{ artifactId, type }}>
            <StyledButton>
              <Icon type="hourglass" />
              &nbsp;&nbsp;
              <FormattedMessage id="ArtifactsDetailsCtaSection.historyOfChanges" defaultMessage="Historia zmian" />
            </StyledButton>
          </LinkTo>
          <LinkTo
            linkPattern={PAGE_CONFIG.artifactsLabelPrintPage.path}
            linkParams={{ artifactId, type }}
            linkState={{ prevPath: path }}
          >
            <StyledButton>
              <Icon type="printer" />
              &nbsp;&nbsp;
              <FormattedMessage id="ArtifactsDetailsCtaSection.printLabel" defaultMessage="Etykieta" />
            </StyledButton>
          </LinkTo>
        </React.Fragment>
      </ContentForLoggedInUsers>
      <StyledButton onClick={handlePrint}>
        <Icon type="printer" />
        &nbsp;&nbsp;
        <FormattedMessage id="ArtifactsDetailsCtaSection.printView" defaultMessage="Drukuj" />
      </StyledButton>
    </React.Fragment>
  );
};

const StyledButton = styled(Button)`
  margin-left: 10px;
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow !important;
      background-color: ${darkBlueColor} !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: black !important;
        background-color: white !important;
        border: 2px solid red !important;
      }
    `};
`;

export default (withTheme(ArtifactsDetailsCtaSection): React$ComponentType<$Diff<React$ElementConfig<any>, any>>);
