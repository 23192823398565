// @flow
import * as React from 'react';
import { withTheme } from 'styled-components';
import { accessibility } from '../../lib/constants/themeModes';

type PropsType = {
  children: React.Node,
  component: React.ComponentType<any>,
  fallbackTo?: string,
  theme: {
    mode: mixed,
  },
};

const FallbackInA11y = (props: PropsType): React.Node => {
  const {
    children,
    theme: { mode },
    component,
    fallbackTo = 'div',
    ...rest
  } = props;

  const Component = mode === accessibility
    ? React.createElement(fallbackTo, null, children)
    : React.createElement(component, rest, children);

  return Component;
};

export default (withTheme(FallbackInA11y): React$ComponentType<$Diff<React$ElementConfig<any>, any>>);
