// @flow
type ReturnType = {
  dataWithLocalId: any,
  lastLocalId: number,
};

const addsLocalIds = (data: []): ReturnType => {
  let localId = -1;
  if (data) {
    const dataWithLocalId = data.map(
      (item: any): any => {
        localId += 1;

        return { ...item, localId };
      },
    );

    return { dataWithLocalId, lastLocalId: localId };
  }

  return { dataWithLocalId: [], lastLocalId: localId };
};

export default addsLocalIds;
