// @flow
import * as React from 'react';
import {
  Col, Input, Row, Button, Popconfirm,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  FormattedMessage, defineMessages, injectIntl, IntlShape,
} from 'react-intl';
import styled from 'styled-components';
// Form Validation
import { required } from '../../../../lib/formValidation';
// Own Types
import type { NotesType } from '../../../../lib/types';

const FormItem = Form.Item;
const { TextArea } = Input;

type PropsTypes = {
  autoSize: {
    maxRows: number,
    minRows: number,
  },
  getFieldDecorator: Function,
  getFieldValue: Function,
  handleRemoveNote: (note: NotesType) => Promise<void>,
  intl: typeof IntlShape,
};

const translations = defineMessages({
  removeQuestion: {
    defaultMessage: 'Czy chcesz usunąć notatkę?',
    id: 'NotesField.removeQuestion',
  },
  yes: {
    defaultMessage: 'Tak',
    id: 'general.yes',
  },
  no: {
    defaultMessage: 'Nie',
    id: 'general.no',
  },
});

const NotesField = (props: PropsTypes): React.Node => {
  const {
    getFieldValue, getFieldDecorator, handleRemoveNote, autoSize, intl,
  } = props;
  const name = 'notes';

  const notesForm = getFieldValue('notesForm') || [];

  return notesForm.map(
    (note: NotesType, noteIndex: number): React.Node => {
      const { localId } = note;

      return (
        <StyledRow gutter={24} type="flex" align="middle" justify="space-between" key={localId}>
          {note.id && (
            <HiddenFormItem>
              {getFieldDecorator(`${name}[${noteIndex}].id`, {
                initialValue: note.id,
              })(<Input />)}
            </HiddenFormItem>
          )}
          <HiddenFormItem>
            {getFieldDecorator(`${name}[${noteIndex}].localId`, {
              initialValue: localId,
            })(<Input />)}
          </HiddenFormItem>
          <Col xs={18} sm={18} md={20} lg={22}>
            <StyledFormItem>
              {getFieldDecorator(`${name}[${noteIndex}].content`, {
                initialValue: note.content,
                rules: [required],
              })(<TextArea autoSize={autoSize} />)}
            </StyledFormItem>
          </Col>
          <FormattedMessage id="general.remove" defaultMessage="Usuń">
            {(buttonText: string): React.Node => (
              <Popconfirm
                title={intl.formatMessage(translations.removeQuestion)}
                onConfirm={(): Promise<void> => handleRemoveNote(note)}
                okText={intl.formatMessage(translations.yes)}
                cancelText={intl.formatMessage(translations.no)}
              >
                <StyledButton>{buttonText}</StyledButton>
              </Popconfirm>
            )}
          </FormattedMessage>
        </StyledRow>
      );
    },
  );
};

const StyledRow = styled(Row)`
  margin-bottom: 15px;
`;

const StyledButton = styled(Button)`
  margin-right: 15px;
`;

const HiddenFormItem = styled(FormItem)`
  display: none !important;
`;

const StyledFormItem = styled(FormItem)`
  flex: 1;
  margin-right: 15px !important;
  margin-bottom: 0 !important;
`;

export default (injectIntl(NotesField): any);
