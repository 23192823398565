// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  FormattedMessage, defineMessages, injectIntl, IntlShape,
} from 'react-intl';
import {
  Card, Row, Col, Input,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

const FormItem = Form.Item;

type PropTypes = {
  getFieldDecorator: Function,
  intl: typeof IntlShape,
  objectLayer: string,
  objectNumber: string,
  objectPart: string,
};

const translations = defineMessages({
  arabicNumber: {
    defaultMessage: 'Cyfra arabska',
    id: 'Object.placeholder.arabicNumber',
  },
  numberOrLetter: {
    defaultMessage: 'Cyfra lub litera alfabetu',
    id: 'Object.placeholder.numberOrLetter',
  },
});

const ObjectSectionSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator, intl, objectLayer, objectPart, objectNumber,
  } = props;

  const arabicNumber = intl.formatMessage(translations.arabicNumber);
  const numberOrLetter = intl.formatMessage(translations.numberOrLetter);

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          <FormattedMessage id="Object.title" defaultMessage="Obiekt" />
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col xs={24} sm={12} lg={8}>
          <FormItem label={<FormattedMessage id="Object.number" defaultMessage="Nr obiektu" />}>
            {getFieldDecorator('object_number', {
              initialValue: objectNumber || null,
            })(<Input type="number" placeholder={arabicNumber} />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <FormItem label={<FormattedMessage id="Object.part" defaultMessage="Część obiektu" />}>
            {getFieldDecorator('object_part', {
              initialValue: objectPart || null,
            })(<Input placeholder={numberOrLetter} />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <FormItem label={<FormattedMessage id="Object.layer" defaultMessage="Warstwa obiektu" />}>
            {getFieldDecorator('object_layer', {
              initialValue: objectLayer || null,
            })(<Input placeholder={numberOrLetter} />)}
          </FormItem>
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default (injectIntl(ObjectSectionSection): any);
