// @flow
import { camelCase, upperFirst } from 'lodash';
import client from '../../../config/apollo';
import GET_LANGUAGE_QUERY from '../../queries/getLanguage';
import artifactListTranslatedColumns from '../../constants/artifactListTranslatedColumns';

const prepareColumnSortOrder = (sorter: { field: string, order: string }): ?string => {
  const { field, order } = sorter;
  if (!field) return null;

  const { language } = client.readQuery({
    query: GET_LANGUAGE_QUERY,
  });

  const orderShortcut = order === 'descend' ? 'DESC' : 'ASC';
  const columnSortOrder = `${camelCase(field)}_${
    artifactListTranslatedColumns.includes(field) ? `value${upperFirst(language)}_` : ''
  }${orderShortcut}`;

  return columnSortOrder;
};

export default prepareColumnSortOrder;
