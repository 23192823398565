// @flow
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Card, Row, Col } from 'antd';
// Own Component
import DictionarySelect from '../../DictionarySelect';
import dictionariesId from '../../../lib/constants/dictionariesId';
// Types
import type { DicoveryType } from '../../../lib/types';

type PropTypes = {
  archaeologicalCulture: DicoveryType | null,
  chronology: DicoveryType | null,
  getFieldDecorator: Function,
  setFieldsValue: Function,
};

const OriginSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator, setFieldsValue, archaeologicalCulture, chronology,
  } = props;
  const { archaeologicalCultureId, chronologyId } = dictionariesId;

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          <FormattedMessage id="OriginSection.title" defaultMessage="Pochodzenie" />
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col xs={24} sm={12} lg={8}>
          <DictionarySelect
            dictionaryId={archaeologicalCultureId}
            label={<FormattedMessage id="OriginSection.culture" defaultMessage="Kultura" />}
            name="archaeological_culture_id"
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            initialFieldValue={archaeologicalCulture || null}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <DictionarySelect
            dictionaryId={chronologyId}
            label={<FormattedMessage id="OriginSection.chronology" defaultMessage="Chronologia" />}
            name="chronology_id"
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            initialFieldValue={chronology || null}
          />
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default OriginSection;
