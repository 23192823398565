// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Select, Button, Popconfirm, Spin,
} from 'antd';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import StatusFormatter from '../StatusFormatter/StatusFormatter';
import withUser from '../withUser';
import USER_LEVEL from '../../lib/constants/userLevel';
import CHANGE_ARTIFACT_STATUS from '../../lib/mutations/changeArtifactStatus';
import ARTIFACT_STATUS from '../../lib/constants/artifactStatus';

import type { StatusType, UserType } from '../../lib/types';
import withRouter from '../../lib/utils/withRouter';

type PropsType = {
  client: any,
  params: { artifactId?: string },
  status: StatusType,
  user: UserType,
};

type StateType = {
  loading: boolean,
  showChanger: boolean,
  status: StatusType,
};

const { Option } = Select;

class StatusChanger extends React.PureComponent<PropsType, StateType> {
  static statusIsNotChangeable = (status: StatusType): boolean => {
    return StatusChanger.notChangeableStatuses.includes(status);
  };

  constructor(props: PropsType) {
    super(props);
    const { status } = props;

    this.state = {
      status,
      showChanger: false,
      loading: false,
    };
  }

  handleOnChange = (value: StatusType) => {
    this.setState({
      status: value,
    });
  };

  handleSubmit = async (): Promise<void> => {
    const {
      client,
      params: { artifactId },
      status: statusFromProps,
    } = this.props;
    const { status } = this.state;

    if (status === statusFromProps) return;

    this.setState({ loading: true });
    await client.mutate({
      mutation: CHANGE_ARTIFACT_STATUS,
      variables: {
        id: artifactId,
        status,
      },
    });

    await this.setState({
      loading: false,
      showChanger: false,
    });
  };

  userIsSuperAdmin(): boolean {
    const { user } = this.props;

    return user.level === USER_LEVEL.superAdmin;
  }

  toggleShowChanger() {
    const { showChanger } = this.state;
    this.setState({
      showChanger: !showChanger,
    });
  }

  static notChangeableStatuses = [
    ARTIFACT_STATUS.new,
    ARTIFACT_STATUS.draft,
    ARTIFACT_STATUS.ready,
    ARTIFACT_STATUS.rejected,
    ARTIFACT_STATUS.rented,
  ];

  render(): React.Node {
    const { status: statusFromProps } = this.props;
    const { showChanger, status, loading } = this.state;

    if (StatusChanger.statusIsNotChangeable(status) || !this.userIsSuperAdmin()) {
      return (
        <Container>
          <StatusFormatter status={status} />
        </Container>
      );
    }

    if (!showChanger) {
      return (
        <Container>
          <StatusFormatter status={status} />
          <Button size="small" type="dashed" onClick={(): void => this.toggleShowChanger()} tabIndex="0">
            <FormattedMessage id="StatusChanger.change" defaultMessage="zmień" />
          </Button>
        </Container>
      );
    }

    return (
      <Spin spinning={loading}>
        <Container>
          <Select value={status} onChange={this.handleOnChange}>
            <Option value={ARTIFACT_STATUS.archived}>
              <FormattedMessage id="general.status.archived" defaultMessage="Archiwum" />
            </Option>
            <Option value={ARTIFACT_STATUS.missing}>
              <FormattedMessage id="general.status.missing" defaultMessage="Zaginiony" />
            </Option>
            <Option value={ARTIFACT_STATUS.destroyed}>
              <FormattedMessage id="general.status.destroyed" defaultMessage="Zniszczony" />
            </Option>
            <Option value={ARTIFACT_STATUS.hidden}>
              <FormattedMessage id="general.status.hidden" defaultMessage="Ukryty" />
            </Option>
            <Option value={ARTIFACT_STATUS.delegated}>
              <FormattedMessage id="general.status.delegated" defaultMessage="Przekazany" />
            </Option>
          </Select>
          <Popconfirm
            title={(
              <FormattedMessage
                id="StatusChanger.areYouSureToChangeStatus"
                defaultMessage="Czy na pewno chcesz zmienić status?"
              />
)}
            onConfirm={this.handleSubmit}
            okText={<FormattedMessage id="general.yes" defaultMessage="Tak" />}
            cancelText={<FormattedMessage id="general.no" defaultMessage="Nie" />}
          >
            <Button type="primary" disabled={statusFromProps === status}>
              <FormattedMessage id="StatusChanger.change" defaultMessage="zmień" />
            </Button>
          </Popconfirm>
        </Container>
      </Spin>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-item: center;
  margin-top: 11px;
  margin-bottom: 35px;
`;

export default (withApollo(withUser(withRouter(StatusChanger))): any);
