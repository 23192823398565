// @flow
import * as React from 'react';
import {
  Col, Input, Row, Button, Popconfirm,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  FormattedMessage, defineMessages, injectIntl, IntlShape,
} from 'react-intl';
import styled from 'styled-components';
// Own Types
import type { OtherMassArtifactElementInputType } from '../../../../../lib/types';
// Form Validation
import { required } from '../../../../../lib/formValidation';

const FormItem = Form.Item;

type PropsTypes = {
  getFieldDecorator: Function,
  getFieldValue: Function,
  handleRemoveOtherMassArtifactElement: (otherMassArtifactElement: OtherMassArtifactElementInputType) => Promise<void>,
  intl: typeof IntlShape,
};

const translations = defineMessages({
  removeQuestion: {
    defaultMessage: 'Czy chcesz usunąć element?',
    id: 'MonumentOtherField.removeQuestion',
  },
  yes: {
    defaultMessage: 'Tak',
    id: 'general.yes',
  },
  no: {
    defaultMessage: 'Nie',
    id: 'general.no',
  },
});

const MonumentOtherField = (props: PropsTypes): React.Node => {
  const {
    getFieldValue, getFieldDecorator, handleRemoveOtherMassArtifactElement, intl,
  } = props;
  const name = 'other_mass_artifact_elements';

  const otherMassArtifactElements = getFieldValue('otherMassArtifactElementsForm') || [];

  return otherMassArtifactElements.map(
    (otherMassArtifactElement: OtherMassArtifactElementInputType): React.Node => {
      const { localId } = otherMassArtifactElement;

      return (
        <React.Fragment key={localId}>
          <Row gutter={48} type="flex" align="middle" justify="space-between">
            <HiddenFormItem>
              {getFieldDecorator(`${name}[${localId}].localId`, {
                initialValue: localId,
              })(<Input />)}
            </HiddenFormItem>
            {otherMassArtifactElement.id && (
              <HiddenFormItem>
                {getFieldDecorator(`${name}[${localId}].id`, {
                  initialValue: otherMassArtifactElement.id,
                })(<Input />)}
              </HiddenFormItem>
            )}
            <Col span={8}>
              <FormItem label={<FormattedMessage id="MonumentOtherField.description" defaultMessage="Opis" />}>
                {getFieldDecorator(`${name}[${localId}].description`, {
                  initialValue: otherMassArtifactElement.description,
                  rules: [required],
                })(<Input />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={<FormattedMessage id="MonumentOtherField.quantity" defaultMessage="Ilość" />}>
                {getFieldDecorator(`${name}[${localId}].quantity`, {
                  initialValue: otherMassArtifactElement.quantity,
                  rules: [required],
                })(<Input type="number" />)}
              </FormItem>
            </Col>
            <StyledCol span={8}>
              <FormattedMessage id="general.remove" defaultMessage="Usuń">
                {(buttonText: string): React.Node => (
                  <Popconfirm
                    title={intl.formatMessage(translations.removeQuestion)}
                    onConfirm={(): Promise<void> => handleRemoveOtherMassArtifactElement(otherMassArtifactElement)}
                    okText={intl.formatMessage(translations.yes)}
                    cancelText={intl.formatMessage(translations.no)}
                  >
                    <StyledButton>{buttonText}</StyledButton>
                  </Popconfirm>
                )}
              </FormattedMessage>
            </StyledCol>
          </Row>
        </React.Fragment>
      );
    },
  );
};

const StyledCol = styled(Col)`
  display: flex !important;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-right: 15px;
`;

const HiddenFormItem = styled(FormItem)`
  display: none !important;
`;

export default (injectIntl(MonumentOtherField): any);
