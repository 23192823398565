// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  FormattedMessage, defineMessages, IntlShape, injectIntl,
} from 'react-intl';
import {
  Card, Row, Col, Input, DatePicker,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

const FormItem = Form.Item;

type PropTypes = {
  acquisitionDate: string,
  acquisitionMethod: string,
  getFieldDecorator: Function,
  intl: typeof IntlShape,
};

const translations = defineMessages({
  dataPlaceholder: {
    defaultMessage: 'RRRR-MM-DD',
    id: 'ResearchesSection.datapicker.placeholder',
  },
});

const AcquisitionSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator, intl, acquisitionMethod, acquisitionDate,
  } = props;

  const datePickerPlaceholder = intl.formatMessage(translations.dataPlaceholder);

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          <FormattedMessage id="AcquisitionSection.acquisition" defaultMessage="Nabycie" />
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col span={16}>
          <FormItem
            label={<FormattedMessage id="AcquisitionSection.acquisitionMethod" defaultMessage="Sposób nabycia" />}
          >
            {getFieldDecorator('acquisition_method', {
              initialValue: acquisitionMethod || null,
            })(<Input />)}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label={<FormattedMessage id="AcquisitionSection.acquisitionDate" defaultMessage="Data nabycia" />}>
            {getFieldDecorator('acquisition_date', {
              initialValue: acquisitionDate,
            })(<DatePicker format="YYYY-MM-DD" placeholder={datePickerPlaceholder} />)}
          </FormItem>
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default (injectIntl(AcquisitionSection): any);
