// @flow
import GET_MY_WORK_ARTIFACTS_LIST_COLUMNS_QUERY from '../queries/getArtifactsMyWorkListColumns';
import {
  artifactsMyWorkListFloatingColumns,
  artifactsMyWorkListDefaultColumns,
} from '../constants/artifactsListFloatingColumns';

const artifactsMyWorkListColumns = {
  defaults: {
    artifactsMyWorkListColumns: {
      selectedColumns: artifactsMyWorkListDefaultColumns,
      orderedColumns: artifactsMyWorkListFloatingColumns,
      columnSortOrder: 'title_ASC',
      __typename: 'artifactsMyWorkListColumns',
    },
  },
  resolvers: {
    Mutation: {
      setArtifactsMyWorkListColumns: (
        _: any,
        {
          columnSortOrder,
          orderedColumns,
          selectedColumns,
        }: { columnSortOrder: string, orderedColumns: string[], selectedColumns: string[] },
        { cache }: { cache: Object },
      ): null => {
        const readedArtifactsMyWorkListColumnsData = cache.readQuery({
          query: GET_MY_WORK_ARTIFACTS_LIST_COLUMNS_QUERY,
        });

        cache.writeQuery({
          query: GET_MY_WORK_ARTIFACTS_LIST_COLUMNS_QUERY,
          data: {
            artifactsMyWorkListColumns: {
              selectedColumns: selectedColumns
                || readedArtifactsMyWorkListColumnsData.artifactsMyWorkListColumns.selectedColumns,
              orderedColumns: orderedColumns
                || readedArtifactsMyWorkListColumnsData.artifactsMyWorkListColumns.orderedColumns,
              columnSortOrder: columnSortOrder
                || readedArtifactsMyWorkListColumnsData.artifactsMyWorkListColumns.columnSortOrder,
              __typename: 'artifactsMyWorkListColumns',
            },
          },
        });

        return null;
      },
    },
  },
};

export default artifactsMyWorkListColumns;
