// @flow
import find from 'lodash/find';
import PAGE_CONFIGS from '../../config/pageConfig';
import type { TranslationType, PageConfigType } from '../../lib/types';

const missingTitle: TranslationType = {
  id: 'PageConfigMissingTitle',
  defaultMessage: 'Title is missing in pageConfig.js',
};

function getPageConfig(match: { path: string, url: string }): Object {
  const config = find(
    PAGE_CONFIGS,
    (c: PageConfigType): boolean => {
      if (c.url) {
        return c.url === match.url;
      }

      return c.path === match.path;
    },
  );

  if (!config) {
    return {
      showBreadcrumbs: false,
      helmetPageTitle: missingTitle,
      pageTitle: missingTitle,
    };
  }

  return {
    ...config,
    helmetPageTitle: config.helmetPageTitle || config.pageTitle,
  };
}

// eslint-disable-next-line import/prefer-default-export
export { getPageConfig };
