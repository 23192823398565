// @flow
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

// ======================================================
// React intl passes the messages and format functions down the component
// tree using the 'context' scope. the injectIntl HOC basically takes these out
// of the context and injects them into the props of the component. To be able to
// import this translation functionality as a module anywhere (and not just inside react components),
// this function inherits props & context from its parent and exports a singleton that'll
// expose all that shizzle.
// https://stackoverflow.com/questions/36648880/use-react-intl-translated-messages-in-redux-middleware
// ======================================================

type PropsType = {
  children: React.Node,
  intl: any,
};

let INTL;

const IntlGlobalProvider = (props: PropsType): React.Node => {
  const { children, intl } = props;
  INTL = intl;

  return children;
};

// ======================================================
// Class that exposes translations
// ======================================================
let instance: any;
class IntlTranslator {
  // Singleton
  constructor(): any {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  // ------------------------------------
  // Formatting Functions
  // ------------------------------------
  // eslint-disable-next-line
  formatMessage(message: typeof MessageDescriptor, values: Object): string {
    return INTL.formatMessage(message, values);
  }

  // eslint-disable-next-line
  formatNumber(value: any): string {
    return INTL.formatNumber(value);
  }
}

export const intl: IntlTranslator = new IntlTranslator();
export default IntlGlobalProvider;
