// @flow

const parseDictionaryValue = (dictionaryValue: ?string): null | string => {
  if (typeof dictionaryValue === 'undefined') {
    return null;
  }

  return dictionaryValue;
};

export default parseDictionaryValue;
