import gql from 'graphql-tag';

export default gql`
  mutation SetArtifactsArchaeologicalListColumns(
    $selectedColumns: Array, $orderedColumns: Array, $columnSortOrder: String) {
    setArtifactsArchaeologicalListColumns(
      selectedColumns: $selectedColumns
      orderedColumns: $orderedColumns
      columnSortOrder: $columnSortOrder
      __typename: "artifactsArchaeologicalListColumns"
    ) @client
  }
`;
