// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Spin } from 'antd';

import GET_ARTIFACT_CHANGE_QUERY from '../../../../lib/queries/getArtifactChange';
import ChangeLogDiff from '../../../../components/ChangeLogDiff';

import type { ArtifactChangeType, QueryRenderProps } from '../../../../lib/types';

type PropsType = {
  id: string,
};

class ArtifactsChangeLogDisplayer extends React.PureComponent<PropsType> {
  render(): React.Node {
    const { id } = this.props;

    return (
      <Query query={GET_ARTIFACT_CHANGE_QUERY} variables={{ filter: { id } }}>
        {({
          loading,
          error,
          data,
        }: QueryRenderProps<{ allArtifactChanges: { data: ArtifactChangeType[] } }>): React.Node => {
          if (error) return `Error! ${error.message}`;

          const change: ?ArtifactChangeType = data && data.allArtifactChanges && data.allArtifactChanges.data[0];

          return (
            <React.Fragment>
              {loading && <Spin style={{ position: 'absolute', right: '20px' }} />}
              {!change
                // eslint-disable-next-line max-len
                && !loading && <FormattedMessage id="ArtifactsChangeLogDisplayer.noData" defaultMessage="Brak danych" />}
              {change && (
                <React.Fragment>
                  <StyledProp>
                    <FormattedMessage id="ArtifactsChangeLogDisplayer.author" defaultMessage="Autor zmiany" />
                    :&nbsp;
                    {change.changed_by_email}
                  </StyledProp>
                  <StyledProp>
                    <FormattedMessage id="ArtifactsChangeLogDisplayer.data" defaultMessage="Data zmiany" />
                    :&nbsp;
                    {String(change.created_at)}
                  </StyledProp>
                  <ChangeLogDiff left={change.old} right={change.new} />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

const StyledProp = styled.div`
  display: flex;
  span {
    font-weight: bold;
  }
`;

export default ArtifactsChangeLogDisplayer;
