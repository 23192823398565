// @flow
import * as React from 'react';
import {
  FormattedMessage, injectIntl, defineMessages, IntlShape,
} from 'react-intl';
import {
  Button, Row, Col, Divider, message, Input,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import styled from 'styled-components';
// Own typed
import type { DictionaryEntryType } from '../../lib/types';
// Form Validation
import { required, min3Characters } from '../../lib/formValidation';
import dictionariesId from '../../lib/constants/dictionariesId';

const FormItem = Form.Item;

type FieldsErrorType = {
  name: string[],
};

type PropsType = {
  className: string,
  dictionaryData: DictionaryEntryType,
  dictionaryId: string,
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    resetFields: Function,
    setFieldsValue: Function,
    validateFields: Function,
  },
  handleClose: Function,
  intl: typeof IntlShape,
  onSubmit: (val: DictionaryEntryType) => void,
  uniqueDictionaryValidator: () => Promise<void>,
};

class DictionaryEditForm extends React.PureComponent<PropsType, void> {
  static hasErrors(fieldsError: FieldsErrorType): boolean {
    return Object.keys(fieldsError).some((field: string): FieldsErrorType => fieldsError[field]);
  }

  handleSubmit = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    const {
      onSubmit,
      form: { validateFields, resetFields },
      intl,
      handleClose,
    } = this.props;

    const translations = defineMessages({
      submitSuccessMessage: {
        defaultMessage: 'Słownik został zaktualizowany',
        id: 'DictionaryEditForm.successUpdateMessage',
      },
    });

    const submitSuccessMessage = intl.formatMessage(translations.submitSuccessMessage);

    validateFields((errors: string, values: DictionaryEntryType) => {
      if (!errors) {
        onSubmit(values);
        message.success(submitSuccessMessage, 0.5).then(() => {
          handleClose();
          resetFields();
        });
      }
    });
  };

  render(): React.Node {
    const {
      form: { getFieldDecorator },
      handleClose,
      className,
      dictionaryData,
      uniqueDictionaryValidator,
      dictionaryId,
    } = this.props;

    const isCityDictionary = (dictionariesId.cityId === dictionaryId);

    return (
      <Form layout="vertical" onSubmit={this.handleSubmit} className={className}>
        <FormItem label={<FormattedMessage id="DictionaryEditForm.valuePL" defaultMessage="PL" />}>
          {getFieldDecorator('value_pl', {
            rules: [required, min3Characters, uniqueDictionaryValidator],
            initialValue: dictionaryData ? dictionaryData.value_pl : null,
          })(<Input />)}
        </FormItem>
        <FormItem label={<FormattedMessage id="DictionaryEditForm.valueEN" defaultMessage="EN" />}>
          {getFieldDecorator('value_en', {
            rules: [min3Characters],
            initialValue: dictionaryData ? dictionaryData.value_en : null,
          })(<Input />)}
        </FormItem>
        <FormItem label={<FormattedMessage id="DictionaryEditForm.valueDE" defaultMessage="DE" />}>
          {getFieldDecorator('value_de', {
            rules: [min3Characters],
            initialValue: dictionaryData ? dictionaryData.value_de : null,
          })(<Input />)}
        </FormItem>
        {isCityDictionary && (
          <FormItem label={<FormattedMessage id="DictionaryEditForm.cityCode" defaultMessage="Symbol miejscowość" />}>
            {getFieldDecorator('code', {
              rules: [required, min3Characters, uniqueDictionaryValidator],
              initialValue: dictionaryData ? dictionaryData.code : null,
            })(<Input />)}
          </FormItem>
        )}
        {dictionaryData.id && (
          <HiddenInputField label="id">
            {getFieldDecorator('id', {
              initialValue: dictionaryData.id,
            })(<Input disabled />)}
          </HiddenInputField>
        )}
        <Row>
          <Col>
            <FormattedMessage id="DictionaryEditForm.warning" defaultMessage="Uwaga:" />
            <br />
            <FormattedMessage
              id="DictionaryEditForm.warningDescription"
              defaultMessage="Jeżeli pola EN oraz DE nie zostaną uzupełnione przyjmują automatycznie wartość PL"
            />
          </Col>
        </Row>
        <Divider />

        <Button block type="primary" htmlType="submit" style={{ marginBottom: '5px' }}>
          <FormattedMessage id="general.approve" defaultMessage="Zatwierdź" />
        </Button>

        <Button block type="default" onClick={handleClose}>
          <FormattedMessage id="general.cancel" defaultMessage="Anuluj" />
        </Button>
      </Form>
    );
  }
}

const StyledDictionaryEditForm = styled(DictionaryEditForm)`
  width: 100%;
`;

const HiddenInputField = styled(FormItem)`
  display: none !important;
`;

export default (injectIntl(Form.create()(StyledDictionaryEditForm)): any);
