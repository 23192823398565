import gql from 'graphql-tag';

export default gql`
  query AllHistoricalArtifacts(
    $filter: HistoricalArtifactFilter, $paginator: Paginator, $order: String
  ) {
    allHistoricalArtifacts(filter: $filter, paginator: $paginator, order: $order) {
      total
      data {
        id
        title
        catalog_id
        short_catalog_id
        type
        artifact_type {
          id
          value_pl
          value_en
          value_de
        }
        city {
          id
          value_pl
          value_en
          value_de
        }
        voivodeship {
          id
          value_pl
          value_en
          value_de
        }
        county {
          id
          value_pl
          value_en
          value_de
        }
        community {
          id
          value_pl
          value_en
          value_de
        }
        natural_resource {
          id
          value_pl
          value_en
          value_de
        }
        created_at
        updated_at
        published_at
        status
        created_by_email
        shelving_number
        shelf_number
      }
    }
  }
`;
