// @flow
import * as React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Empty, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ReloadOutlined } from '@ant-design/icons';

const NoDataBecauseError = (): React.Node => {
  return (
    <StyledEmpty
      description={(
        <FormattedMessage
          id="NoDatabecauseError.description"
          defaultMessage="Z powodu wystapienia błędu nie można pobrać danych"
        />
)}
    >
      <Button icon={<LegacyIcon type={ReloadOutlined} />} onClick={(): void => window.location.reload()}>
        <FormattedMessage id="NoDatabecauseError.restartApp" defaultMessage="Wymuś restart aplikacji" />
      </Button>
    </StyledEmpty>
  );
};

const StyledEmpty = styled(Empty)`
  width: 100%;
  overflow: hidden;
  padding: 15px;
  background: white;
`;

export default NoDataBecauseError;
