// @flow
import { defineMessages } from 'react-intl';

const artifactDataTranslationsMessages: any = defineMessages({
  id: {
    defaultMessage: 'Id',
    id: 'artifact.id',
  },
  catalog_id: {
    defaultMessage: 'Nr katalogowy',
    id: 'artifact.catalog_id',
  },
  short_catalog_id: {
    defaultMessage: 'Skrócony nr katalogowy',
    id: 'artifact.short_catalog_id',
  },
  title: {
    defaultMessage: 'Tytuł',
    id: 'artifact.title',
  },
  type: {
    defaultMessage: 'Typ zabytku',
    id: 'artifact.type',
  },
  HISTORICAL: {
    defaultMessage: 'Historyczny',
    id: 'artifact.HISTORICAL',
  },
  MASS: {
    defaultMessage: 'Masowy',
    id: 'artifact.MASS',
  },
  SEPARATED: {
    defaultMessage: 'Wydzielony',
    id: 'artifact.SEPARATED',
  },
  city: {
    defaultMessage: 'Miejscowość',
    id: 'artifact.city',
  },
  cta: {
    defaultMessage: 'Przyciski',
    id: 'artifact.cta',
  },
  voivodeship: {
    defaultMessage: 'Województwo',
    id: 'artifact.voivodeship',
  },
  county: {
    defaultMessage: 'Powiat',
    id: 'artifact.county',
  },
  community: {
    defaultMessage: 'Gmina',
    id: 'artifact.community',
  },
  natural_resource: {
    defaultMessage: 'Surowiec',
    id: 'artifact.natural_resource',
  },
  status: {
    defaultMessage: 'Status',
    id: 'artifact.status',
  },
  created_at: {
    defaultMessage: 'Data utworzenia szkicu',
    id: 'artifact.created_at',
  },
  updated_at: {
    defaultMessage: 'Data aktualizacji',
    id: 'artifact.updated_at',
  },
  published_at: {
    defaultMessage: 'Data publikacji',
    id: 'artifact.published_at',
  },
  created_by_email: {
    defaultMessage: 'Autor szkicu',
    id: 'artifact.created_by_email',
  },
  ordinal_number_in_azp: {
    defaultMessage: 'Nr na AZP',
    id: 'artifact.ordinal_number_in_azp',
  },
  azp: {
    defaultMessage: 'AZP',
    id: 'artifact.azp',
  },
  ordinal_number_in_city: {
    defaultMessage: 'Nr w miejscowości',
    id: 'artifact.ordinal_number_in_city',
  },
  chronology: {
    defaultMessage: 'Chronologia',
    id: 'artifact.chronology',
  },
  archaeological_culture: {
    defaultMessage: 'Kultura',
    id: 'artifact.archaeological_culture',
  },
  artifact_type: {
    defaultMessage: 'Rodzaj zabytku',
    id: 'artifact.artifact_type',
  },
  excavation: {
    defaultMessage: 'Wykop',
    id: 'artifact.excavation',
  },
  section: {
    defaultMessage: 'Odcinek',
    id: 'artifact.section',
  },
  hectare: {
    defaultMessage: 'Hektar',
    id: 'artifact.hectare',
  },
  are: {
    defaultMessage: 'Ar',
    id: 'artifact.are',
  },
  are_quarter: {
    defaultMessage: 'Ćwiartka ara',
    id: 'artifact.are_quarter',
  },
  natural_layer: {
    defaultMessage: 'Warstwa naturalna',
    id: 'artifact.natural_layer',
  },
  mechanical_layer: {
    defaultMessage: 'Warstwa mechaniczna',
    id: 'artifact.mechanical_layer',
  },
  coordinates: {
    defaultMessage: 'Współrzędne geograficzne',
    id: 'artifact.coordinates',
  },
  shortCatalog: {
    defaultMessage: 'Skrócony nr katalogowy',
    id: 'artifact.shortCatalogId',
  },
  shelving_number: {
    defaultMessage: 'Nr regału',
    id: 'artifact.shelving_number',
  },
  shelf_number: {
    defaultMessage: 'Nr półki',
    id: 'artifact.shelf_number',
  },
  category: {
    defaultMessage: 'Kategoria',
    id: 'artifact.category',
  },
  quantity: {
    defaultMessage: 'Liczba',
    id: 'artifact.quantity',
  },
  research_date: {
    id: 'artifact.research_date',
    defaultMessage: 'Data badań',
  },
  research_executive: {
    id: 'artifact.research_executive',
    defaultMessage: 'Wykonawca badań',
  },
  field_inventory_number: {
    id: 'artifact.fieldInventoryNumbers',
    defaultMessage: 'Nr inwentarza polowego',
  },
  object_number: {
    id: 'artifact.object_number',
    defaultMessage: 'Numer obiektu',
  },
  object_part: {
    id: 'artifact.object_part',
    defaultMessage: 'Część obiektu',
  },
  object_layer: {
    id: 'artifact.object_layer',
    defaultMessage: 'Warstwa obiektu',
  },
  place_of_origin: {
    id: 'artifact.place_of_origin',
    defaultMessage: 'Miejsce Pochdzenia',
  },
  dimensions: {
    id: 'artifact.dimensions',
    defaultMessage: 'Wymiary',
  },
  implementation_technique: {
    id: 'artifact.implementation_technique',
    defaultMessage: 'Technika wykonania',
  },
  preserved: {
    id: 'artifact.preserved',
    defaultMessage: 'Konserwacja',
  },
  preservation_state: {
    id: 'artifact.preservation_state',
    defaultMessage: 'Stan zachowania',
  },
  value: {
    id: 'artifact.value',
    defaultMessage: 'Wartość',
  },
  creation_time: {
    id: 'artifact.creation_time',
    defaultMessage: 'Czas powstania',
  },
  creator: {
    id: 'artifact.creator',
    defaultMessage: 'Autor/wytwórca',
  },
  acquisition_method: {
    id: 'artifact.acquisition_method',
    defaultMessage: 'Sposób nabycia',
  },
  acquisition_date: {
    id: 'artifact.acquisition_date',
    defaultMessage: 'Data nabycia',
  },
  clay_dishes_fragments: {
    id: 'artifact.clay_dishes_fragments',
    defaultMessage: 'Fragmenty naczyń glinianych',
  },
  stove_tiles_fragments: {
    id: 'artifact.stove_tiles_fragments',
    defaultMessage: 'Fragmenty kafli piecowych',
  },
  building_ceramics_fragments: {
    id: 'artifact.building_ceramics_fragments',
    defaultMessage: 'Fragmenty ceramiki budowlanej',
  },
  threshing_floors: {
    id: 'artifact.threshing_floors',
    defaultMessage: 'Polepa',
  },
  human_bones: {
    id: 'artifact.human_bones',
    defaultMessage: 'Kości ludzkich',
  },
  animal_bones: {
    id: 'artifact.animal_bones',
    defaultMessage: 'Kości zwierzęce',
  },
  shells: {
    id: 'artifact.shells',
    defaultMessage: 'Muszle',
  },
  iron_slag: {
    id: 'artifact.iron_slag',
    defaultMessage: 'Żużle żelazne',
  },
  glass_slag: {
    id: 'artifact.glass_slag',
    defaultMessage: 'Żużle szklane',
  },
  glass_fragments: {
    id: 'artifact.glass_fragments',
    defaultMessage: 'Fragmenty szkła',
  },
  metal_objects: {
    id: 'artifact.metal_objects',
    defaultMessage: 'Przedmioty metalowe',
  },
  flints: {
    id: 'artifact.flints',
    defaultMessage: 'Krzemienie',
  },
  leather_object_fragments: {
    id: 'artifact.leather_object_fragments',
    defaultMessage: 'Fragmenty wyrobów skórzanych',
  },
  other_mass_artifact_elements: {
    id: 'artifact.other_mass_artifact_elements',
    defaultMessage: 'Inne elementy',
  },
  rentals: {
    id: 'artifact.rentals',
    defaultMessage: 'Wypożyczenia',
  },
  renter: {
    id: 'artifact.renter',
    defaultMessage: 'Osoba/Instytucja',
  },
  rental_date: {
    id: 'artifact.rental_date',
    defaultMessage: 'Data wypożyczenia',
  },
  return_date: {
    id: 'artifact.return_date',
    defaultMessage: 'Data zwrotu',
  },
});

export default artifactDataTranslationsMessages;
