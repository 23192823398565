// @flow
import * as React from 'react';
import { Button } from 'antd';
import { Icon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';
import styled, { css, withTheme } from 'styled-components';
import type { Theme } from 'styled-components';
import { withApollo } from 'react-apollo';
// Queries
import ALL_ARTIFACTS_EXPORT from '../../../../../lib/queries/allArtifactsExport';
import { accessibility } from '../../../../../lib/constants/themeModes';
import { biggerFontSize } from '../../../../../lib/style/themes';
import type { ArtifactFilterUrlType } from '../../../../../lib/types';
import getFilterType from './getFilterType';

type PropsType = {
  client: any,
  columnSortOrder: string,
  filter: ArtifactFilterUrlType,
  selectedColumns: string[],
  // eslint-disable-next-line react/no-unused-prop-types
  theme: Theme,
  type: string,
};

const CSVButton = (props: PropsType): React.Node => {
  const downloadCSV = async (): Promise<void> => {
    const {
      client, columnSortOrder, selectedColumns, filter, type,
    } = props;

    const filterType = getFilterType(type);

    const response = await client.query({
      query: ALL_ARTIFACTS_EXPORT,
      variables: { columns: selectedColumns, order: columnSortOrder, filter: { ...filter, type: filterType } },
      fetchPolicy: 'network-only',
    });

    if (response && response.data && response.data.allArtifactsExport) {
      const csvUrl = response.data.allArtifactsExport.csv_url;
      window.open(csvUrl, 'Download');
    }
  };

  return (
    <StyledButtonCSV type="primary" onClick={downloadCSV}>
      <Icon type="download" />
      <FormattedMessage id="CSVButton.donwload" defaultMessage="Pobierz CSV" />
    </StyledButtonCSV>
  );
};

const StyledButtonCSV = styled(Button)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: black !important;
      background-color: yellow !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: black !important;
        background-color: white !important;
        border: 2px solid red !important;
      }
    `};
`;

export default (withApollo(withTheme(CSVButton)): any);
