// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  Button, message, Card, Popconfirm,
} from 'antd';
import { Icon, Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import moment from 'moment';
// Layout style
import { pageContainerStyle } from '../../../lib/style/pageStyle';
// Own Types
import type { KZHType, UserType } from '../../../lib/types';
// Components
import DiscoveryBasicSection from '../Sections/DiscoveryBasicSection';
import MonumentSection from '../Sections/MonumentSection';
import ProperName from '../Sections/ProperNameSection';
import Notes from '../Sections/NotesSection';
import RentalsSection from '../Sections/RentalsSection';
import AcquisitionSection from '../Sections/AcquisitionSection';
import WarehouseLocationSection from '../Sections/WarehouseLocationSection';
import withUser from '../../withUser';
import ARTIFACT_STATUS_TYPE from '../../../lib/constants/artifactStatus';
import Breakpoints from '../../../lib/constants/breakpoints';
import LowResolutionBaner from '../../LowResolutionBaner/LowResolutionBaner';
import FileUpload from '../../FileUpload';
import canIArtifact from '../../../lib/utils/canI/canIArtifact/canIArtifact';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  data: KZHType,
  form: {
    getFieldDecorator: Function,
    getFieldsValue: Function,
    getFieldValue: Function,
    isFieldsTouched: Function,
    isFieldTouched: Function,
    resetFields: Function,
    setFieldsValue: Function,
    validateFieldsAndScroll: Function,
  },
  navigate: any,
  onSubmit: (val: KZHType, afterSave: string) => void,
  removeKZH: (id: string) => Promise<void>,
  submitErrorMessage: string,
  submitHistoricalArtifactToReview: Function,
  user: UserType,
};

type StateType = {
  isTouchedFlag: boolean,
};

class KZHForm extends React.PureComponent<PropsType, StateType> {
  state = {
    isTouchedFlag: false,
  };

  handleSubmit = (event: SyntheticInputEvent<HTMLInputElement>, afterSave: string) => {
    event.preventDefault();

    const {
      data,
      form: { validateFieldsAndScroll, getFieldsValue },
      onSubmit,
      submitErrorMessage,
    } = this.props;

    const isDraftOrNew = !data || (
      data.status === ARTIFACT_STATUS_TYPE.draft
      || data.status === ARTIFACT_STATUS_TYPE.new
    );

    validateFieldsAndScroll(isDraftOrNew && KZHForm.draftValidationsFields, (errors: string) => {
      if (!errors) {
        const values = getFieldsValue();
        onSubmit({ ...values }, afterSave);
      } else {
        message.error(submitErrorMessage);
      }
    });
  };

  handleSubmitToReview = () => {
    const {
      form: { validateFieldsAndScroll },
      submitErrorMessage,
      submitHistoricalArtifactToReview,
    } = this.props;

    validateFieldsAndScroll((errors: string) => {
      if (!errors && typeof submitHistoricalArtifactToReview === 'function') {
        submitHistoricalArtifactToReview();
      } else {
        message.error(submitErrorMessage);
      }
    });
  };

  setManualTouchedFlag = () => {
    this.setState({ isTouchedFlag: true });
  };

  static draftValidationsFields = ['title'];

  render(): React.Node {
    const {
      data,
      form: {
        getFieldDecorator,
        getFieldsValue,
        getFieldValue,
        setFieldsValue,
        isFieldTouched,
        isFieldsTouched,
        resetFields,
      },
      navigate,
      user,
      removeKZH,
    } = this.props;

    const { isTouchedFlag } = this.state;
    const isTouched = !!isFieldsTouched() || isTouchedFlag;
    const { canUpdate, canSendToReview } = canIArtifact({
      status: data && data.status,
      user,
      ownerEmail: data && data.created_by_email,
    });

    const disableSave = !canUpdate || !isTouched;
    const disableSendToReview = !canSendToReview || isTouched;
    const showRemoveButton = canUpdate && data;
    const artifactStatus = data && data.status;

    return (
      <StyledPageContainer>
        <StyledForm layout="vertical">
          <ProperName
            getFieldDecorator={getFieldDecorator}
            title={data ? data.title : null}
            status={data ? data.status : null}
            author={data ? data.created_by_email : user.email}
            rejectReason={data ? data.comment : null}
            canUpdate={canUpdate}
          />
          <DiscoveryBasicSection
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            formType="KZH"
            voivodeship={data ? data.voivodeship : null}
            county={data ? data.county : null}
            community={data ? data.community : null}
            city={data ? data.city : null}
            ordinalNumberInCity={data ? data.ordinal_number_in_city : null}
            azp={data ? data.azp : null}
            ordinalNumberInAzp={data ? data.ordinal_number_in_azp : null}
          />
          <WarehouseLocationSection
            getFieldDecorator={getFieldDecorator}
            shelvingNumber={data ? data.shelving_number : null}
            shelfNumber={data ? data.shelf_number : null}
          />
          <MonumentSection
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            getFieldsValue={getFieldsValue}
            setFieldsValue={setFieldsValue}
            formType="KZH"
            preserved={data ? data.preserved : null}
            value={data ? data.value : null}
            description={data ? data.description : null}
            dimensions={data ? data.dimensions : null}
            implementationTechnique={data ? data.implementation_technique : null}
            preservationState={data ? data.preservation_state : null}
            literature={data ? data.literature : null}
            placeOfOrigin={data ? data.place_of_origin : null}
            creationTime={data ? data.creation_time : null}
            creator={data ? data.creator : null}
            artifactType={data ? data.artifact_type : null}
            naturalResource={data ? data.natural_resource : null}
          />
          <AcquisitionSection
            getFieldDecorator={getFieldDecorator}
            acquisitionMethod={data ? data.acquisition_method : null}
            acquisitionDate={data && data.acquisition_date ? moment(data.acquisition_date, 'YYYY-MM-DD') : null}
          />
          <RentalsSection
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            isFieldTouched={isFieldTouched}
            setFieldsValue={setFieldsValue}
            rentals={data ? data.rentals : []}
            resetFields={resetFields}
            artifactStatus={artifactStatus}
          />
          <Notes
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            notes={data ? data.notes : []}
          />
          <FileUpload
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            mainPhoto={data ? data.main_photo : null}
            photos={data ? data.photos : []}
            setManualTouchedFlag={this.setManualTouchedFlag}
          />
          <StyledCard>
            <ButtonContainer>
              {showRemoveButton && (
                <Popconfirm
                  title={(
                    <FormattedMessage
                      id="KZHForm.removeQuestion"
                      defaultMessage="Czy na pewno chcesz usunąć zabytek historyczny?"
                    />
)}
                  onConfirm={(): Promise<void> => removeKZH(data.id)}
                  okText={<FormattedMessage id="general.yes" defaultMessage="Tak" />}
                  cancelText={<FormattedMessage id="general.no" defaultMessage="Nie" />}
                >
                  <Button size="large">
                    <Icon type="delete" />
                    <FormattedMessage id="general.remove" defaultMessage="Usuń" />
                  </Button>
                </Popconfirm>
              )}
              <Button size="large" onClick={(): void => navigate(-1)}>
                <Icon type="undo" />
                <FormattedMessage id="general.cancel" defaultMessage="Anuluj" />
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={(event: SyntheticInputEvent<HTMLInputElement>): void => this.handleSubmit(event, 'back')}
                disabled={disableSave}
              >
                <Icon type="save" theme="filled" />
                <FormattedMessage id="general.save" defaultMessage="Zapisz" />
              </Button>
              <Button
                size="large"
                onClick={(event: SyntheticInputEvent<HTMLInputElement>): void => this.handleSubmit(event, 'list')}
                disabled={disableSave}
              >
                <Icon type="save" theme="filled" />
                <FormattedMessage id="general.saveAndGoBackToTheList" defaultMessage="Zapisz i wróć na listę" />
              </Button>
              <Button size="large" disabled={disableSendToReview} onClick={this.handleSubmitToReview}>
                <Icon type="rocket" />
                <FormattedMessage id="general.sendToReview" defaultMessage="Wyślij do akceptacji" />
              </Button>
            </ButtonContainer>
          </StyledCard>
        </StyledForm>
        <LowResolutionBaner />
      </StyledPageContainer>
    );
  }
}

const StyledForm = styled(Form)`
  display: block;

  @media (max-width: ${Breakpoints.tablet}) {
    display: none;
  }
`;

const StyledPageContainer = styled.div`
  ${pageContainerStyle};
`;

const ButtonContainer = styled.div`
  > * {
    margin-left: 5px;
  }
  text-align: right;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default (withRouter(withUser(Form.create()(KZHForm))): any);
