import gql from 'graphql-tag';

export default gql`
  query StaticHTML($id: string) {
    staticHTML @rest(type: "HTML", path: $id) {
      id
      content
      __typename
    }
  }
`;
