// @flow
import * as React from 'react';
import { Card, message } from 'antd';
import {
  FormattedMessage, defineMessages, injectIntl, IntlShape,
} from 'react-intl';
import { Mutation, withApollo } from 'react-apollo';
import update from 'immutability-helper';
import { DragDropContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// Mutations
import ADD_PHOTO from '../../lib/mutations/addPhoto';
import REMOVE_PHOTO from '../../lib/mutations/removePhoto';

import type { PhotoType } from '../../lib/types';
import addsLocalIds from '../../lib/utils/addsLocalIds';
import FileUploadTable from './FileUploadTable';

type PropsTypes = {
  client: any,
  getFieldDecorator: Function,
  getFieldValue: Function,
  intl: typeof IntlShape,
  mainPhoto: PhotoType,
  photos: PhotoType[],
  setManualTouchedFlag: () => Promise<void>,
};

type DragSortingRecordType = {
  description: string,
  id: string,
  localId: number,
  path: string,
};

type StateType = {
  dragSortingTable: DragSortingRecordType[],
  localId: number,
};

class FileUpload extends React.Component<PropsTypes, StateType> {
  state = {
    dragSortingTable: [],
    localId: 0,
  };

  componentDidMount() {
    const dragSortingTable = this.prepareTableBaseOnInitialData();

    const { dataWithLocalId: initialDataWithLocalId, lastLocalId } = addsLocalIds(dragSortingTable);

    this.setState({
      dragSortingTable: initialDataWithLocalId,
      localId: lastLocalId + 1,
    });
  }

  prepareTableBaseOnInitialData = (): PhotoType[] => {
    const { photos, mainPhoto } = this.props;

    const dragSortingTable = mainPhoto ? [mainPhoto, ...photos] : photos;

    return dragSortingTable;
  };

  onDragSortingTableRowMethod = (_record, _index): { index: number, moveRow: Function } => {
    const { setManualTouchedFlag, getFieldValue } = this.props;

    const moveRow = (dragIndex: number, hoverIndex: number) => {
      setManualTouchedFlag();
      const dragSortingTable = getFieldValue('photosForm');

      const dragRow = dragSortingTable[dragIndex];
      const updatedDragSortingTable = update(dragSortingTable, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
      });

      this.setState({
        dragSortingTable: updatedDragSortingTable,
      });
    };

    return {
      index: _index,
      moveRow,
    };
  };

  handleAddNewPhoto = (newData: PhotoType) => {
    const { intl, setManualTouchedFlag } = this.props;
    const { dragSortingTable, localId } = this.state;

    const successMessage = intl.formatMessage(FileUpload.translations.successFileUpload);

    const updatedDragSortingTableData = [...dragSortingTable, { ...newData, localId }];

    this.setState({
      dragSortingTable: updatedDragSortingTableData,
      localId: localId + 1,
    });

    setManualTouchedFlag();

    message.success(successMessage);
  };

  handleRemoveFile = async (photoIdToRemove: string): Promise<void> => {
    const { client, setManualTouchedFlag } = this.props;
    const { dragSortingTable } = this.state;

    await client.mutate({
      mutation: REMOVE_PHOTO,
      variables: {
        id: photoIdToRemove,
      },
    });

    const filteredDragSortingTable = dragSortingTable.filter((item: PhotoType): boolean => item.id !== photoIdToRemove);

    this.setState({
      dragSortingTable: [...filteredDragSortingTable],
    });

    setManualTouchedFlag();
  };

  static translations = defineMessages({
    successFileUpload: {
      defaultMessage: 'Plik został wysłany prawidłowo',
      id: 'FileUpload.successFileUpload',
    },
  });

  render(): React.Node {
    const { dragSortingTable } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props;

    getFieldDecorator('photosForm', { initialValue: dragSortingTable });

    return (
      <Card
        bordered={false}
        title={(
          <div>
            <FormattedMessage id="FileUpload.title" defaultMessage="Zdjęcia" />
          </div>
)}
      >
        <Mutation
          mutation={ADD_PHOTO}
          context={{ hasUpload: true }} // Use UploadClient
          onCompleted={(newData: { addPhoto: PhotoType }) => {
            this.handleAddNewPhoto(newData.addPhoto);
          }}
        >
          {(mutate: any, { loading }: any): any => (
            <FileUploadTable
              getFieldDecorator={getFieldDecorator}
              getFieldValue={getFieldValue}
              handleRemoveFile={this.handleRemoveFile}
              loading={loading}
              mutate={mutate}
              onDragSortingTableRowMethod={this.onDragSortingTableRowMethod}
            />
          )}
        </Mutation>
      </Card>
    );
  }
}

export default (DragDropContext(HTML5Backend)(injectIntl(withApollo(FileUpload))): any);
