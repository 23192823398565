// @flow
// React
import * as React from 'react';
import { withApollo, Query } from 'react-apollo';
import { message } from 'antd';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
// Queries and mutation
import UPDATE_SEPARATED_ARTIFACT from '../../../lib/mutations/updateSeparatedArtifact';
import GET_SEPARATED_ARTIFACT from '../../../lib/queries/getSeparatedArtifact';
import REMOVE_SEPARATED_ARTIFACT from '../../../lib/mutations/removeSeparatedArtifact';
import CHANGE_ARTIFACT_STATUS from '../../../lib/mutations/changeArtifactStatus';
// Components
import KZWForm from '../../../components/Forms/KZWForm';
import LoadingSpin from '../../../components/LoadingSpin';
import BreadCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';
// Own Types
import type { KZWType, UserType, QueryRenderProps } from '../../../lib/types';

import parseNotes from '../../../lib/utils/parseNotes';
import parseRentals from '../../../lib/utils/parseRentals';
import parsePreserved from '../../../lib/utils/parsePreserved';
import parseDictionaryValue from '../../../lib/utils/parseDictionaryValue';
import parsePhotos from '../../../lib/utils/parsePhotos';
import ARTIFACT_STATUS from '../../../lib/constants/artifactStatus';
import NoDataBecauseError from '../../../components/NoDataBecauseError';
import withRouter from '../../../lib/utils/withRouter';

type ResponseType = {
  separatedArtifact: {
    concat: Function,
    data: KZWType[],
  },
};

type PropsType = {
  client: any,
  intl: typeof IntlShape,
  location: {
    state: { prevPath?: string },
  },
  navigate: any,
  params: { artifactId: string },
  user: ?UserType,
};

type StateType = {
  updating: boolean,
};

export class KZWEdit extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    updating: false,
  };

  mutationUpdater: ((cache: any, mutationResult: any) => void) = (cache: any, mutationResult: any) => {
    const {
      params: { artifactId },
    } = this.props;

    try {
      const response: ?ResponseType = cache.readQuery({ query: GET_SEPARATED_ARTIFACT, variables: { id: artifactId } });
      const { data } = mutationResult;

      if (response && data) {
        cache.writeQuery({
          query: GET_SEPARATED_ARTIFACT,
          variables: { id: artifactId },
          data: { separatedArtifact: data.updateSeparatedArtifact },
        });
      }
    } catch (e) {
      // https://github.com/apollographql/apollo-client/issues/1701
    }
  };

  editKZW: (formValues: KZWType, afterSave: string) => Promise<void> = async (
    formValues: KZWType,
    afterSave: string,
  ): Promise<void> => {
    const {
      intl,
      client,
      params: { artifactId },
      navigate,
    } = this.props;

    const { mainPhoto, photos } = parsePhotos(formValues.photos, formValues.photosForm);

    this.setState({ updating: true });
    const response = await client.mutate({
      mutation: UPDATE_SEPARATED_ARTIFACT,
      variables: {
        id: artifactId,
        ...formValues,
        shelf_number: parseInt(formValues.shelf_number, 10),
        are: parseInt(formValues.are, 10),
        object_number: parseInt(formValues.object_number, 10),
        shelving_number: parseInt(formValues.shelving_number, 10),
        notes: parseNotes(formValues.notes),
        rentals: parseRentals(formValues.rentals),
        research_date: formValues.research_date && formValues.research_date.format('YYYY-MM-DD'),
        inventory_entry_at: formValues.inventory_entry_at && formValues.inventory_entry_at.format('YYYY-MM-DD'),
        preserved: parsePreserved(formValues.preserved),
        voivodeship_id: parseDictionaryValue(formValues.voivodeship_id),
        county_id: parseDictionaryValue(formValues.county_id),
        community_id: parseDictionaryValue(formValues.community_id),
        city_id: parseDictionaryValue(formValues.city_id),
        azp_id: parseDictionaryValue(formValues.azp_id),
        artifact_type_id: parseDictionaryValue(formValues.artifact_type_id),
        natural_resource_id: parseDictionaryValue(formValues.natural_resource_id),
        archaeological_culture_id: parseDictionaryValue(formValues.archaeological_culture_id),
        chronology_id: parseInt(parseDictionaryValue(formValues.chronology_id), 10),
        main_photo: mainPhoto,
        photos,
      },
      update: this.mutationUpdater,
    });
    this.setState({ updating: false });

    if ('errors' in response) {
      return;
    }

    const translatedSuccessMessage = intl.formatMessage(KZWEdit.translations.submitSuccessMessage);
    if (afterSave === 'list') {
      navigate('/artifacts/list/archaeological');
    }
    message.success(translatedSuccessMessage);
  };

  removeKZW: ((removeId: string) => Promise<void>) = async (removeId: string): Promise<void> => {
    const { client, location, navigate } = this.props;

    await client.mutate({
      mutation: REMOVE_SEPARATED_ARTIFACT,
      variables: {
        id: removeId,
      },
    });

    if (location.state && location.state.prevPath) {
      navigate('/artifacts/list/archaeological');
    } else {
      navigate(-1);
    }
  };

  submitSeparatedArtifactToReview: (() => Promise<void>) = async (): Promise<void> => {
    const {
      intl,
      client,
      params: { artifactId },
    } = this.props;

    this.setState({ updating: true });
    const response = await client.mutate({
      mutation: CHANGE_ARTIFACT_STATUS,
      variables: {
        id: artifactId,
        status: ARTIFACT_STATUS.ready,
      },
      update: this.mutationUpdater,
    });
    this.setState({ updating: false });

    if ('errors' in response) {
      return;
    }

    const translatedSuccessMessage = intl.formatMessage(
      KZWEdit.translations.sendSeparatedArtifactToReviewSuccessMessage,
    );
    message.success(translatedSuccessMessage);
  };

  static translations: any = defineMessages({
    submitSuccessMessage: {
      defaultMessage: 'Zabytek wydzielony został zaktualizowany',
      id: 'KZWEdit.submitSuccessMessage',
    },
    submitErrorMessage: {
      defaultMessage: 'Błąd walidacji formularza, sprawdź wszystkie pola',
      id: 'KZWEdit.errorMessage',
    },
    sendSeparatedArtifactToReviewSuccessMessage: {
      defaultMessage: 'Znalezisko wydzielone zostało wysłane do akceptacji',
      id: 'KZWEdit.sendSeparatedArtifactToReviewSuccessMessage',
    },
  });

  render(): React.Node {
    const {
      intl,
      params: { artifactId },
      user,
    } = this.props;
    const { updating } = this.state;
    const submitErrorMessage = intl.formatMessage(KZWEdit.translations.submitErrorMessage);

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['admin', 'editSeparatedArtifact']} />
        <Query query={GET_SEPARATED_ARTIFACT} variables={{ id: artifactId, isAdmin: user && !!user.email }}>
          {({ loading, error, data }: QueryRenderProps<{ separatedArtifact: KZWType }>): React.Node => {
            if (loading || updating) return <LoadingSpin tip="Loading..." />;
            if (error) return <NoDataBecauseError />;
            if (!data || !data.separatedArtifact) return 'Brak danych';

            return (
              <KZWForm
                data={data.separatedArtifact}
                onSubmit={this.editKZW}
                submitErrorMessage={submitErrorMessage}
                removeKZW={this.removeKZW}
                submitSeparatedArtifactToReview={this.submitSeparatedArtifactToReview}
                artifactId={artifactId}
              />
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

export default (withRouter(withApollo(injectIntl(withUser(KZWEdit)))): any);
