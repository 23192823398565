// @flow
import store from 'store';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import decodeJWT from '../../lib/utils/decodeJWT';

async function isTokenValidOrUndefined(): Promise<boolean> {
  const storedToken: String = store.get('authToken');

  if (!storedToken) return true;

  const token = decodeJWT(storedToken);

  const timeNow = new Date().getTime();
  const createdAt = token.iat * 1000; // unix time is in seconds we need ms

  // Refresh token every 5 minutes
  // return false for refresh token

  return timeNow - createdAt < 5 * 1000 * 60;
}

function fetchAccessToken(): Promise<Response> {
  return fetch(process.env.REACT_APP_GRAPHQL_ADDRESS || '/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `{
      refreshToken(old_token: "${store.get('authToken')}") {
        value
      }
    }`,
    }),
  });
}

function handleFetch({ value }: { value: string }) {
  store.set('authToken', value);
}

function handleError() {
  window.history.pushState(null, '', '/401');
}

export default (new TokenRefreshLink({
  accessTokenField: 'refreshToken',
  isTokenValidOrUndefined: (): Promise<boolean> => isTokenValidOrUndefined(),
  fetchAccessToken: (): Promise<Response> => fetchAccessToken(),
  handleFetch: (token: { value: string }): void => handleFetch(token),
  handleError: (): void => handleError(),
}): any);
