import gql from 'graphql-tag';

export default gql`
  query AllDictionaryEntries($filter: DictionaryEntryFilter, $paginator: Paginator, $order: String) {
    allDictionaryEntries(filter: $filter, paginator: $paginator, order: $order) {
      total
      data {
        id
        value_pl
        value_de
        value_en
        dictionary_id
        code
      }
    }
  }
`;
