// @flow
import GET_ARCHAEOLOGICAL_ARTIFACTS_LIST_COLUMNS_QUERY from '../queries/getArtifactsArchaeologicalListColumns';
import {
  artifactsArchaeologicalListFloatingColumns,
  artifactsArchaeologicalListDefaultColumns,
} from '../constants/artifactsListFloatingColumns';

const artifactsArchaeologicalListColumns = {
  defaults: {
    artifactsArchaeologicalListColumns: {
      selectedColumns: artifactsArchaeologicalListDefaultColumns,
      orderedColumns: artifactsArchaeologicalListFloatingColumns,
      columnSortOrder: 'title_ASC',
      __typename: 'artifactsArchaeologicalListColumns',
    },
  },
  resolvers: {
    Mutation: {
      setArtifactsArchaeologicalListColumns: (
        _: any,
        {
          columnSortOrder,
          orderedColumns,
          selectedColumns,
        }: { columnSortOrder: string, orderedColumns: string[], selectedColumns: string[] },
        { cache }: { cache: Object },
      ): null => {
        const readedArtifactsArchaeologicalListColumnsData = cache.readQuery({
          query: GET_ARCHAEOLOGICAL_ARTIFACTS_LIST_COLUMNS_QUERY,
        });

        cache.writeQuery({
          query: GET_ARCHAEOLOGICAL_ARTIFACTS_LIST_COLUMNS_QUERY,
          data: {
            artifactsArchaeologicalListColumns: {
              selectedColumns: selectedColumns
                || readedArtifactsArchaeologicalListColumnsData.artifactsArchaeologicalListColumns.selectedColumns,
              orderedColumns: orderedColumns
                || readedArtifactsArchaeologicalListColumnsData.artifactsArchaeologicalListColumns.orderedColumns,
              columnSortOrder: columnSortOrder
                || readedArtifactsArchaeologicalListColumnsData.artifactsArchaeologicalListColumns.columnSortOrder,
              __typename: 'artifactsArchaeologicalListColumns',
            },
          },
        });

        return null;
      },
    },
  },
};

export default artifactsArchaeologicalListColumns;
