// @flow
import { defineMessages } from 'react-intl';
import definePageConfig from '../lib/utils/definePageConfig';

// translation title must be the same in pageConfig and ends with Title or HelmetTitle
export const translations: any = defineMessages({
  homePageTitle: {
    defaultMessage: 'E-Archiwum Archeologiczne',
    id: 'StaticPage.homeTitle',
  },
  faqPageTitle: {
    defaultMessage: 'Jak korzystać',
    id: 'StaticPage.faqTitle',
  },
  artifactsListHistoricalPageTitle: {
    defaultMessage: 'Katalog Historyczny',
    id: 'Dictionary.artifactsListHistoricalTitle',
  },
  artifactsListAarchaeologicalPageTitle: {
    defaultMessage: 'Katalog Archeologiczny',
    id: 'Helmet.archaeologicalCatalogTitle',
  },
  dictionariesListPageTitle: {
    defaultMessage: 'Słowniki',
    id: 'DictionariesList.dictionariesListTitle',
  },
  dictionaryDetailsPageTitle: {
    defaultMessage: 'Słownik',
    id: 'Dictionary.dictionaryTitle',
  },
  newKZMPageTitle: {
    defaultMessage: 'Dodaj zabytek masowy',
    id: 'KZMNew.title',
  },
  newKZHPageTitle: {
    defaultMessage: 'Dodaj zabytek historyczny',
    id: 'KZHNew.title',
  },
  newKZWPageTitle: {
    defaultMessage: 'Dodaj zabytek wydzielony',
    id: 'KZWNew.title',
  },
  privacyPageTitle: {
    defaultMessage: 'Polityka prywatności',
    id: 'StaticPage.privacyTitle',
  },
  rulesPageTitle: {
    defaultMessage: 'Regulamin',
    id: 'StaticPage.rulesTitle',
  },
  editMassArtifactPageTitle: {
    defaultMessage: 'Edycja zabytku masowego',
    id: 'EditMassArtifact.title',
  },
  editHistoricalArtifactPageTitle: {
    defaultMessage: 'Edycja zabytku historycznego',
    id: 'EditHistoricalArtifact.title',
  },
  editSeparatedArtifactPageTitle: {
    defaultMessage: 'Edycja zabytku wydzielonego',
    id: 'EditSeparatedArtifact.title',
  },
  artifactDetailsPageTitle: {
    defaultMessage: 'Szczegóły zabytku',
    id: 'ArtifactDetail.artifactDetailTitle',
  },
  artifactsChangeLogPageTitle: {
    defaultMessage: 'Historia zmian',
    id: 'ArtifactDetail.artifactsChangeLogTitle',
  },
  myWorkPageTitle: {
    defaultMessage: 'Moja praca',
    id: 'Helmet.myWorkTitle',
  },
  moderationViewPageTitle: {
    defaultMessage: 'Widok moderacji',
    id: 'Helmet.moderationViewTitle',
  },
  administrationViewPageTitle: {
    defaultMessage: 'Widok administracji',
    id: 'Helmet.administrationViewTitle',
  },
  artifactsDetailsByCatalogIdPageTitle: {
    defaultMessage: 'Szczegóły zabytku',
    id: 'ArtifactDetail.artifactDetailTitle',
  },
});

// default values fo pageConfig is
// {
//   url: path,
//   showBreadcrumbs: true,
//   showPageTitle: true,
// }

const pageConfig = {
  homePage: {
    path: '/',
    showBreadcrumbs: false,
    showPageTitle: false,
  },
  faqPage: {
    path: '/about/faq',
  },
  dictionariesListPage: {
    path: '/admin/dictionaries',
  },
  dictionaryDetailsPage: {
    path: '/admin/dictionary/:dictionaryId',
  },
  privacyPage: {
    path: '/about/privacy',
  },
  rulesPage: {
    path: '/about/rules',
  },
  artifactsListHistoricalPage: {
    path: '/artifacts/list/:type',
    url: '/artifacts/list/historical',
  },
  artifactsListAarchaeologicalPage: {
    path: '/artifacts/list/:type',
    url: '/artifacts/list/archaeological',
  },
  myWorkPage: {
    path: '/artifacts/list/:type',
    url: '/artifacts/list/my-work',
  },
  moderationViewPage: {
    path: '/artifacts/list/:type',
    url: '/artifacts/list/moderation',
  },
  administrationViewPage: {
    path: '/artifacts/list/:type',
    url: '/artifacts/list/administration',
  },
  newKZMPage: {
    path: '/admin/kzm/new',
  },
  newKZWPage: {
    path: '/admin/kzw/new',
  },
  newKZHPage: {
    path: '/admin/kzh/new',
  },
  editMassArtifactPage: {
    path: '/admin/massartifact/:artifactId/edit',
  },
  editHistoricalArtifactPage: {
    path: '/admin/historicalartifact/:artifactId/edit',
  },
  editSeparatedArtifactPage: {
    path: '/admin/separatedartifact/:artifactId/edit',
  },
  artifactsDetailsByCatalogIdPage: {
    path: '/:type/:shortCatalogId',
  },
  artifactsChangeLogPage: {
    path: '/artifacts/:type/:artifactId/change-log',
  },
  artifactDetailsPage: {
    path: '/artifacts/:type/:artifactId',
    showBreadcrumbs: false,
  },
  artifactsLabelPrintPage: {
    path: '/artifacts/:type/:artifactId/print-label',
    showBreadcrumbs: false,
    showPageTitle: false,
  },
};

const definedConfig: any = definePageConfig({ translations, pageConfig });

export default definedConfig;
