// @flow
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import { RetryLink } from 'apollo-link-retry';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { RestLink } from 'apollo-link-rest';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import resolvers from '../../lib/resolvers';
import errorHandler from './errorHandler';
import refreshTokenLink from './refreshTokenLink';
import authLink from './authLink';
import fragmentTypes from './fragmentTypes.json';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

const stateLink = withClientState({
  ...resolvers,
  cache,
});

const httpBatch = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ADDRESS,
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ADDRESS,
});

const retryLink = new RetryLink();
const restLink = new RestLink({ uri: process.env.REACT_APP_CMS_ADDRESS });
const defaultLink = [stateLink, refreshTokenLink, authLink, errorHandler, retryLink, restLink];

/**
 * Apollo Terminating link
 * The split will skip Batching if an
 * operation's context contains hasUpload: true.
 * https://github.com/jaydenseric/apollo-upload-client/issues/34
 */
const httpLink = ApolloLink.split(
  // eslint-disable-next-line
  operation => operation.getContext().hasUpload,
  ApolloLink.from([...defaultLink, uploadLink]),
  ApolloLink.from([...defaultLink, httpBatch]),
);

const client: any = new ApolloClient({
  link: httpLink,
  cache,
  defaultOptions,
  connectToDevTools: !!process.env.REACT_APP_DEBUG,
});

export default client;
