// @flow
import { setContext } from 'apollo-link-context';
import store from 'store';

type headersType = {
  headers: {
    Authorization: ?string,
  },
};

export default (setContext(
  (): headersType => {
    const token = store.get('authToken');
    const language = store.get('userLanguage');

    return {
      headers: {
        Authorization: (token && `Bearer ${token}`) || null,
        'Accept-Language': language || 'pl',
      },
    };
  },
): any);
