// @flow
import * as React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const LoadingSpin = ({ tip }: { tip: string }): React.Node => <StyledSpin size="large" tip={tip} />;

const StyledSpin = styled(Spin)`
  margin-top: 33vh !important;
`;

export default LoadingSpin;
