// @flow
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import routePaths from '../lib/constants/routePaths';
import PAGE_CONFIG from '../config/pageConfig';
import PrivateRoute from '../components/PrivateRoute';
import Dictionary from './Dictionaries/Dictionary';
import DictionariesList from './Dictionaries/DictionariesList';
import ArtifactsDetails from './Artifacts/ArtifactsDetails';
import ArtifactsChangeLog from './Artifacts/ArtifactsChangeLog';
import Artifacts from './Artifacts';
import Auth from './Auth';
import ErrorPage from './ErrorPage';
import StaticPage from './StaticPage';
import KZMNew from './Forms/KZMNew';
import KZMEdit from './Forms/KZMEdit';
import KZWNew from './Forms/KZWNew';
import KZHNew from './Forms/KZHNew';
import KZHEdit from './Forms/KZHEdit';
import KZWEdit from './Forms/KZWEdit';
import ArtifactLabelPrint from './Artifacts/ArtifactLabelPrint';

const RoutesComponent = (): React.Element<typeof Routes> => (
  <Routes>
    {/* Static */}
    <Route path={routePaths.home} element={<StaticPage />} />
    <Route path={routePaths.aboutFaq} element={<StaticPage />} />
    <Route path={routePaths.aboutPrivacy} element={<StaticPage />} />
    <Route path={routePaths.aboutRules} element={<StaticPage />} />

    {/* Auth */}
    <Route path={routePaths.auth} element={<Auth />} />

    {/* Artifact details */}
    <Route path={PAGE_CONFIG.artifactsDetailsByCatalogIdPage.path} element={<ArtifactsDetails />} />
    <Route path={routePaths.artifactsDetails} element={<ArtifactsDetails />} />

    {/* Artifacts */}
    <Route path="artifacts/list/:type" element={<Artifacts />} />

    {/* Private routes */}
    <Route path="/" element={<PrivateRoute />}>
      {/* Artifact details */}
      <Route path={PAGE_CONFIG.artifactsChangeLogPage.path} element={<ArtifactsChangeLog />} />
      <Route path={PAGE_CONFIG.artifactsLabelPrintPage.path} element={<ArtifactLabelPrint />} />

      {/* Dictionaries */}
      <Route path={routePaths.adminDictionaries} element={<DictionariesList />} />
      <Route path={routePaths.adminDictionary} element={<Dictionary />} />

      {/* KZM */}
      <Route path={routePaths.adminKzmNew} element={<KZMNew />} />
      <Route path={routePaths.adminMassArtifactEdit} element={<KZMEdit />} />

      {/* KZW */}
      <Route path={routePaths.adminKzwNew} element={<KZWNew />} />
      <Route path={routePaths.adminSeparatedArtifactEdit} element={<KZWEdit />} />

      {/* KZH */}
      <Route path={routePaths.adminKzhNew} element={<KZHNew />} />
      <Route path={routePaths.adminHistoricalArtifactEdit} element={<KZHEdit />} />

      {/* Sentry test */}
      <Route
        path="/sentry"
        element={(
          <button type="button" onClick={(): void => window.doError()}>
            Do Error
          </button>
        )}
      />
    </Route>

    <Route path="*" element={<ErrorPage />} />
  </Routes>
);

export default RoutesComponent;
