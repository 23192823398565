// @flow
import type { OtherMassArtifactElementInputType, OtherMassArtifactElementType } from '../../types';

const parseOther = (others: OtherMassArtifactElementInputType[]): OtherMassArtifactElementType[] => {
  if (others) {
    return others.map(
      (other: OtherMassArtifactElementInputType): OtherMassArtifactElementType => ({
        id: other.id,
        description: other.description,
        quantity: parseInt(other.quantity, 10),
      }),
    );
  }

  return [];
};

export default parseOther;
