// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import CHANGE_LANGUAGE_MUTATION from '../../lib/mutations/changeLanguage';

type PropsType = {
  client: any,
};

const LanguageSwitchInAccessibility = (props: PropsType): React.Node => {
  const handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    const { client } = props;

    client.mutate({
      mutation: CHANGE_LANGUAGE_MUTATION,
      variables: { language: value },
    });
  };

  return (
    <StyledButtonsWrapper>
      <StyledButton key="pl" onClick={handleChange} onKeyPress={handleChange} value="pl" type="button">
        Polski
      </StyledButton>
      <StyledButton key="en" onClick={handleChange} onKeyPress={handleChange} value="en" type="button">
        English
      </StyledButton>
      <StyledButton key="de" onClick={handleChange} onKeyPress={handleChange} value="de" type="button">
        Deutsch
      </StyledButton>
    </StyledButtonsWrapper>
  );
};

const StyledButton = styled.button`
  margin-right: 8px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  color: #001529;
  background: yellow;
  font-size: 16px;
  width: 150px;
  height: 30px;
  border: none;

  &:focus {
    border: 4px solid white;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  width: 400px;
`;

export default (withApollo(LanguageSwitchInAccessibility): any);
