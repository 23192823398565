// @flow
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  Card, Row, Col, Input, Alert, Tooltip,
} from 'antd';
import { Icon, Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
// Form Validation
import { required, min5Characters } from '../../../lib/formValidation';
import ARTIFACT_STATUS from '../../../lib/constants/artifactStatus';
import StatusChanger from '../../StatusChanger/StatusChanger';

const FormItem = Form.Item;

type PropTypes = {
  author: ?string,
  canUpdate: boolean,
  getFieldDecorator: Function,
  rejectReason: ?string,
  status: ?string,
  title: ?string,
};

const ProperNameSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator, title, status, author, rejectReason, canUpdate,
  } = props;

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          <FormattedMessage id="ProperNameSection.metadata" defaultMessage="Metadane" />
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col sm={24} md={24} lg={7}>
          <FormItem label={<FormattedMessage id="ProperNameSection.title" defaultMessage="Nazwa własna" />}>
            {getFieldDecorator('title', {
              initialValue: title || null,
              rules: [required, min5Characters],
            })(<Input data-testid="title" />)}
          </FormItem>
        </Col>
        <Col sm={24} md={12} lg={5}>
          <Label>
            <FormattedMessage id="general.statusTitle" defaultMessage="Status" />: &nbsp;
          </Label>
          <StatusChanger status={status || ARTIFACT_STATUS.new} />
        </Col>
        <Col sm={24} md={12} lg={4}>
          <Label>
            <FormattedMessage id="ProperNameSection.editionStatus" defaultMessage="Status edycji:" />
          </Label>
          <Tooltip
            placement="bottom"
            title={(
              <FormattedMessage
                id="ProperNameSection.editionModesTooltip"
                // eslint-disable-next-line max-len
                defaultMessage="Admin może edytować tylko własne szkice. Superadmin może edytować tylko zabytki o statusie szkic lub archiwum."
              />
)}
          >
            {canUpdate && (
              <EditionStatus>
                <EditionEnableIcon type="check-square" />
                <EditionStatusText>
                  <FormattedMessage id="ProperNameSection.editionPossible" defaultMessage="Możliwa" />
                </EditionStatusText>
              </EditionStatus>
            )}
            {!canUpdate && (
              <EditionStatus>
                <EditionDissableIcon type="close-square" />
                <EditionStatusText>
                  <FormattedMessage id="ProperNameSection.editionDissable" defaultMessage="Zablokowana" />
                </EditionStatusText>
              </EditionStatus>
            )}
          </Tooltip>
        </Col>
        <Col sm={24} md={12} lg={6}>
          <Label>
            <FormattedMessage id="general.author" defaultMessage="Autor szkicu" />: &nbsp;
          </Label>
          <Author>{author}</Author>
        </Col>
        {rejectReason && (
          <Col span={24}>
            <Alert
              message={
                // eslint-disable-next-line
                <span dangerouslySetInnerHTML={{ __html: rejectReason.replace(/\r?\n/g, '<br/>') }} />
              }
              showIcon
              type="error"
            />
          </Col>
        )}
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

const Author = styled.div`
  padding: 4px 11px;
  border: 1px dashed #d9d9d9;
  margin-top: 9px;
  margin-bottom: 32px;
  border-radius: 5px;
`;

const Label = styled.div`
  color: rgba(0, 0, 0, 0.85);
`;

const EditionStatus = styled.div`
  margin-top: 4px;
  margin-bottom: 18px;
  display: flex;
  align-items: top;
`;

const EditionEnableIcon = styled(Icon)`
  color: green !important;
  font-size: 50px;
  margin-right: 10px;
`;

const EditionDissableIcon = styled(Icon)`
  color: red !important;
  font-size: 50px;
  margin-right: 10px;
`;

const EditionStatusText = styled.div`
  margin-top: 8px;
`;

export default ProperNameSection;
