// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  FormattedMessage, defineMessages, injectIntl, IntlShape,
} from 'react-intl';
import {
  Card, Row, Col, Input, DatePicker,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

const FormItem = Form.Item;

type PropTypes = {
  fieldInventoryNumbers: ?string,
  getFieldDecorator: Function,
  intl: typeof IntlShape,
  researchDate: ?string,
  researchExecutive: ?string,
};

const translations = defineMessages({
  dataPlaceholder: {
    defaultMessage: 'RRRR-MM-DD',
    id: 'ResearchesSection.datapicker.placeholder',
  },
  inventoryNumbersPlaceholder: {
    defaultMessage: 'Wartości oddzielone przecinkami',
    id: 'ResearchesSection.fieldInventoryNumbersPlaceholder',
  },
});

const ResearchesSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator, intl, researchDate, researchExecutive, fieldInventoryNumbers,
  } = props;

  const datePickerPlaceholder = intl.formatMessage(translations.dataPlaceholder);
  const inventoryNumbersPlaceholder = intl.formatMessage(translations.inventoryNumbersPlaceholder);

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          <FormattedMessage id="ResearchesSection.title" defaultMessage="Badania" />
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col lg={8}>
          <FormItem label={<FormattedMessage id="ResearchesSection.date" defaultMessage="Data badań" />}>
            {getFieldDecorator('research_date', {
              initialValue: researchDate || null,
            })(<DatePicker format="YYYY-MM-DD" placeholder={datePickerPlaceholder} />)}
          </FormItem>
        </Col>
        <Col lg={8}>
          <FormItem
            label={<FormattedMessage id="ResearchesSection.researchExecutive" defaultMessage="Wykonawca badań" />}
          >
            {getFieldDecorator('research_executive', {
              initialValue: researchExecutive || null,
            })(<Input />)}
          </FormItem>
        </Col>
        <Col lg={8}>
          <FormItem
            label={
              <FormattedMessage id="ResearchesSection.fieldInventoryNumbers" defaultMessage="Nr inwentarza polowego" />
            }
          >
            {getFieldDecorator('field_inventory_number', {
              initialValue: fieldInventoryNumbers || null,
            })(<Input placeholder={inventoryNumbersPlaceholder} />)}
          </FormItem>
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default (injectIntl(ResearchesSection): any);
