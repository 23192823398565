import gql from 'graphql-tag';

export default gql`
  mutation AddPhoto($file: Upload) {
    addPhoto(file: $file) {
      id
      path
      description
    }
  }
`;
