// @flow
import * as React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { accessibility } from '../../../../lib/constants/themeModes';
import type { UserType } from '../../../../lib/types';
import { biggerFontSize } from '../../../../lib/style/themes';

const Buttons = ({ record, user }: { record: Object, user: UserType }): React.Node => {
  const getRecordType = record.type.props.id.split('.')[1].toLowerCase();
  const matchNumber = new RegExp('^\\d+$');

  const linkToDetails = matchNumber.test(record.short_catalog_id)
    ? `/${record.short_catalog_id.substring(0, 2)}/${record.short_catalog_id.substring(2)}`
    : `/artifacts/${getRecordType}/${record.id}`;

  return (
    <React.Fragment>
      <Link to={linkToDetails} tabIndex="-1">
        <StyledButtonDetails type="primary">
          <FormattedMessage id="general.details" defaultMessage="Szczegóły" />
        </StyledButtonDetails>
      </Link>
      {user
        && user.email && (
          <Link to={`/admin/${getRecordType}artifact/${record.id}/edit`}>
            <StyledButtonDetails>
              <FormattedMessage id="general.edit" defaultMessage="Edytuj" />
            </StyledButtonDetails>
          </Link>
      )}
    </React.Fragment>
  );
};

const StyledButtonDetails = styled(Button)`
  margin-right: 10px;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: black !important;
      background-color: yellow !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: white !important;
        background-color: black !important;
        border: 2px solid red !important;
      }
    `};
`;

export default Buttons;
