import gql from 'graphql-tag';

export default gql`
  mutation AddHistoricalArtifact(
    $title: String
    $voivodeship_id: ID
    $county_id: ID
    $community_id: ID
    $city_id: ID
    $artifact_type_id: ID
    $natural_resource_id: ID
    $description: String
    $place_of_origin: String
    $dimensions: String
    $implementation_technique: String
    $preserved: Boolean!
    $preservation_state: String
    $value: String
    $literature: String
    $creation_time: String
    $creator: String
    $acquisition_method: String
    $acquisition_date: Date
    $inventory_entry_at: Date
    $notes: [NoteInput]
    $rentals: [RentalInput]
    $photos: [PhotoInput]
    $main_photo: PhotoInput
    $shelving_number: Int
    $shelf_number: Int
  ) {
    addHistoricalArtifact(
      title: $title
      voivodeship_id: $voivodeship_id
      county_id: $county_id
      community_id: $community_id
      city_id: $city_id
      artifact_type_id: $artifact_type_id
      natural_resource_id: $natural_resource_id
      description: $description
      place_of_origin: $place_of_origin
      dimensions: $dimensions
      implementation_technique: $implementation_technique
      preserved: $preserved
      preservation_state: $preservation_state
      value: $value
      literature: $literature
      creation_time: $creation_time
      creator: $creator
      acquisition_method: $acquisition_method
      acquisition_date: $acquisition_date
      inventory_entry_at: $inventory_entry_at
      notes: $notes
      rentals: $rentals
      photos: $photos
      main_photo: $main_photo
      shelving_number: $shelving_number
      shelf_number: $shelf_number
    ) {
      id
      catalog_id
      short_catalog_id
      title
      description
      place_of_origin
      dimensions
      implementation_technique
      preserved
      preservation_state
      value
      literature
      creation_time
      creator
      acquisition_method
      acquisition_date
      inventory_entry_at
      voivodeship {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      county {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      community {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      city {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      artifact_type {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      natural_resource {
        id
        value_pl
        value_en
        value_de
        dictionary_id
      }
      notes {
        id
        created_by_email
        content
        created_at
      }
      rentals {
        id
        rental_date
        renter
        return_date
      }
      main_photo {
        id
        path
        description
      }
      photos {
        id
        path
        description
      }
      shelving_number
      shelf_number
    }
  }
`;
