// @flow
import GET_HISTORICAL_ARTIFACTS_LIST_COLUMNS_QUERY from '../queries/getArtifactsHistoricalListColumns';
import {
  artifactsHistoricalListFloatingColumns,
  artifactsHistoricalListDefaultColumns,
} from '../constants/artifactsListFloatingColumns';

const artifactsHistoricalListColumns = {
  defaults: {
    artifactsHistoricalListColumns: {
      selectedColumns: artifactsHistoricalListDefaultColumns,
      orderedColumns: artifactsHistoricalListFloatingColumns,
      columnSortOrder: 'title_ASC',
      __typename: 'artifactsHistoricalListColumns',
    },
  },
  resolvers: {
    Mutation: {
      setArtifactsHistoricalListColumns: (
        _: any,
        {
          columnSortOrder,
          orderedColumns,
          selectedColumns,
        }: { columnSortOrder: string, orderedColumns: string[], selectedColumns: string[] },
        { cache }: { cache: Object },
      ): null => {
        const readedArtifactsHistoricalListColumnsData = cache.readQuery({
          query: GET_HISTORICAL_ARTIFACTS_LIST_COLUMNS_QUERY,
        });

        cache.writeQuery({
          query: GET_HISTORICAL_ARTIFACTS_LIST_COLUMNS_QUERY,
          data: {
            artifactsHistoricalListColumns: {
              selectedColumns: selectedColumns
                || readedArtifactsHistoricalListColumnsData.artifactsHistoricalListColumns.selectedColumns,
              orderedColumns: orderedColumns
                || readedArtifactsHistoricalListColumnsData.artifactsHistoricalListColumns.orderedColumns,
              columnSortOrder: columnSortOrder
                || readedArtifactsHistoricalListColumnsData.artifactsHistoricalListColumns.columnSortOrder,
              __typename: 'artifactsHistoricalListColumns',
            },
          },
        });

        return null;
      },
    },
  },
};

export default artifactsHistoricalListColumns;
