// @flow
//
// THIS FILE IS DEPRECATED PLESE USE /config/pageConfig.js instead
//
export default {
  aboutFaq: '/about/faq',
  aboutPrivacy: '/about/privacy',
  aboutRules: '/about/rules',
  adminDetails: 'http://dev.ala.e-archeologia.pl/',
  adminDictionaries: '/admin/dictionaries',
  adminDictionary: '/admin/dictionary/:dictionaryId',
  adminHistoricalArtifactEdit: '/admin/historicalartifact/:artifactId/edit',
  adminKzhNew: '/admin/kzh/new',
  adminKzmNew: '/admin/kzm/new',
  adminKzwNew: '/admin/kzw/new',
  adminMassArtifactEdit: '/admin/massartifact/:artifactId/edit',
  adminSeparatedArtifactEdit: '/admin/separatedartifact/:artifactId/edit',
  artifacts: '/artifacts/*',
  artifactsByCatalogIdDetails: '/:type/:shortCatalogId',
  artifactsDetails: '/artifacts/:type/:artifactId',
  artifactChangeLog: '/artifacts/:type/:artifactId/change-log',
  artifactsList: '/artifacts/list/*',
  artifactsListAdministration: '/artifacts/list/administration',
  artifactsListArchaeological: '/artifacts/list/archaeological',
  artifactsListHistorical: '/artifacts/list/historical',
  artifactsListModeration: '/artifacts/list/moderation',
  artifactsListMyWork: '/artifacts/list/my-work',
  auth: '/auth',
  contact: 'https://e-archeologialubuska.pl/kontakt/?site=E-Archiwum%20Archeologiczne',
  home: '/',
};
