// @flow
const artifactListTranslatedColumns = [
  'city',
  'voivodeship',
  'county',
  'community',
  'natural_resource',
  'chronology',
  'archaeological_culture',
  'artifact_type',
  'azp',
];

export default artifactListTranslatedColumns;
