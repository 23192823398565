// @flow
import * as React from 'react';
import { Card } from 'antd';
import { ifProp } from 'styled-tools';
import store from 'store';
import styled, { css } from 'styled-components';

import ArtifactsHistoricalTable from './ArtifactsTable/ArtifactsHistoricalTable';
import ArtifactsArchaeologicalTable from './ArtifactsTable/ArtifactsArchaeologicalTable';
import ArtifactsAdminTable from './ArtifactsTable/ArtifactsAdminTable';
import ArtifactsFilters from './ArtifactsFilters';
import BreadCrumb from '../../../components/BreadCrumb';
import { pageContainerStyle } from '../../../lib/style/pageStyle';
import { accessibility } from '../../../lib/constants/themeModes';
import { darkBlueColor } from '../../../lib/style/themes';
import Breakpoints from '../../../lib/constants/breakpoints';
import LowResolutionBaner from '../../../components/LowResolutionBaner/LowResolutionBaner';

import type { ArtifactFilterUrlType } from '../../../lib/types';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  filter: ArtifactFilterUrlType,
  location: {
    pathname: string,
  },
  type: string,
};

type StateType = {
  fullWidthTable: boolean,
};

class ArtifactsList extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    fullWidthTable: store.get('fullWidthTable') || false,
  };

  toggleFullWidthTable: (() => void) = () => {
    const { fullWidthTable } = this.state;

    this.setState({
      fullWidthTable: !fullWidthTable,
    });
    store.set('fullWidthTable', !fullWidthTable);
  };

  render(): React.Node {
    const {
      filter,
      location: { pathname },
      type,
    } = this.props;
    const { fullWidthTable } = this.state;

    const adminTypes = ['my-work', 'moderation', 'administration'];

    return (
      <React.Fragment>
        {type === 'historical' && <BreadCrumb breadcrumbs={['artifactsHistoricalList']} />}
        {type === 'archaeological' && <BreadCrumb breadcrumbs={['artifactsArchaeologicalList']} />}
        {type === 'my-work' && <BreadCrumb breadcrumbs={['artifactsMyWorkList']} />}
        {type === 'moderation' && <BreadCrumb breadcrumbs={['artifactsModerationList']} />}
        {type === 'administration' && <BreadCrumb breadcrumbs={['artifactsAdministrationList']} />}
        <StyledPageContainer $fullWidthTable={fullWidthTable}>
          <Card style={{ marginBottom: '20px' }}>
            <ArtifactsFilters key={pathname} type={type} toggleFullWidthTable={this.toggleFullWidthTable} />
          </Card>
          <Card>
            {type === 'archaeological' && <ArtifactsArchaeologicalTable key={pathname} filter={filter} type={type} />}
            {type === 'historical' && <ArtifactsHistoricalTable key={pathname} filter={filter} type={type} />}
            {adminTypes.includes(type) && <ArtifactsAdminTable key={pathname} filter={filter} />}
          </Card>
        </StyledPageContainer>
        <LowResolutionBaner />
      </React.Fragment>
    );
  }
}

const StyledPageContainer = styled.div`
  ${pageContainerStyle};
  transition: max-width 0.3s ease;
  max-width: ${ifProp(['$fullWidthTable'], '100%')};

  @media (max-width: ${Breakpoints.mobile}) {
    display: none;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-card-body {
        background-color: ${darkBlueColor};
      }
    `};
`;

export default (withRouter(ArtifactsList): any);
