// @flow
import { merge } from 'lodash';
import changeLanguage from './changeLanguage';
import changeTheme from './changeTheme';
import currentUser from './currentUser';
import artifacsHistoricalListColumns from './artifacsHistoricalListColumns';
import artifacsArchaeologicalListColumns from './artifacsArchaeologicalListColumns';
import artifacsMyWorkListColumns from './artifacsMyWorkListColumns';

const resolvers: any = merge(
  changeLanguage,
  changeTheme,
  currentUser,
  artifacsHistoricalListColumns,
  artifacsArchaeologicalListColumns,
  artifacsMyWorkListColumns,
);

export default resolvers;
