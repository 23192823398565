// @flow
import * as React from 'react';
import { IntlProvider, createIntl, createIntlCache } from 'react-intl';
import { graphql } from 'react-apollo';
import { ConfigProvider } from 'antd';
import messages from '../../config/intl';
import antdTranslations from '../../config/antdTranslations';
import GET_LANGUAGE_QUERY from '../../lib/queries/getLanguage';
import IntlGlobalProvider from '../IntlGlobalProvider';

type PropsType = {
  children: React.Node,
  GetLanguageQUERY: {
    language: string,
  },
};

const cache = createIntlCache();

const ConfiguredLanguageProviders = (props: PropsType): React.Node => {
  const [intl, setIntl] = React.useState(null);
  const {
    children,
    GetLanguageQUERY: { language },
  } = props;

  React.useEffect(() => {
    setIntl(createIntl({
      locale: language,
      messages,
      onError: () => {},
    }, cache));
  }, []);

  return (
    <ConfigProvider locale={antdTranslations[language]}>
      <IntlProvider locale={language} messages={messages[language]} defaultLocale="pl" onError={() => {}}>
        {intl && <IntlGlobalProvider intl={intl}>{children}</IntlGlobalProvider>}
      </IntlProvider>
    </ConfigProvider>
  );
};

export default (graphql(GET_LANGUAGE_QUERY, { name: 'GetLanguageQUERY' })(ConfiguredLanguageProviders): any);
