// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Col, Row, Empty } from 'antd';
import GET_HISTORICAL_ARTIFACT from '../../../lib/queries/getHistoricalArtifact';
import { pageContainerStyle } from '../../../lib/style/pageStyle';
import { StyledCard, StyledDetailsName, StyledDetail } from '../../../components/StyledComponents/StyledCard';
import StyledH2 from '../../../components/StyledComponents/StyledH2';
import type { ArtifactType, UserType, QueryRenderProps } from '../../../lib/types';
import artifactDataTranslationsMessages from '../../../lib/constants/artifactDataTranslationsMessages';

import LoadingSpin from '../../../components/LoadingSpin';
import ArtifactsDetailsCtaSection from './ArtifactsDetailsCtaSection';
import WarehouseLocationSection from './Sections/WarehouseLocationSection';
import AcceptRejectBar from './AcceptRejectBar';
import ContentForLoggedInUsers from '../../../components/ContentForLoggedInUsers';
import RenderButtonInBredcrumb from '../../../components/RenderButtonInBredcrumb';
import BreadCrumb from '../../../components/BreadCrumb';
import PhotosSection from '../../../components/PhotosSection';
import withUser from '../../../components/withUser';
import logo from '../../../lib/images/logo.svg';
import TranslatedValue from '../../../components/TranslatedValue/TranslatedValue';
import USER_LEVEL from '../../../lib/constants/userLevel';
import ARTIFACT_STATUS_TYPE from '../../../lib/constants/artifactStatus';
import NoDataBecauseError from '../../../components/NoDataBecauseError';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  artifactIdUrl: number,
  handlePrint: Function,
  intl: typeof IntlShape,
  params: { artifactId: number, shortCatalogId?: number, type: number },
  prepareNotes: Function,
  prepareRentals: Function,
  setChecbkoxValue: Function,
  user: ?UserType,
};

class ArtifactsHistoricalDetails extends React.PureComponent<PropsType, void> {
  render(): React.Node {
    const {
      artifactIdUrl,
      handlePrint,
      user,
      setChecbkoxValue,
      prepareRentals,
      prepareNotes,
      params: { shortCatalogId: shortCatalogIdUrl, type: typeUrl },
    } = this.props;

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['artifactsHistoricalList', 'artifactDetails']} />
        <StyledPageContainer>
          <Query
            query={GET_HISTORICAL_ARTIFACT}
            variables={{
              id: artifactIdUrl,
              isAdmin: user && !!user.email,
              shortCatalogId: shortCatalogIdUrl && typeUrl + shortCatalogIdUrl,
            }}
          >
            {({
              loading,
              error,
              data,
            }: QueryRenderProps<{
              historicalArtifact: ArtifactType,
            }>): React.Node => {
              if (loading) return <LoadingSpin tip="Loading..." />;
              if (error) return <NoDataBecauseError />;
              if (!data || !data.historicalArtifact) {
                return <Empty />;
              }

              const {
                artifact_type: artifactHistoricalType,
                // inventory_entry_at: inventoryEntryAt,
                natural_resource: naturalResource,
                acquisition_date: acquisitionDate,
                acquisition_method: acquisitionMethod,
                catalog_id: catalogId,
                city,
                community,
                county,
                creation_time: creationTime,
                creator,
                description,
                dimensions,
                id,
                implementation_technique: implementationTechnique,
                literature,
                main_photo: mainPhoto,
                notes,
                photos,
                place_of_origin: placeOfOrigin,
                preservation_state: preservationState,
                preserved,
                rentals,
                title,
                type,
                value,
                voivodeship,
                shelving_number: shelvingNumber,
                shelf_number: shelfNumber,
              } = data.historicalArtifact;

              const preparedNotes = notes && prepareNotes(notes);
              const emptyHistoricalValue = <FormattedMessage id="general.emptyValue" defaultMessage="brak" />;
              const preparedRental = prepareRentals(rentals);
              const artifactType = type && type.toLowerCase();

              const showAcceptRejectBar = user
                && user.level === USER_LEVEL.superAdmin
                && (data.historicalArtifact && data.historicalArtifact.status === ARTIFACT_STATUS_TYPE.ready);

              const url = window.location.href;

              return (
                <React.Fragment>
                  <RenderButtonInBredcrumb>
                    <ArtifactsDetailsCtaSection
                      artifactId={artifactIdUrl || id}
                      type={artifactType}
                      handlePrint={handlePrint}
                    />
                  </RenderButtonInBredcrumb>
                  <StyledH2 tabIndex="0">
                    <img src={logo} alt="Logo" />
                    <span>
                      {title} {catalogId && `- ${catalogId}`}
                    </span>
                  </StyledH2>
                  <Row gutter={12}>
                    <Col span={24}>
                      <StyledCard
                        className="show-print"
                        bordered={false}
                        title={<FormattedMessage id="ArtifactsDetailsContainer.url" defaultMessage="Link url" />}
                        tabIndex="0"
                      >
                        {url}
                      </StyledCard>
                    </Col>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="ArtifactsDetails.sectionLocationTitle"
                            defaultMessage="Miejsce pochodzenia"
                          />
)}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.voivodeship} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(voivodeship && <TranslatedValue value={voivodeship} />) || emptyHistoricalValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.county} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(county && <TranslatedValue value={county} />) || emptyHistoricalValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.community} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(community && <TranslatedValue value={community} />) || emptyHistoricalValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.city} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(city && <TranslatedValue value={city} />) || emptyHistoricalValue}
                        </StyledDetail>
                      </StyledCard>
                    </Col>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={
                          <FormattedMessage id="ArtifactsDetails.sectionAcquisitionTitle" defaultMessage="Nabycie" />
                        }
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage
                            id="ArtifactsHistoricalDetails.acquisitionMethod"
                            defaultMessage="Sposób nabycia"
                          />
                        </StyledDetailsName>
                        <StyledDetail>: {acquisitionMethod || emptyHistoricalValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage
                            id="ArtifactsHistoricalDetails.acquisitionDate"
                            defaultMessage="Data nabycia"
                          />
                        </StyledDetailsName>
                        <StyledDetail>: {acquisitionDate || emptyHistoricalValue}</StyledDetail>
                        <br />
                      </StyledCard>
                    </Col>
                  </Row>
                  <ContentForLoggedInUsers>
                    <WarehouseLocationSection
                      shelvingNumber={shelvingNumber}
                      shelfNumber={shelfNumber}
                      emptyValue={emptyHistoricalValue}
                    />
                  </ContentForLoggedInUsers>
                  <Row gutter={12}>
                    <Col lg={24}>
                      <StyledCard
                        bordered={false}
                        title={<FormattedMessage id="ArtifactsDetails.sectionArtifactTitle" defaultMessage="Zabytek" />}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <Row gutter={72}>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage id="ArtifactsHistoricalDetails.type" defaultMessage="Typ" />
                            </StyledDetailsName>
                            <StyledDetail>
                              : <FormattedMessage {...artifactDataTranslationsMessages[type]} />
                            </StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.artifactType"
                                defaultMessage="Rodzaj zabytku"
                              />
                            </StyledDetailsName>
                            <StyledDetail>
                              :{' '}
                              {(artifactHistoricalType && <TranslatedValue value={artifactHistoricalType} />)
                                || emptyHistoricalValue}
                            </StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.naturalResource"
                                defaultMessage="Surowiec"
                              />
                            </StyledDetailsName>
                            <StyledDetail>
                              :{' '}
                              {(naturalResource && <TranslatedValue value={naturalResource} />) || emptyHistoricalValue}
                            </StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.preserved"
                                defaultMessage="Konserwacja"
                              />
                            </StyledDetailsName>
                            <StyledDetail>: {setChecbkoxValue(!!preserved)}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage id="ArtifactsHistoricalDetails.value" defaultMessage="Wartość" />
                            </StyledDetailsName>
                            <StyledDetail>: {value || emptyHistoricalValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage id="ArtifactsHistoricalDetails.description" defaultMessage="Opis" />
                            </StyledDetailsName>
                            <StyledDetail>: {description || emptyHistoricalValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage id="ArtifactsHistoricalDetails.dimensions" defaultMessage="Wymiary" />
                            </StyledDetailsName>
                            <StyledDetail>: {dimensions || emptyHistoricalValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.implementationTechnique"
                                defaultMessage="Technika wykonania"
                              />
                            </StyledDetailsName>
                            <StyledDetail>: {implementationTechnique || emptyHistoricalValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.preservationState"
                                defaultMessage="Stan zachowania"
                              />
                            </StyledDetailsName>
                            <StyledDetail>: {preservationState || emptyHistoricalValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.literature"
                                defaultMessage="Literatura"
                              />
                            </StyledDetailsName>
                            <StyledDetail>: {literature || emptyHistoricalValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.placeOfOrigin"
                                defaultMessage="Miejsce powstania"
                              />
                            </StyledDetailsName>
                            <StyledDetail>: {placeOfOrigin || emptyHistoricalValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.creationTime"
                                defaultMessage="Czas powstania"
                              />
                            </StyledDetailsName>
                            <StyledDetail>: {creationTime || emptyHistoricalValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.creator"
                                defaultMessage="Autor/wytwórca"
                              />
                            </StyledDetailsName>
                            <StyledDetail>: {creator || emptyHistoricalValue}</StyledDetail>
                          </Col>
                        </Row>
                      </StyledCard>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col lg={24}>
                      <StyledCard
                        bordered={false}
                        title={
                          <FormattedMessage id="ArtifactsDetails.sectionRentalsTitle" defaultMessage="Wypożyczenia" />
                        }
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetail>{preparedRental}</StyledDetail>
                      </StyledCard>
                    </Col>
                  </Row>
                  <StyledCard
                    className="no-print"
                    bordered={false}
                    tabIndex="0"
                    title={<FormattedMessage id="ArtifactsSeparatedDetails.photos" defaultMessage="Zdjęcia" />}
                  >
                    <Row type="flex" gutter={12}>
                      <PhotosSection photos={mainPhoto ? [mainPhoto, ...photos] : [...photos]} />
                    </Row>
                  </StyledCard>
                  <ContentForLoggedInUsers>
                    <StyledCard
                      className="no-print"
                      bordered={false}
                      title={<FormattedMessage id="ArtifactsHistoricalDetails.notes" defaultMessage="Notatki" />}
                      headStyle={{ padding: '0 32px' }}
                      bodyStyle={{ padding: '24px 32px' }}
                    >
                      {preparedNotes}
                    </StyledCard>
                    {showAcceptRejectBar && (
                      <StyledCard>
                        <AcceptRejectBar />
                      </StyledCard>
                    )}
                  </ContentForLoggedInUsers>
                </React.Fragment>
              );
            }}
          </Query>
        </StyledPageContainer>
      </React.Fragment>
    );
  }
}

const StyledPageContainer = styled.div`
  ${pageContainerStyle};

  @media print {
    .ant-col-lg-12 {
      width: 50% !important;
      float: left;
    }
  }
`;

export default (withRouter(
  withUser(injectIntl(ArtifactsHistoricalDetails)),
): React$ComponentType<$Diff<React$ElementConfig<any>, any>>);
