// @flow
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Card, Col, Button } from 'antd';
import { withApollo } from 'react-apollo';
import REMOVE_NOTE from '../../../../lib/mutations/removeNote';
// Utils
import addsLocalIds from '../../../../lib/utils/addsLocalIds';
// Own Types
import type { NotesType } from '../../../../lib/types';

import NotesField from './NotesField';

type PropTypes = {
  client: any,
  getFieldDecorator: Function,
  getFieldValue: Function,
  notes: NotesType,
  setFieldsValue: Function,
};

type StateTypes = {
  initialDataWithLocalId: NotesType[],
  localId: number,
};

class NotesSection extends React.Component<PropTypes, StateTypes> {
  state = {
    localId: 0,
    initialDataWithLocalId: [],
  };

  componentDidMount() {
    const { notes } = this.props;

    const { dataWithLocalId: initialDataWithLocalId, lastLocalId } = addsLocalIds(notes);

    this.setState({
      initialDataWithLocalId,
      localId: lastLocalId + 1,
    });
  }

  handleRemoveNote = async (noteToRemove: NotesType): Promise<void> => {
    const { setFieldsValue, getFieldValue, client } = this.props;
    const notes = getFieldValue('notes');

    if (noteToRemove.id) {
      await client.mutate({
        mutation: REMOVE_NOTE,
        variables: {
          id: noteToRemove.id,
        },
      });
    }

    const filteredNotes = notes.filter((note: NotesType): boolean => note.localId !== noteToRemove.localId);

    setFieldsValue({
      notes: filteredNotes,
      notesForm: filteredNotes,
    });
  };

  handleAddNote = () => {
    const { localId } = this.state;
    const { setFieldsValue, getFieldValue } = this.props;
    const notesForm = getFieldValue('notesForm') || [];

    setFieldsValue({
      notesForm: [...notesForm, { localId, content: '' }],
    });

    this.setState({ localId: localId + 1 });
  };

  render(): React.Node {
    const { getFieldDecorator, getFieldValue } = this.props;
    const { initialDataWithLocalId } = this.state;

    getFieldDecorator('notesForm', { initialValue: initialDataWithLocalId });

    return (
      <StyledCard
        bordered={false}
        title={(
          <div className="title">
            <FormattedMessage id="NotesSection.title" defaultMessage="Notatki" />
          </div>
)}
      >
        <NotesField
          getFieldDecorator={getFieldDecorator}
          getFieldValue={getFieldValue}
          handleRemoveNote={this.handleRemoveNote}
          autoSize={{ minRows: 4, maxRows: 6 }}
        />
        <Col lg={24}>
          <StyledButton onClick={this.handleAddNote}>
            <FormattedMessage id="NotesSection.addNewKZMNote" defaultMessage="Dodaj notatkę" />
          </StyledButton>
        </Col>
      </StyledCard>
    );
  }
}

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default (withApollo(NotesSection): any);
