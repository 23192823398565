// @flow
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Card, Col, Button } from 'antd';
// Utils
import addsLocalIds from '../../../../lib/utils/addsLocalIds';
// Own Types
import type { RentalsType } from '../../../../lib/types';

import RentalsField from './RentalsField';
import ARTIFACT_STATUS_TYPE from '../../../../lib/constants/artifactStatus';

type PropTypes = {
  artifactStatus: String,
  getFieldDecorator: Function,
  getFieldValue: Function,
  isFieldTouched: Function,
  rentals: RentalsType[],
  resetFields: Function,
  setFieldsValue: Function,
};

type StateTypes = {
  initialDataWithLocalId: RentalsType[],
  localId: number,
};

class RentalsSection extends React.Component<PropTypes, StateTypes> {
  state: StateTypes = {
    localId: 0,
    initialDataWithLocalId: [],
  };

  componentDidMount() {
    const { rentals } = this.props;

    const { dataWithLocalId: initialDataWithLocalId, lastLocalId } = addsLocalIds(rentals);

    this.setState({
      initialDataWithLocalId,
      localId: lastLocalId + 1,
    });
  }

  handleRemoveRental: ((rentalToRemove: RentalsType) => void) = (rentalToRemove: RentalsType) => {
    const { setFieldsValue, getFieldValue } = this.props;
    const rentalsForm = getFieldValue('rentalsForm');
    const filteredRentals = rentalsForm.filter(
      (rental: RentalsType): boolean => rental.localId !== rentalToRemove.localId,
    );

    setFieldsValue({
      rentalsForm: filteredRentals,
    });
  };

  handleAddRental: (() => void) = () => {
    const { localId } = this.state;
    const { setFieldsValue, getFieldValue } = this.props;
    const rentalsForm = getFieldValue('rentalsForm') || [];

    setFieldsValue({
      rentalsForm: [...rentalsForm, { localId, content: '' }],
    });

    this.setState({ localId: localId + 1 });
  };

  render(): React.Node {
    const {
      getFieldDecorator,
      getFieldValue,
      resetFields,
      setFieldsValue,
      isFieldTouched,
      artifactStatus = ARTIFACT_STATUS_TYPE.new,
    } = this.props;
    const { initialDataWithLocalId } = this.state;

    const showRentalSection = !(
      artifactStatus === ARTIFACT_STATUS_TYPE.new
      || artifactStatus === ARTIFACT_STATUS_TYPE.draft
      || artifactStatus === ARTIFACT_STATUS_TYPE.rejected
    );

    if (!showRentalSection) return null;

    getFieldDecorator('rentalsForm', { initialValue: initialDataWithLocalId });

    const rentalsForm = getFieldValue('rentalsForm') || [];

    return (
      <StyledCard
        bordered={false}
        title={(
          <div className="title">
            <FormattedMessage id="RentalsSection.title" defaultMessage="Wypożyczenia" />
          </div>
)}
      >
        {rentalsForm.map(
          (rental: RentalsType): React.Node => {
            return (
              <RentalsField
                getFieldDecorator={getFieldDecorator}
                getFieldValue={getFieldValue}
                handleRemoveRentals={this.handleRemoveRental}
                isFieldTouched={isFieldTouched}
                key={rental.localId}
                name={`rentals[${rental.localId}]`}
                rental={rental}
                resetFields={resetFields}
                setFieldsValue={setFieldsValue}
              />
            );
          },
        )}
        <Col lg={24}>
          <StyledButton type="primary" onClick={this.handleAddRental}>
            <FormattedMessage id="RentalsSection.addNewKZMNote" defaultMessage="Dodaj wypożyczenie" />
          </StyledButton>
        </Col>
      </StyledCard>
    );
  }
}

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default RentalsSection;
