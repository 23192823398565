// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import CHANGE_THEME_MUTATION from '../../lib/mutations/changeTheme';
import { standard } from '../../lib/constants/themeModes';
import { darkBlueColor, generalFontSize } from '../../lib/style/themes';

type PropsType = {
  client: any,
};

const AccessibilitySwitchInAccessibility = (props: PropsType): React.Node => {
  const toogleTheme = async (): Promise<void> => {
    const { client } = props;

    await client.mutate({
      mutation: CHANGE_THEME_MUTATION,
      variables: { theme: { mode: standard } },
    });
  };

  return (
    <StyledButton onClick={toogleTheme} onKeyPress={toogleTheme} type="button">
      <FormattedMessage
        id="AccessibilitySwitchInAccessibility.turnOffDisabledFriendlyMode"
        defaultMessage="Wyłącz tryb przyjazny osobom niedowidzącym"
      />
    </StyledButton>
  );
};

const StyledButton = styled.button`
  margin: 8px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  color: ${darkBlueColor};
  background: yellow;
  font-size: ${generalFontSize};
  border: none;

  &:focus {
    border: 4px solid white;
  }
`;

export default (withApollo(AccessibilitySwitchInAccessibility): any);
