import gql from 'graphql-tag';

const GET_MASS_ARTIFACT_LABEL = gql`
  query GetMassArtifactLabel($id: ID) {
    massArtifact(id: $id) {
      title
      catalog_id
      short_catalog_id
      shelving_number
      shelf_number
    }
  }
`;

const GET_SEPARATED_ARTIFACT_LABEL = gql`
  query GetSeparatedArtifactLabel($id: ID) {
    separatedArtifact(id: $id) {
      title
      catalog_id
      short_catalog_id
      shelving_number
      shelf_number
    }
  }
`;

const GET_HISTORICAL_ARTIFACT_LABEL = gql`
  query GetHistoricalArtifactLabel($id: ID) {
    historicalArtifact(id: $id) {
      title
      catalog_id
      short_catalog_id
      shelving_number
      shelf_number
    }
  }
`;

export { GET_MASS_ARTIFACT_LABEL, GET_SEPARATED_ARTIFACT_LABEL, GET_HISTORICAL_ARTIFACT_LABEL };
