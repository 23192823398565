// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Query, withApollo } from 'react-apollo';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import {
  Table, Button, Spin, Row,
} from 'antd';

import type {
  ArtifactType,
  StringArtifactType,
  ArtifactFilterUrlType,
  QueryRenderProps,
} from '../../../../../lib/types';
import { accessibility } from '../../../../../lib/constants/themeModes';

// Queries & Mutation
import ALL_ARTIFACTS_QUERY from '../../../../../lib/queries/allArtifacts';
import { makeValueTrueFromArrayOfString } from '../ArtifactsTableUtils';

import withUser from '../../../../../components/withUser';
import { handleNestedFilter } from '../../ArtifactsFilters/ArtifactsFiltersUtils';
import { darkBlueColor, biggerFontSize } from '../../../../../lib/style/themes';
import CSVButton from '../CSVButton';

import type { ArtifactsTableHeaderType, AllArtifactsQueryVariablesType } from './ArtifactsAdminTableContainer';
import NoDataBecauseError from '../../../../../components/NoDataBecauseError/NoDataBecauseError';

type PropsType = {
  allArtifactsQueryVariables: AllArtifactsQueryVariablesType,
  artifactsTableHeader: ArtifactsTableHeaderType[],
  className: string,
  columnSortOrder: ?string,
  filter: ArtifactFilterUrlType,
  handleArtifactsTableSort: Function,
  limit: number,
  orderedColumns: Array<string>,
  pageNumber: number,
  prepareArtifactsTableBody: (data: ArtifactType[]) => StringArtifactType[],
  selectedColumns: Array<string>,
  updatePageNumber: () => void,
};

const ArtifactsTable = (props: PropsType): React.Node => {
  const {
    artifactsTableHeader,
    allArtifactsQueryVariables,
    columnSortOrder,
    limit,
    pageNumber,
    filter,
    selectedColumns,
    orderedColumns,
    prepareArtifactsTableBody,
    className,
    handleArtifactsTableSort,
    updatePageNumber,
  } = props;

  // selectedColumns is in format ['azp', 'cos'] for query we need it as { azp: true, zos: true }
  const queryVariables = makeValueTrueFromArrayOfString(selectedColumns);

  return (
    <Query
      query={ALL_ARTIFACTS_QUERY}
      variables={{
        ...queryVariables,
        order: columnSortOrder,
        paginator: { limit, page: 1 },
        filter: handleNestedFilter(filter),
      }}
    >
      {({
        loading,
        error,
        data,
        fetchMore,
      }: QueryRenderProps<{
        allArtifacts: {
          __typename: string,
          data: ArtifactType[],
          total: number,
        },
      }>): React.Node => {
        if (error) return <NoDataBecauseError />;

        if (data && data.allArtifacts && data.allArtifacts.data) {
          // eslint-disable-next-line
          const total = data.allArtifacts.total;

          const artifactsTableBody = prepareArtifactsTableBody(data.allArtifacts.data);

          return (
            <div role="textbox" tabIndex="0" aria-label="Tabela przedstawiająca wszystkie zabytki">
              <Row type="flex" justify="space-between">
                <ArtifactStatistics tabIndex="0">
                  <FormattedMessage id="ArtifactsTable.artifactFound" defaultMessage="Liczba znalezionych zabytków: " />
                  <ArtifactsCounter>
                    <FormattedNumber value={total} />
                  </ArtifactsCounter>
                </ArtifactStatistics>
                <div>
                  <CSVButton
                    columnSortOrder={columnSortOrder}
                    orderedColumns={orderedColumns}
                    selectedColumns={selectedColumns}
                    filter={filter}
                  />
                </div>
              </Row>
              <Table
                columns={artifactsTableHeader}
                loading={loading}
                dataSource={[...artifactsTableBody]}
                pagination={false}
                bordered
                scroll={{ x: 'auto' }}
                className={className}
                role="table"
                onChange={handleArtifactsTableSort}
              />
              <FormattedMessage id="general.loadMore" defaultMessage="Wczytaj kolejne">
                {(buttonText: string): React.Node => (
                  <StyledButton
                    type="primary"
                    disabled={[...artifactsTableBody].length >= total}
                    onClick={() => {
                      fetchMore({
                        variables: {
                          ...allArtifactsQueryVariables,
                          order: columnSortOrder,
                          paginator: { limit, page: pageNumber + 1 },
                        },
                        // eslint-disable-next-line
                        updateQuery: (prev, { fetchMoreResult }): any => {
                          if (!fetchMoreResult) return prev;

                          return {
                            ...prev,
                            allArtifacts: {
                              data: [...prev.allArtifacts.data, ...fetchMoreResult.allArtifacts.data],
                              __typename: fetchMoreResult.allArtifacts.__typename,
                            },
                          };
                        },
                      });
                      updatePageNumber();
                    }}
                  >
                    {buttonText}
                  </StyledButton>
                )}
              </FormattedMessage>
            </div>
          );
        }

        return <CenteredSpin />;
      }}
    </Query>
  );
};

const ArtifactsTableWithApollo = withApollo(withUser(ArtifactsTable));

const CenteredSpin = styled(Spin)`
  display: block !important;
  margin: 5px auto !important;
`;

const StyledArtifactsTable: any = styled(ArtifactsTableWithApollo)`
  .ant-table td {
    white-space: nowrap;
  }

  td: focus, th: focus {
    outline: none;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-table-thead > tr > th {
        background: yellow;
        color: ${darkBlueColor};
        &:focus {
          border: 4px solid red !important;
        }
      }
      td,
      th {
        border: 2px solid black;
        font-size: ${biggerFontSize};
        color: black;
        background: white;
        &:focus {
          border: 4px solid red !important;
        }
      }
      ::selection {
        background: yellow;
        color: black;
      }

      .ant-table-tbody > tr > td.ant-table-column-sort {
        background: white;
      }
    `};
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin: 20px 0;
`;

const ArtifactStatistics = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: gray;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${biggerFontSize};
      &:focus {
        border: 2px solid red !important;
      }
    `};
`;

const ArtifactsCounter = styled.div`
  font-size: 22px;
  font-weight: bold;
  min-width: 50px;
  text-align: center;
  border: 1px solid #e8e8e8;
  background: #fafafa;
  margin: 0 0 3px 3px;
  padding: 0 5px;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background: ${darkBlueColor};
    `};
`;

export default StyledArtifactsTable;
