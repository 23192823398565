// @flow
import * as React from 'react';
import { Query, withApollo } from 'react-apollo';
import {
  Table, Popconfirm, Button, Input,
} from 'antd';
import { Icon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import styled from 'styled-components';
import {
  defineMessages, injectIntl, IntlShape, FormattedMessage,
} from 'react-intl';
// Own Types
import type {
  DictionaryEntryType,
  PaginationDataType,
  SerachTypes,
  TableColumnsType,
  QueryRenderProps,
} from '../../lib/types';
// Queries
import ALL_DICTIONARY_ENTRIES_QUERY from '../../lib/queries/allDictionaryEntries';
import ALL_DICTIONARIES_QUERY from '../../lib/queries/allDictionaries';
import NoDataBecauseError from '../NoDataBecauseError';

type PropsType = {
  className: string,
  client: any,
  columnSortOrder: string,
  dictionaryId: string,
  handleRemoveDictionary: string => Promise<void>,
  handleSearchEnter: () => void,
  handleSearchReset: () => void,
  handleSort: () => Promise<void>,
  intl: typeof IntlShape,
  openUpdateDictionaryDrawer: DictionaryEntryType => Promise<void>,
  paginationData: PaginationDataType,
  serachFilter: SerachTypes,
  updatePageNumber: (pageNumber: number) => Promise<void>,
};

type StateType = {
  columns: TableColumnsType[],
};

class DictionaryTable extends React.PureComponent<PropsType, StateType> {
  static prepareColumns = (
    className: string,
    handleRemoveDictionary: (id: string) => Promise<void>,
    openUpdateDictionaryDrawer: (record: DictionaryEntryType) => Promise<void>,
    onPressSearchEnter: (selectedKeys: string, confirm: Function) => void,
    handleSearchReset: (clearFilters: Function) => void,
    dictionaryName: string,
    translations: { placeholder: string, reset: string, search: string, valueName: string },
  ): TableColumnsType[] => {
    return [
      {
        title: <span>{dictionaryName}</span>,
        dataIndex: translations.valueName,
        sorter: true,
        key: translations.valueName,
        // eslint-disable-next-line
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }): React.Node => (
          <div className={`${className} dictionary-search`}>
            <Input
              className="search-input"
              /* eslint-disable */
              // $FlowFixMe[prop-missing]
              ref={ele => (this.searchInput = ele)}
              /* eslint-enable */
              placeholder={translations.placeholder}
              value={selectedKeys[0]}
              // eslint-disable-next-line max-len
              onChange={(event: SyntheticInputEvent<HTMLInputElement>): Promise<void> => setSelectedKeys(event.target.value ? [event.target.value] : [])
              }
              onPressEnter={(): void => onPressSearchEnter(selectedKeys, confirm)}
            />
            <Button
              className="search-button"
              type="primary"
              onClick={(): void => onPressSearchEnter(selectedKeys, confirm)}
            >
              {translations.search}
            </Button>
            <Button className="search-button" onClick={(): void => handleSearchReset(clearFilters)}>
              {translations.reset}
            </Button>
          </div>
        ),
        onFilterDropdownVisibleChange: (visible: boolean) => {
          if (visible) {
            setTimeout(() => {
              // $FlowFixMe[prop-missing]
              this.searchInput.focus();
            });
          }
        },
        filterIcon: (filtered: boolean): React.Node => (
          <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />
        ),
        render: (rowText: string, record: DictionaryEntryType): React.Node => (
          <div className={`${className} dictionary-table`} key={record.id}>
            <span className="dictionary-table-text">{rowText}</span>
            <FormattedMessage id="general.edit" defaultMessage="Edytuj">
              {(buttonText: string): React.Node => (
                <Button
                  type="primary"
                  className="edit-button"
                  onClick={(): Promise<void> => openUpdateDictionaryDrawer(record)}
                >
                  {buttonText}
                </Button>
              )}
            </FormattedMessage>
            <FormattedMessage id="general.remove" defaultMessage="Usuń">
              {(buttonText: string): React.Node => (
                <Popconfirm
                  title={(
                    <FormattedMessage
                      id="DictionaryTable.removeQuestion"
                      defaultMessage="Czy na pewno chcesz usunąć wpis?"
                    />
)}
                  onConfirm={(): Promise<void> => handleRemoveDictionary(record.id)}
                  okText={<FormattedMessage id="general.yes" defaultMessage="Tak" />}
                  cancelText={<FormattedMessage id="general.no" defaultMessage="Nie" />}
                >
                  <Button>{buttonText}</Button>
                </Popconfirm>
              )}
            </FormattedMessage>
          </div>
        ),
      },
    ];
  };

  state = {
    columns: [],
  };

  componentDidMount() {
    this.renderColumns();
  }

  componentDidUpdate(prevProps: PropsType) {
    const { intl } = this.props;
    if (prevProps.intl.locale !== intl.locale) {
      this.renderColumns();
    }
  }

  renderColumns = async (): Promise<void> => {
    const {
      handleRemoveDictionary,
      openUpdateDictionaryDrawer,
      className,
      intl,
      handleSearchEnter,
      handleSearchReset,
    } = this.props;

    const translations = {
      placeholder: intl.formatMessage(DictionaryTable.translation.placeholder),
      search: intl.formatMessage(DictionaryTable.translation.search),
      reset: intl.formatMessage(DictionaryTable.translation.reset),
      valueName: `value_${intl.locale}`,
    };

    const { dictionaryName } = await this.getDictionaryName();

    const columns = DictionaryTable.prepareColumns(
      className,
      handleRemoveDictionary,
      openUpdateDictionaryDrawer,
      handleSearchEnter,
      handleSearchReset,
      dictionaryName,
      translations,
    );

    this.setState({ columns });
  };

  getDictionaryName = async (): Promise<{
    dictionaryName: string,
  }> => {
    const {
      client,
      dictionaryId,
      intl: { locale },
    } = this.props;

    const dictionaryData = await client.query({
      query: ALL_DICTIONARIES_QUERY,
      variables: { filter: { id: dictionaryId } },
      fetchPolicy: 'cache-first',
    });

    const dictionaryName = dictionaryData.data.allDictionaries.length > 0
      ? dictionaryData.data.allDictionaries[0][`value_${locale}`]
      : '???';

    return {
      dictionaryName,
    };
  };

  static translation = defineMessages({
    placeholder: {
      defaultMessage: 'Wpisz szukaną frazę',
      id: 'DictionaryTable.placeholder',
    },
    search: {
      defaultMessage: 'Szukaj',
      id: 'general.search',
    },
    reset: {
      defaultMessage: 'Wyczyść',
      id: 'general.reset',
    },
  });

  render(): React.Node {
    const {
      columnSortOrder,
      handleSort,
      dictionaryId,
      paginationData: { limit, pageNumber },
      updatePageNumber,
      serachFilter,
    } = this.props;

    const { columns } = this.state;

    return (
      <React.Fragment>
        <Query
          query={ALL_DICTIONARY_ENTRIES_QUERY}
          variables={{
            filter: { dictionaryId, ...serachFilter },
            paginator: { limit, page: 1 },
            order: columnSortOrder,
          }}
        >
          {({
            loading,
            error,
            data,
            fetchMore,
          }: QueryRenderProps<{
            allDictionaryEntries: { __typename: string, data: DictionaryEntryType[], total: number },
          }>): React.Node => {
            if (error) return <NoDataBecauseError />;

            if (data && data.allDictionaryEntries && data.allDictionaryEntries.data) {
              const dictionaryData = data.allDictionaryEntries.data;
              // eslint-disable-next-line
              const total = data.allDictionaryEntries.total;

              return (
                <React.Fragment>
                  <Table
                    pagination={false}
                    columns={columns}
                    loading={loading}
                    dataSource={dictionaryData}
                    bordered
                    role="table"
                    rowKey={(record: DictionaryEntryType): React.Node => record.id}
                    onChange={handleSort}
                  />
                  <FormattedMessage id="general.loadMore" defaultMessage="Wczytaj kolejne">
                    {(buttonText: string): React.Node => (
                      <StyledButtonAnt
                        type="primary"
                        disabled={dictionaryData.length >= total}
                        onClick={() => {
                          fetchMore({
                            variables: {
                              paginator: { limit, page: pageNumber + 1 },
                            },
                            // eslint-disable-next-line
                            updateQuery: (prev, { fetchMoreResult }): any => {
                              if (!fetchMoreResult) return prev;

                              return {
                                ...prev,
                                allDictionaryEntries: {
                                  data: [
                                    ...prev.allDictionaryEntries.data,
                                    ...fetchMoreResult.allDictionaryEntries.data,
                                  ],
                                  total: fetchMoreResult.allDictionaryEntries.total,
                                  __typename: fetchMoreResult.allDictionaryEntries.__typename,
                                },
                              };
                            },
                          });
                          updatePageNumber(pageNumber + 1);
                        }}
                      >
                        {buttonText}
                      </StyledButtonAnt>
                    )}
                  </FormattedMessage>
                </React.Fragment>
              );
            }

            return null;
          }}
        </Query>
      </React.Fragment>
    );
  }
}

const StyledButtonAnt = styled(Button)`
  width: 100%;
  margin: 20px 0;
`;

const StyledDictionaryTable = styled(DictionaryTable)`
  &.dictionary-table {
    display: flex;
    .edit-button {
      margin-right: 10px;
    }
  }

  .dictionary-table-text {
    flex-grow: 1;
  }

  &.dictionary-search {
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);

    .search-input {
      width: 180px;
      margin-right: 8px;
    }
    .search-button {
      margin-right: 8px;
    }
  }
`;

export default (injectIntl(withApollo(StyledDictionaryTable)): any);
