// @flow
import {
  GET_MASS_ARTIFACT_LABEL,
  GET_SEPARATED_ARTIFACT_LABEL,
  GET_HISTORICAL_ARTIFACT_LABEL,
} from '../../../lib/queries/getArtifactLabel';
import { ARTIFACT_STRING_TYPE } from '../../../lib/constants/artifactType';

import type { ArtifactStringUnionType } from '../../../lib/types';

function getLabelQueryPerType(type: ArtifactStringUnionType): ?string {
  // query niby jest stringiem ale slabym chyba
  switch (type) {
    case ARTIFACT_STRING_TYPE.mass:
      return GET_MASS_ARTIFACT_LABEL;
    case ARTIFACT_STRING_TYPE.historical:
      return GET_HISTORICAL_ARTIFACT_LABEL;
    case ARTIFACT_STRING_TYPE.separated:
      return GET_SEPARATED_ARTIFACT_LABEL;
    default:
      return undefined;
  }
}

function getShortCatalogUrl({ origin, shortCatalogId }: { origin: ?string, shortCatalogId: ?string }): ?string {
  if (!shortCatalogId || !origin) return undefined;

  return `${origin}/${[shortCatalogId.slice(0, 2), '/', shortCatalogId.slice(2)].join('')}`;
}

// eslint-disable-next-line import/prefer-default-export
export { getLabelQueryPerType, getShortCatalogUrl };
