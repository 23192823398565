// @flow
import * as React from 'react';
import deepMap from 'deep-map';
import { diff } from 'jsondiffpatch/dist/jsondiffpatch.umd';

import { format } from './formatters/siteChangeLogFormatter';
import { mapKeysDeep, translateValue, translateKey } from './ChnageLogDiffUtils';

import './formatters/siteChangeLogFormatter.css';

type PropsType = {
  left: any,
  right: any,
};

type StateType = {
  showDrawer: boolean,
};

class ChangeLogDiff extends React.PureComponent<PropsType, StateType> {
  render(): React.Node {
    const { left, right } = this.props;

    const delta = diff(left, right);

    const translatedKeysDelta = mapKeysDeep(delta, (_, key: string): string => translateKey(key));
    const translatedKeysAndValuesDelta = deepMap(translatedKeysDelta, (value: string): string => translateValue(value));

    const html = format(translatedKeysAndValuesDelta);

    return (
      //  eslint-disable-next-line
      <div dangerouslySetInnerHTML={{ __html: html }} />
    );
  }
}

export default ChangeLogDiff;
