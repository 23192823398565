// @flow
import type { TranslationType } from '../../types';

type DefinePageConfigArgType = {
  pageConfig: {
    [key: string]: any,
  },
  translations: any,
};

type DefinePageConfigType = {
  pageTitle: TranslationType,
  path: string,
  showBreadcrumbs: ?boolean,
  showPageTitle: ?boolean,
};

type DefinePageConfigReturnType = {
  [key: string]: any,
};

const missingTitle = {
  id: 'PageConfigMissingTitle',
  defaultMessage: 'Title is missing in pageConfig.js',
};

function definePageConfig({ translations, pageConfig }: DefinePageConfigArgType): DefinePageConfigReturnType {
  const definedPageConfig: DefinePageConfigType = { ...pageConfig };

  Object.keys(definedPageConfig).forEach((key: string) => {
    const config = definedPageConfig[key];

    if (!config.showPageTitle && config.showPageTitle !== false) {
      definedPageConfig[key].pageTitle = translations[`${key}Title`] || missingTitle;
    }

    if (!config.showBreadcrumbs) {
      definedPageConfig[key].showBreadcrumbs = true;
    }
  });

  return definedPageConfig;
}

export default definePageConfig;
