// @flow
import STATUS from '../../../constants/artifactStatus';
import USER_LEVEL from '../../../constants/userLevel';
import type { StatusType, UserType } from '../../../types';

type canIArtifactParamsType = {|
  ownerEmail: ?string,
  status: StatusType,
  user: UserType,
|};

type canIArtifactReturnType = {|
  canSendToReview: boolean,
  canUpdate: boolean,
|};

export default function canIArtifact({
  status = STATUS.new,
  user,
  ownerEmail,
}: canIArtifactParamsType): canIArtifactReturnType {
  const isDelegated = status === STATUS.delegated;
  const isDestroyed = status === STATUS.destroyed;
  const isDraft = status === STATUS.draft;
  const isMissing = status === STATUS.missing;
  const isNew = status === STATUS.new;
  const isReady = status === STATUS.ready;
  const isRejected = status === STATUS.rejected;

  const isStatusForbiddenToUpdate = isReady || isMissing || isDelegated || isDestroyed;
  const isStatusPossibleToUpdate = isNew || isDraft || isRejected;

  const isSuperAdmin = !!(user && user.level === USER_LEVEL.superAdmin);
  const isOwner = !ownerEmail || ownerEmail === user.email;

  const canUpdate = (isSuperAdmin && !isStatusForbiddenToUpdate) || (isOwner && isStatusPossibleToUpdate);
  const canSendToReview = (isOwner || isSuperAdmin) && isDraft;

  return {
    canUpdate,
    canSendToReview,
  };
}
