// @flow
import * as React from 'react';
import { Query, withApollo } from 'react-apollo';
import { Button, Row } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import styled from 'styled-components';
import { camelCase } from 'lodash';
import prepareColumnSortOrder from '../../../lib/utils/prepareColumnSortOrder';
// Queries
import ALL_DICTIONARIES_QUERY from '../../../lib/queries/allDictionaries';
import ALL_DICTIONARY_ENTRIES_QUERY from '../../../lib/queries/allDictionaryEntries';
// Mutation
import ADD_DICTIONARY_ENTRY from '../../../lib/mutations/addDictionaryEntry';
import REMOVE_DICTIONARY_ENTRY from '../../../lib/mutations/removeDictionaryEntry';
import UPDATE_DICTIONARY_ENTRY from '../../../lib/mutations/updateDictionaryEntry';
// Own Types
import type {
  DictionaryEntryType,
  PaginationDataType,
  AllDictionaryEntries,
  ValidatorRuleType,
  SerachTypes,
  QueryRenderProps,
} from '../../../lib/types';
// Componnent
import DictionaryDrawer from '../../../components/DictionaryDrawer';
import DictionaryTable from '../../../components/DictionaryTable';
import BreadCrumb from '../../../components/BreadCrumb';
// style
import { pageContainerStyle } from '../../../lib/style/pageStyle';
import NoDataBecauseError from '../../../components/NoDataBecauseError';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  className: string,
  client: any,
  intl: typeof IntlShape,
  params: { dictionaryId: string },
};

type StateType = {
  columnSortOrder: string,
  dictionaryData: DictionaryEntryType,
  paginationData: PaginationDataType,
  serachFilter: SerachTypes,
  showUpdateDictionaryDrawer: boolean,
};

const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

class Dictionary extends React.PureComponent<PropsType, StateType> {
  state = {
    columnSortOrder: 'valuePl_ASC',
    paginationData: {
      limit: 50,
      pageNumber: 1,
    },
    showUpdateDictionaryDrawer: false,
    dictionaryData: {
      id: '0',
      total: 0,
      value_pl: '',
    },
    serachFilter: {
      valuePl_contains: '',
    },
  };

  updatePageNumber = (pageNumber: number) => {
    const {
      paginationData: { limit },
    } = this.state;

    this.setState({ paginationData: { pageNumber, limit } });
  };

  handleSort = (_: Object, __: Object, sorter: { field: string, order: string }) => {
    const columnSortOrder = prepareColumnSortOrder(sorter);
    if (!columnSortOrder) return;
    const {
      paginationData: { limit },
    } = this.state;

    this.setState({
      columnSortOrder,
      paginationData: { pageNumber: 1, limit },
    });
  };

  addDictionaryUpdater = (cache: any, mutationResult: any) => {
    const {
      params: { dictionaryId },
    } = this.props;

    const { paginationData, columnSortOrder, serachFilter } = this.state;

    const { limit } = paginationData;

    const response: ?AllDictionaryEntries = cache.readQuery({
      query: ALL_DICTIONARY_ENTRIES_QUERY,
      variables: {
        filter: { dictionaryId, ...serachFilter },
        paginator: { limit, page: 1 },
        order: columnSortOrder,
      },
    });

    const { data } = mutationResult;

    if (response && data) {
      const {
        allDictionaryEntries: { __typename, total },
      } = response;
      cache.writeQuery({
        query: ALL_DICTIONARY_ENTRIES_QUERY,
        variables: {
          filter: { dictionaryId, ...serachFilter },
          paginator: { limit, page: 1 },
          order: columnSortOrder,
        },
        data: {
          allDictionaryEntries: {
            data: response.allDictionaryEntries.data.concat([data.addDictionaryEntry]),
            total: total + 1,
            __typename,
          },
        },
      });
    }
  };

  removeDictionaryUpdater = (cache: any, mutationResult: any) => {
    const {
      params: { dictionaryId },
    } = this.props;
    const { paginationData, columnSortOrder, serachFilter } = this.state;
    const { limit } = paginationData;

    const response: ?AllDictionaryEntries = cache.readQuery({
      query: ALL_DICTIONARY_ENTRIES_QUERY,
      variables: {
        filter: { dictionaryId, ...serachFilter },
        paginator: { limit, page: 1 },
        order: columnSortOrder,
      },
    });

    const { data } = mutationResult;
    if (response && data) {
      const {
        allDictionaryEntries: { __typename, total },
      } = response;
      const filteredData = response.allDictionaryEntries.data.filter(
        (dictionary: DictionaryEntryType): boolean => +dictionary.id !== +data.removeDictionaryEntry.id,
      );

      cache.writeQuery({
        query: ALL_DICTIONARY_ENTRIES_QUERY,
        variables: {
          filter: { dictionaryId, ...serachFilter },
          paginator: { limit, page: 1 },
          order: columnSortOrder,
        },
        data: {
          allDictionaryEntries: {
            data: filteredData,
            total: total - 1,
            __typename,
          },
        },
      });
    }
  };

  handleAddNewDictionary = async (values: DictionaryEntryType): Promise<void> => {
    const {
      params: { dictionaryId },
      client,
    } = this.props;

    await client.mutate({
      mutation: ADD_DICTIONARY_ENTRY,
      variables: {
        ...values,
        dictionary_id: dictionaryId,
      },
      update: this.addDictionaryUpdater,
    });
  };

  handleUpdateDictionary = async (values: DictionaryEntryType): Promise<void> => {
    const {
      params: { dictionaryId },
      client,
    } = this.props;

    await client.mutate({
      mutation: UPDATE_DICTIONARY_ENTRY,
      variables: {
        ...values,
        dictionary_id: dictionaryId,
      },
    });
  };

  openUpdateDictionaryDrawer = (dictionaryData: DictionaryEntryType) => {
    this.setState({
      showUpdateDictionaryDrawer: true,
      dictionaryData,
    });
  };

  handleCloseUpdateDictionaryDrawer = () => {
    this.setState({ showUpdateDictionaryDrawer: false });
  };

  handleRemoveDictionary = async (id: string): Promise<void> => {
    const { client } = this.props;

    await client.mutate({
      mutation: REMOVE_DICTIONARY_ENTRY,
      variables: {
        id,
      },
      update: this.removeDictionaryUpdater,
    });
  };

  uniqueDictionaryValidator = async (rule: ValidatorRuleType, value: string, callback: Function): Promise<void> => {
    const {
      client,
      params: { dictionaryId },
    } = this.props;
    const { paginationData, columnSortOrder, dictionaryData } = this.state;

    const { limit } = paginationData;
    const fieldName = camelCase(rule.field);

    const allDictionaries = await client.query({
      query: ALL_DICTIONARY_ENTRIES_QUERY,
      variables: {
        filter: { dictionaryId, [fieldName]: value },
        paginator: { limit, page: 1 },
        order: columnSortOrder,
      },
      fetchPolicy: 'network-only',
    });

    const existOnDictionaryList = allDictionaries.data.allDictionaryEntries.data.length > 0;
    const initialDictionaryDataValue = dictionaryData[rule.field];

    const isDifferentValue = initialDictionaryDataValue !== value;

    if (value && existOnDictionaryList && isDifferentValue) {
      callback(<FormattedMessage id="validation.unique" defaultMessage="Wartość już istnieje" />);
    }
  };

  handleSearchEnter = (selectedKeys: string, confirm: Function) => {
    const {
      intl: { locale },
    } = this.props;

    const localCapitalized = capitalizeFirstLetter(locale);

    this.setState({ serachFilter: { [`value${localCapitalized}_contains`]: selectedKeys[0] } });
    confirm();
  };

  handleSearchReset = (clearFilters: Function) => {
    const {
      intl: { locale },
    } = this.props;

    const localCapitalized = capitalizeFirstLetter(locale);
    this.setState({ serachFilter: { [`value${localCapitalized}_contains`]: '' } });
    clearFilters();
  };

  render(): React.Node {
    const {
      showUpdateDictionaryDrawer, dictionaryData, paginationData, columnSortOrder, serachFilter,
    } = this.state;
    const {
      className,
      intl: { locale },
      params: { dictionaryId },
    } = this.props;

    return (
      <React.Fragment>
        <Query query={ALL_DICTIONARIES_QUERY} fetchPolicy="cache-first" variables={{ filter: { id: dictionaryId } }}>
          {({ loading, error, data }: QueryRenderProps<{ allDictionaries: DictionaryEntryType[] }>): React.Node => {
            if (error) return <NoDataBecauseError />;

            if (data && data.allDictionaries && data.allDictionaries.length > 0) {
              const dictionaryName = data.allDictionaries[0][`value_${locale}`];

              return (
                <React.Fragment>
                  {!loading && (
                    <BreadCrumb breadcrumbs={['admin', 'dictionaries', 'dictionary']} title={dictionaryName} />
                  )}
                </React.Fragment>
              );
            }

            return null;
          }}
        </Query>
        <StyledPageContainer className={className}>
          <StyledPageContent>
            <Row type="flex" justify="space-between" className="title-row">
              <StyledButton type="primary" className="add-button" onClick={this.openUpdateDictionaryDrawer}>
                <FormattedMessage id="Dictionary.add" defaultMessage="Dodaj wpis" />
              </StyledButton>
            </Row>

            <DictionaryTable
              handleRemoveDictionary={this.handleRemoveDictionary}
              openUpdateDictionaryDrawer={this.openUpdateDictionaryDrawer}
              dictionaryId={dictionaryId}
              paginationData={paginationData}
              columnSortOrder={columnSortOrder}
              handleSort={this.handleSort}
              updatePageNumber={this.updatePageNumber}
              handleSearchEnter={this.handleSearchEnter}
              handleSearchReset={this.handleSearchReset}
              serachFilter={serachFilter}
            />

            <DictionaryDrawer
              handleAddNewDictionary={this.handleAddNewDictionary}
              handleCloseUpdateDictionaryDrawer={this.handleCloseUpdateDictionaryDrawer}
              handleUpdateDictionary={this.handleUpdateDictionary}
              showUpdateDictionaryDrawer={showUpdateDictionaryDrawer}
              dictionaryData={dictionaryData}
              uniqueDictionaryValidator={this.uniqueDictionaryValidator}
              dictionaryId={dictionaryId}
            />
          </StyledPageContent>
        </StyledPageContainer>
      </React.Fragment>
    );
  }
}

const StyledPageContainer = styled.div`
  ${pageContainerStyle};
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-right: 0 !important;
  margin-bottom: 15px;
`;

const StyledPageContent = styled.div`
  padding: 24px 32px;
  background-color: #fff;
  flex: 1;
`;

const StyledDictionary = styled(Dictionary)`
  .add-button,
  .edit-button {
    margin-right: 8px;
  }
`;

export default (Form.create()(withRouter(withApollo(injectIntl(StyledDictionary)))): any);
