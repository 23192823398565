import gql from 'graphql-tag';

export default gql`
  query separatedArtifact($id: ID, $isAdmin: Boolean = false, $shortCatalogId: String) {
    separatedArtifact(id: $id, short_catalog_id: $shortCatalogId) {
      id
      status @include(if: $isAdmin)
      created_at @include(if: $isAdmin)
      updated_at @include(if: $isAdmin)
      published_at @include(if: $isAdmin)
      created_by_email @include(if: $isAdmin)
      comment @include(if: $isAdmin)
      catalog_id
      short_catalog_id
      title
      inventory_entry_at
      type
      rentals {
        id
        rental_date
        renter
        return_date
      }
      created_by_id
      voivodeship {
        id
        value_pl
        value_en
        value_de
      }
      county {
        id
        value_pl
        value_en
        value_de
      }
      community {
        id
        value_pl
        value_en
        value_de
      }
      city {
        id
        value_pl
        value_en
        value_de
      }
      notes {
        id
        created_by_email
        content
        created_at
      }
      archaeological_culture {
        id
        value_pl
        value_en
        value_de
      }
      are
      are_quarter
      natural_layer
      azp {
        id
        value_pl
        value_en
        value_de
      }
      chronology {
        id
        value_pl
        value_en
        value_de
      }
      created_by_email
      excavation
      field_inventory_number
      hectare
      mechanical_layer
      object_layer
      object_number
      object_part
      ordinal_number_in_azp
      ordinal_number_in_city
      research_date
      research_executive
      section
      coordinates
      artifact_type {
        id
        value_pl
        value_en
        value_de
      }
      natural_resource {
        id
        value_pl
        value_en
        value_de
      }
      description
      dimensions
      implementation_technique
      preserved
      preservation_state
      value
      literature
      archaeological_culture {
        id
        value_pl
        value_en
        value_de
      }
      type
      notes {
        id
        created_by_email
        content
        created_at
      }
      rentals {
        id
        rental_date
        renter
        return_date
      }
      created_by_id
      status
      comment
      main_photo {
        id
        path
        description
      }
      photos {
        id
        path
        description
      }
      shelving_number
      shelf_number
    }
  }
`;
