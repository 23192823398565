import gql from 'graphql-tag';

export default gql`
  query AllArtifacts(
    $filter: ArtifactFilter, $paginator: Paginator, $order: String
  ) {
    allArtifacts(filter: $filter, paginator: $paginator, order: $order) {
      total
      data {
        ... on MassArtifact {
          id
          type
          title
          catalog_id
          short_catalog_id
          city {
            id
            value_pl
            value_en
            value_de
          }
          ordinal_number_in_city
          ordinal_number_in_azp
          azp {
            id
            value_pl
            value_en
            value_de
          }
          county {
            id
            value_pl
            value_en
            value_de
          }
          community {
            id
            value_pl
            value_en
            value_de
          }
          voivodeship {
            id
            value_pl
            value_en
            value_de
          }
          archaeological_culture {
            id
            value_pl
            value_en
            value_de
          }
          created_at
          updated_at
          published_at
          status
          created_by_email
          shelving_number
          shelf_number
        }
        ... on SeparatedArtifact {
          id
          type
          title
          catalog_id
          short_catalog_id
          city {
            id
            value_pl
            value_en
            value_de
          }
          ordinal_number_in_city
          ordinal_number_in_azp
          azp {
            id
            value_pl
            value_en
            value_de
          }
          county {
            id
            value_pl
            value_en
            value_de
          }
          community {
            id
            value_pl
            value_en
            value_de
          }
          voivodeship {
            id
            value_pl
            value_en
            value_de
          }
          chronology {
            id
            value_pl
            value_en
            value_de
          }
          archaeological_culture {
            id
            value_pl
            value_en
            value_de
          }
          artifact_type {
            id
            value_pl
            value_en
            value_de
          }
          natural_resource {
            id
            value_pl
            value_en
            value_de
          }
          created_at
          updated_at
          published_at
          status
          created_by_email
          shelving_number
          shelf_number
        }
        ... on HistoricalArtifact {
          id
          title
          catalog_id
          short_catalog_id
          type
          artifact_type {
            id
            value_pl
            value_en
            value_de
          }
          city {
            id
            value_pl
            value_en
            value_de
          }
          voivodeship {
            id
            value_pl
            value_en
            value_de
          }
          county {
            id
            value_pl
            value_en
            value_de
          }
          community {
            id
            value_pl
            value_en
            value_de
          }
          natural_resource {
            id
            value_pl
            value_en
            value_de
          }
          created_at
          updated_at
          published_at
          status
          created_by_email
          shelving_number
          shelf_number
        }
      }
    }
  }
`;
