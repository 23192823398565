// @flow
// React
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { message } from 'antd';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
// Queries and mutation
import ADD_HISTORICAL_ARITFACT from '../../../lib/mutations/addHistoricalArtifact';
// Components
import KZHForm from '../../../components/Forms/KZHForm';
import LoadingSpin from '../../../components/LoadingSpin';
import BreadCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';
// Own Types
import type { KZHType } from '../../../lib/types';

import parseNotes from '../../../lib/utils/parseNotes';
import parseRentals from '../../../lib/utils/parseRentals';
import parsePreserved from '../../../lib/utils/parsePreserved';
import parsePhotos from '../../../lib/utils/parsePhotos';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  client: any,
  intl: typeof IntlShape,
  navigate: any,
};

type StateType = {
  updating: boolean,
};

export class KZHNew extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    updating: false,
  };

  addNewKZH: (formValues: KZHType, afterSave: string) => Promise<void> = async (
    formValues: KZHType,
    afterSave: string,
  ): Promise<void> => {
    const { intl, client, navigate } = this.props;

    const { mainPhoto, photos } = parsePhotos(formValues.photos, formValues.photosForm);

    this.setState({ updating: true });
    const response = await client.mutate({
      mutation: ADD_HISTORICAL_ARITFACT,
      variables: {
        ...formValues,
        notes: parseNotes(formValues.notes),
        shelf_number: parseInt(formValues.shelf_number, 10),
        shelving_number: parseInt(formValues.shelving_number, 10),
        are: parseInt(formValues.are, 10),
        archaeological_culture_id: parseInt(formValues.archaeological_culture_id, 10),
        other_mass_artifact_elements: parseInt(formValues.archaeological_culture_id, 10),
        rentals: parseRentals(formValues.rentals),
        acquisition_date: formValues.acquisition_date && formValues.acquisition_date.format('YYYY-MM-DD'),
        inventory_entry_at: formValues.inventory_entry_at && formValues.inventory_entry_at.format('YYYY-MM-DD'),
        preserved: parsePreserved(formValues.preserved),
        main_photo: mainPhoto,
        photos,
      },
    });
    this.setState({ updating: false });

    if ('errors' in response) {
      return;
    }

    const translatedSuccessMessage = intl.formatMessage(KZHNew.translations.submitSuccessMessage);
    if (afterSave === 'list') {
      navigate('/artifacts/list/historical');
    } else if (response && response.data && response.data.addHistoricalArtifact) {
      navigate(`/admin/historicalartifact/${String(response.data.addHistoricalArtifact.id)}/edit`);
    }
    message.success(translatedSuccessMessage);
  };

  static translations: any = defineMessages({
    submitSuccessMessage: {
      defaultMessage: 'Zabytek historiczny został prawidłowo dodany',
      id: 'KZHNew.submitSuccessMessage',
    },
    submitErrorMessage: {
      defaultMessage: 'Błąd walidacji formularza, sprawdź wszystkie pola',
      id: 'KZHNew.errorMessage',
    },
  });

  render(): React.Node {
    const { intl } = this.props;
    const { updating } = this.state;
    const submitErrorMessage = intl.formatMessage(KZHNew.translations.submitErrorMessage);

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['admin', 'addKZH']} />
        {updating && <LoadingSpin tip="Loading..." />}
        {!updating && <KZHForm onSubmit={this.addNewKZH} submitErrorMessage={submitErrorMessage} />}
      </React.Fragment>
    );
  }
}

export default (withRouter(withApollo(injectIntl(withUser(KZHNew)))): any);
