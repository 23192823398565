// @flow
import * as React from 'react';
import { compile } from 'path-to-regexp';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import withRouter from '../../lib/utils/withRouter';

type PropsType = {
  children: React.Node,
  className?: string,
  linkParams?: ?Object,
  linkPattern: string,
  linkState: ?Object,
  searchParams?: ?Object,
  serviceUrl: ?string,
};

const LinkTo = (props: PropsType): React.Node => {
  const {
    serviceUrl, linkPattern, linkParams, linkState, searchParams, children, className = '',
  } = props;

  const toPath = compile(linkPattern);
  const link = toPath(linkParams);

  let searchString = queryString.stringify(searchParams, { encode: false });
  if (searchString) searchString = `?${searchString}`;

  const href = `${serviceUrl || '/'}${link}${searchString}`;

  if (!serviceUrl) {
    return (
      <Link to={href.slice(1)} state={linkState} className={className}>
        {children}
      </Link>
    );
  }

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
      {children}
    </a>
  );
};

LinkTo.defaultProps = {
  className: null,
  linkParams: {},
  searchParams: {},
};

export default (withRouter(LinkTo): any);
