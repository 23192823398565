import gql from 'graphql-tag';

export default gql`
  query AllDictionaries($filter: DictionaryFilter) {
    allDictionaries(filter: $filter) {
      id
      value_pl
      value_en
      value_de
    }
  }
`;
