import plPL from 'antd/lib/locale-provider/pl_PL';
import deDE from 'antd/lib/locale-provider/de_DE';
import enGB from 'antd/lib/locale-provider/en_GB';

const antdTranslations = {
  pl: plPL,
  de: deDE,
  en: enGB,
};

export default antdTranslations;
