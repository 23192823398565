// @flow

const divideArrayToParts = (array: Array<string>, parts: number): Object => {
  const restElements = array.length % parts;
  let restUsedElements = restElements;
  const partLength = Math.floor(array.length / parts);
  const dividedArray = [];

  for (let i = 0; i < array.length; i += partLength) {
    let end = partLength + i;
    let add = false;

    if (restElements !== 0 && restUsedElements) {
      end += 1;
      restUsedElements -= 1;
      add = true;
    }
    dividedArray.push(array.slice(i, end));

    if (add) {
      i += 1;
    }
  }

  return dividedArray;
};

export default divideArrayToParts;
