// @flow
import * as React from 'react';
import { Col, Row, Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { StyledCard, StyledDetailsName, StyledDetail } from '../../../../components/StyledComponents/StyledCard';
import artifactDataTranslationsMessages from '../../../../lib/constants/artifactDataTranslationsMessages';
import divideArrayToParts from '../../../../lib/utils/divideArrayToParts';
import type {
  OtherMassArtifactElementType, OtherMassArtifactElementInputType,
} from '../../../../lib/types';

type PropsType = {
  data: {
    animalBones: ?string,
    buildingCeramicsFragments: ?string,
    clayDishesFragments: ?string,
    emptyMassArtifactValue: React.Element<any>,
    flints: ?string,
    glassFragments: ?string,
    glassSlag: ?string,
    humanBones: ?string,
    ironSlag: ?string,
    leatherObjectFragments: ?string,
    metalObjects: ?string,
    otherMassArtifactElements: OtherMassArtifactElementInputType[],
    shells: ?string,
    stoveTilesFragments: ?string,
    threshingFloors: ?string,
    type: ?string,
  }
};

const ArtifactMassDetailedSection = (props: PropsType): React.Node => {
  const {
    data,
  } = props;

  const prepareOtherElements = (otherElements: OtherMassArtifactElementInputType[] | []): Object => {
    return otherElements.map(
      (otherElement: OtherMassArtifactElementType): Object => {
        const { description, quantity } = otherElement;
        const prepareOtherElement = {
          key: description,
          category: description,
          quantity,
        };

        return prepareOtherElement;
      },
    );
  };

  const preparedOtherMassArtifactElements = prepareOtherElements(data.otherMassArtifactElements);

  const detailsTableHeader = [
    {
      title: <FormattedMessage {...artifactDataTranslationsMessages.category} />,
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: <FormattedMessage {...artifactDataTranslationsMessages.quantity} />,
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];

  const detailsTableSource = [
    {
      key: 'clay_dishes_fragments',
      category: <FormattedMessage {...artifactDataTranslationsMessages.clay_dishes_fragments} />,
      quantity: data.clayDishesFragments || data.emptyMassArtifactValue,
    },
    {
      key: 'stove_tiles_fragments',
      category: <FormattedMessage {...artifactDataTranslationsMessages.stove_tiles_fragments} />,
      quantity: data.stoveTilesFragments || data.emptyMassArtifactValue,
    },
    {
      key: 'building_ceramics_fragments',
      category: <FormattedMessage {...artifactDataTranslationsMessages.building_ceramics_fragments} />,
      quantity: data.buildingCeramicsFragments || data.emptyMassArtifactValue,
    },
    {
      key: 'threshing_floors',
      category: <FormattedMessage {...artifactDataTranslationsMessages.threshing_floors} />,
      quantity: data.threshingFloors || data.emptyMassArtifactValue,
    },
    {
      key: 'human_bones',
      category: <FormattedMessage {...artifactDataTranslationsMessages.human_bones} />,
      quantity: data.humanBones || data.emptyMassArtifactValue,
    },
    {
      key: 'animal_bones',
      category: <FormattedMessage {...artifactDataTranslationsMessages.animal_bones} />,
      quantity: data.animalBones || data.emptyMassArtifactValue,
    },
    {
      key: 'shells',
      category: <FormattedMessage {...artifactDataTranslationsMessages.shells} />,
      quantity: data.shells || data.emptyMassArtifactValue,
    },
    {
      key: 'iron_slag',
      category: <FormattedMessage {...artifactDataTranslationsMessages.iron_slag} />,
      quantity: data.ironSlag || data.emptyMassArtifactValue,
    },
    {
      key: 'glass_slag',
      category: <FormattedMessage {...artifactDataTranslationsMessages.glass_slag} />,
      quantity: data.glassSlag || data.emptyMassArtifactValue,
    },
    {
      key: 'glass_fragments',
      category: <FormattedMessage {...artifactDataTranslationsMessages.glass_fragments} />,
      quantity: data.glassFragments || data.emptyMassArtifactValue,
    },
    {
      key: 'metal_objects',
      category: <FormattedMessage {...artifactDataTranslationsMessages.metal_objects} />,
      quantity: data.metalObjects || data.emptyMassArtifactValue,
    },
    {
      key: 'flints',
      category: <FormattedMessage {...artifactDataTranslationsMessages.flints} />,
      quantity: data.flints || data.emptyMassArtifactValue,
    },
    {
      key: 'leather_object_fragments',
      category: <FormattedMessage {...artifactDataTranslationsMessages.leather_object_fragments} />,
      quantity: data.leatherObjectFragments || data.emptyMassArtifactValue,
    },
    ...preparedOtherMassArtifactElements,
  ];

  const [
    detailsTableSourceLeft, detailsTableSourceRight,
  ] = divideArrayToParts(detailsTableSource, 2);

  return (
    <StyledCol gutter={12} lg={24}>
      <StyledCard
        bordered={false}
        title={<FormattedMessage id="ArtifactsDetails.sectionArtifactTitle" defaultMessage="Zabytek" />}
        tabIndex="0"
        headStyle={{ padding: '0 32px' }}
        bodyStyle={{ padding: '24px 32px' }}
      >
        <Row gutter={72}>
          <Col xs={24}>
            <StyledDetailsName>
              <FormattedMessage id="ArtifactsDetails.type" defaultMessage="Typ" />
            </StyledDetailsName>
            <StyledDetail>
              : <FormattedMessage {...artifactDataTranslationsMessages[data.type]} />
            </StyledDetail>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} className="col-print fix-print-padding">
            <Table
              dataSource={detailsTableSourceLeft}
              columns={detailsTableHeader}
              pagination={false}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
            <Table
              dataSource={detailsTableSourceRight}
              columns={detailsTableHeader}
              pagination={false}
            />
          </Col>
        </Row>
      </StyledCard>
    </StyledCol>
  );
};

const StyledCol = styled(Col)`
  @media print {
    .fix-print-padding .ant-table-wrapper {
      padding: 0 32px 16px;
    }
    .ant-table-body {
      font-size: 12px !important;
      height: 100%;
    }
    .ant-table-thead > tr > th,
    .ant-table-row > td {
      padding: 12px 0;
    }
  }
`;

export default ArtifactMassDetailedSection;
