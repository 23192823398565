// @flow
import * as React from 'react';
import styled from 'styled-components';
import logo from '../../lib/images/logo.svg';

const Logo = (): React.Node => (
  <LogoWrapper>
    <img src={logo} className="logo-icon" alt="E-archiwum Archeologiczne" />
    <span className="logo-name">
      eArchiwum
      <br /> Archeologiczne
    </span>
  </LogoWrapper>
);

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 14px 0;

  .ant-menu-item {
    padding: 0 !important;
  }

  .logo-icon {
    height: 46px;
    margin-right: 5px;
  }

  .logo-name {
    padding-top: 5px;
    line-height: 17px;
    color: #fff;
    font-weight: 600;
  }
`;

export default Logo;
