// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import ArtifactsChangeLogTable from './ArtifactsChangeLogTable';
import type { ArtifactChangesHeaderType, ArtifactFilterUrlType, QueryRenderProps } from '../../../../lib/types';

import ALL_ARTIFACTS_CHANGES_QUERY from '../../../../lib/queries/allArtifactChanges';
import withRouter from '../../../../lib/utils/withRouter';

type PropsType = {
  filter?: ArtifactFilterUrlType,
  params: {
    artifactId: string,
  },
};

class ArtifactsChangeLogTableContainer extends React.PureComponent<PropsType> {
  static defaultProps = {
    filter: {},
  };

  getFilter = (): Object => {
    const {
      params: { artifactId },
      filter,
    } = this.props;

    return { ...filter, artifactId };
  };

  render(): React.Node {
    const filter = this.getFilter();

    return (
      <Query query={ALL_ARTIFACTS_CHANGES_QUERY} variables={{ filter }}>
        {({
          loading,
          error,
          data,
        }: QueryRenderProps<{ allArtifactChanges: { data: ArtifactChangesHeaderType[] } }>): React.Node => {
          if (error) return `Error! ${error.message}`;

          const artifactChangesHeaders = (data && data.allArtifactChanges && data.allArtifactChanges.data) || [];

          return <ArtifactsChangeLogTable loading={loading} artifactChangesHeaders={artifactChangesHeaders} />;
        }}
      </Query>
    );
  }
}

export default (withRouter(ArtifactsChangeLogTableContainer): any);
