import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CookieConsent from 'react-cookie-consent';

const CookieBanner = () => {
  return (
    <CookieConsent
      containerClasses="no-print"
      location="bottom"
      buttonText={<FormattedMessage id="CookieBanner.button" defaultMessage="Rozumiem" />}
      cookieName="cookies"
      disableStyles
      buttonClasses="ant-btn ant-btn-primary"
      style={{
        alignItems: 'baseline',
        background: '#2B373B',
        color: 'white',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        left: '0',
        position: 'fixed',
        width: '100%',
        zIndex: '1000',
        padding: '10px 15px',
      }}
      contentStyle={{
        flex: '1 0 300px',
      }}
      buttonStyle={{
        margin: '5px auto',
      }}
      expires={150}
    >
      <FormattedMessage
        id="CookieBanner.message"
        defaultMessage="Ta strona używa ciasteczek (cookies), dzięki którym nasz serwis może działać lepiej."
      />{' '}
      <Link to="/about/privacy">
        <FormattedMessage id="CookieBanner.moreInformation" defaultMessage="Dowiedz się więcej." />
      </Link>
    </CookieConsent>
  );
};

export default CookieBanner;
