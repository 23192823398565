// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  FormattedMessage, defineMessages, injectIntl, IntlShape,
} from 'react-intl';
import { message } from 'antd';

type PropsType = {
  intl: typeof IntlShape,
  mutate: Function,
};

const translations = defineMessages({
  errorFileUploadToLarge: {
    defaultMessage: 'Rozmiar pliku za duży (max. 5 MB)',
    id: 'FileUpload.errorFileUploadToLarge',
  },
  errorFileType: {
    defaultMessage: 'Format pliku błędny, dopuszczalne są tylko obrazy',
    id: 'FileUpload.errorFileType',
  },
});

const FileUploadInput = (props: PropsType): React.Node => {
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const { intl, mutate } = props;
  const errorFileUploadToLarge = intl.formatMessage(translations.errorFileUploadToLarge);
  const errorFileType = intl.formatMessage(translations.errorFileType);
  const maxFileSize = 5242880; // file size in bits

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor="file" id="fileLabel">
      <UploadButton>
        <FormattedMessage id="FileUpload.button" defaultMessage="Dodaj plik (max. 5 MB)" />
      </UploadButton>
      <input
        id="file"
        /* eslint-disable */
        ref={inputFileRef}
        style={{ visibility: 'hidden' }}
        type="file"
        accept="image/*"
        required
        // eslint-disable-next-line
        onChange={({
          target: {
            files: [file],
            value,
          },
        }) => {
          if (file && file.size >= maxFileSize) {
            message.error(errorFileUploadToLarge);
          } else if (!/image/.test(file.type)) {
            message.error(errorFileType);
          } else if (file) {
            mutate({ variables: { file } });
          }
          // $FlowExpectedError[incompatible-use]
          inputFileRef.current.value = '';
        }}
      />
    </label>
  );
};

const UploadButton = styled.span`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  padding: 7px 15px;
  color: #fff;
  background-color: #1890ff;
  border-radius: 4px;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);

  &:hover {
    cursor: pointer;
  }
`;

export default (injectIntl(FileUploadInput): any);
