// @flow
import * as React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import styled from 'styled-components';

// component BodyRow cames from ant.desing->Table->Drag Sorting https://ant.design/components/table/#header
// TODO: add flow types
type PropsType = {
  className: string,
  clientOffset: Object,
  connectDragSource: Function,
  connectDropTarget: Function,
  dragRow: Object,
  index: number,
  initialClientOffset: Object,
  isOver: boolean,
  moveRow: Function,
  sourceClientOffset: Object,
  style: Object,
};

type DragDirection = 'downward' | 'upward' | null;

const dragDirection = (
  dragIndex: number,
  hoverIndex: number,
  initialClientOffset: Object,
  clientOffset: Object,
  sourceClientOffset: Object,
): DragDirection => {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2;
  const hoverClientY = clientOffset.y - sourceClientOffset.y;
  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward';
  }
  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward';
  }

  return null;
};

class BodyRow extends React.PureComponent<PropsType, void> {
  render(): React.Node {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      ...restProps
    } = this.props;

    const { style } = restProps;
    let { className } = restProps;

    const rowStyle = { ...style, cursor: 'move' };

    if (isOver && initialClientOffset) {
      const direction = dragDirection(
        dragRow.index,
        restProps.index,
        initialClientOffset,
        clientOffset,
        sourceClientOffset,
      );
      if (direction === 'downward') {
        className += ' drop-over-downward';
      }
      if (direction === 'upward') {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(connectDropTarget(<tr {...restProps} className={className} style={rowStyle} />));
  }
}

const rowSource = {
  beginDrag(props: { index: number }): Object {
    const { index } = props;

    return {
      index,
    };
  },
};

const rowTarget = {
  drop(props: { index: number, moveRow: Function }, monitor: Function): any {
    const dragIndex = monitor.getItem().index;
    const { index, moveRow } = props;
    const hoverIndex = index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }
    // Time to actually perform the action
    moveRow(dragIndex, hoverIndex);
    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.

    // eslint-disable-next-line
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget(
  'row',
  rowTarget,
  (connect: Object, monitor: Function): Object => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    sourceClientOffset: monitor.getSourceClientOffset(),
  }),
)(
  DragSource(
    'row',
    rowSource,
    (connect: Object, monitor: Function): Object => ({
      connectDragSource: connect.dragSource(),
      dragRow: monitor.getItem(),
      clientOffset: monitor.getClientOffset(),
      initialClientOffset: monitor.getInitialClientOffset(),
    }),
  )(BodyRow),
);

const StyledDragableBodyRow: any = styled(DragableBodyRow)`
  &.drop-over-upward td {
    border-top: 2px dashed #1890ff !important;
  }

  &.drop-over-downward td {
    border-bottom: 2px dashed #1890ff !important;
  }
`;

export default StyledDragableBodyRow;
