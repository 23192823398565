// @flow
import gql from 'graphql-tag';

export default (gql`
  query GetCurrentUser {
    current_user @client {
      id
      name
      level
      email
    }
  }
`: any);
