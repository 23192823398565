// @flow
// React
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { message } from 'antd';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
// Queries and mutation
import ADD_SEPARATED_ARTIFACT from '../../../lib/mutations/addSeparatedArtifact';
// Components
import KZWForm from '../../../components/Forms/KZWForm';
import LoadingSpin from '../../../components/LoadingSpin';
import BreadCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';
// Own Types
import type { KZWType } from '../../../lib/types';

import parseNotes from '../../../lib/utils/parseNotes';
import parseRentals from '../../../lib/utils/parseRentals';
import parsePreserved from '../../../lib/utils/parsePreserved';
import parsePhotos from '../../../lib/utils/parsePhotos';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  client: any,
  intl: typeof IntlShape,
  navigate: any,
};

type StateType = {
  updating: boolean,
};

export class KZMWnew extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    updating: false,
  };

  addKZW: (formValues: KZWType, afterSave: string) => Promise<void> = async (
    formValues: KZWType,
    afterSave: string,
  ): Promise<void> => {
    const { intl, client, navigate } = this.props;

    const { mainPhoto, photos } = parsePhotos(formValues.photos, formValues.photosForm);

    this.setState({ updating: true });
    const response = await client.mutate({
      mutation: ADD_SEPARATED_ARTIFACT,
      variables: {
        ...formValues,
        shelf_number: parseInt(formValues.shelf_number, 10),
        are: parseInt(formValues.are, 10),
        object_number: parseInt(formValues.object_number, 10),
        shelving_number: parseInt(formValues.shelving_number, 10),
        chronology_id: parseInt(formValues.chronology_id, 10),
        natural_resource_id: parseInt(formValues.natural_resource_id, 10),
        notes: parseNotes(formValues.notes),
        rentals: parseRentals(formValues.rentals),
        research_date: formValues.research_date && formValues.research_date.format('YYYY-MM-DD'),
        inventory_entry_at: formValues.inventory_entry_at && formValues.inventory_entry_at.format('YYYY-MM-DD'),
        preserved: parsePreserved(formValues.preserved),
        main_photo: mainPhoto,
        photos,
      },
    });
    this.setState({ updating: false });

    if ('errors' in response) {
      return;
    }

    const translatedSuccessMessage = intl.formatMessage(KZMWnew.translations.submitSuccessMessage);
    if (afterSave === 'list') {
      navigate('/artifacts/list/archaeological');
    } else if (response && response.data && response.data.addSeparatedArtifact) {
      navigate(`/admin/separatedartifact/${String(response.data.addSeparatedArtifact.id)}/edit`);
    }
    message.success(translatedSuccessMessage);
  };

  static translations: any = defineMessages({
    submitSuccessMessage: {
      defaultMessage: 'Zabytek wydzielony został prawidłowo dodany',
      id: 'KZMWnew.submitSuccessMessage',
    },
    submitErrorMessage: {
      defaultMessage: 'Błąd walidacji formularza, sprawdź wszystkie pola',
      id: 'KZMWnew.errorMessage',
    },
  });

  render(): React.Node {
    const { intl } = this.props;
    const { updating } = this.state;
    const submitErrorMessage = intl.formatMessage(KZMWnew.translations.submitErrorMessage);

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['admin', 'addKZW']} />
        {updating && <LoadingSpin tip="Loading..." />}
        {!updating && <KZWForm onSubmit={this.addKZW} submitErrorMessage={submitErrorMessage} />}
      </React.Fragment>
    );
  }
}

export default (withRouter(injectIntl(withUser(withApollo(KZMWnew)))): any);
