// @flow
import * as React from 'react';
import { Col, Button as ButtonAnt } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import REMOVE_OTHER_MASS_ARTIFACT_ELEMENT from '../../../../lib/mutations/removeOtherMassArtifactElement';
// Own Types
import type { KZMType, OtherMassArtifactElementInputType } from '../../../../lib/types';
// Components
import MonumentOtherField from './MonumentOtherField';
// Utils
import addsLocalIds from '../../../../lib/utils/addsLocalIds';

type PropsTypes = {
  client: any,
  getFieldDecorator: Function,
  getFieldValue: Function,
  otherMassArtifactElements: OtherMassArtifactElementInputType,
  setFieldsValue: Function,
};

type StateTypes = {
  initialDataWithLocalId: OtherMassArtifactElementInputType[],
  localId: number,
};

class MonumentOtherSection extends React.Component<PropsTypes, StateTypes> {
  state = {
    localId: 0,
    initialDataWithLocalId: [],
  };

  componentDidMount() {
    const { otherMassArtifactElements } = this.props;

    const { dataWithLocalId: initialDataWithLocalId, lastLocalId } = addsLocalIds(otherMassArtifactElements);

    this.setState({
      initialDataWithLocalId,
      localId: lastLocalId + 1,
    });
  }

  handleRemoveOtherMassArtifactElement = async (elementToRemove: OtherMassArtifactElementInputType): Promise<void> => {
    const { setFieldsValue, getFieldValue, client } = this.props;
    const otherMassArtifactElements = getFieldValue('other_mass_artifact_elements');

    if (elementToRemove.id) {
      await client.mutate({
        mutation: REMOVE_OTHER_MASS_ARTIFACT_ELEMENT,
        variables: {
          id: elementToRemove.id,
        },
      });
    }

    const filteredOtherMassArtifactElement = otherMassArtifactElements.filter(
      (item: KZMType): boolean => item.localId !== elementToRemove.localId,
    );

    setFieldsValue({
      otherMassArtifactElementsForm: filteredOtherMassArtifactElement,
    });
  };

  handleAddOtherMassArtifactElement = () => {
    const { localId } = this.state;
    const { setFieldsValue, getFieldValue } = this.props;
    const otherMassArtifactElements = getFieldValue('otherMassArtifactElementsForm') || [];

    setFieldsValue({
      otherMassArtifactElementsForm: [...otherMassArtifactElements, { localId, description: '' }],
    });

    this.setState({ localId: localId + 1 });
  };

  render(): React.Node {
    const { getFieldValue, getFieldDecorator } = this.props;
    const { initialDataWithLocalId } = this.state;

    getFieldDecorator('otherMassArtifactElementsForm', { initialValue: initialDataWithLocalId });

    return (
      <React.Fragment>
        <MonumentOtherField
          getFieldValue={getFieldValue}
          getFieldDecorator={getFieldDecorator}
          handleRemoveOtherMassArtifactElement={this.handleRemoveOtherMassArtifactElement}
        />
        <Col span={24}>
          <StyledButtonAnt type="primary" onClick={this.handleAddOtherMassArtifactElement}>
            <FormattedMessage id="MonumentOtherSection.addNewOtherMassArtifactElement" defaultMessage="Dodaj Inne" />
          </StyledButtonAnt>
        </Col>
      </React.Fragment>
    );
  }
}

const StyledButtonAnt = styled(ButtonAnt)`
  width: 100%;
`;

export default (withApollo(MonumentOtherSection): any);
