// @flow
import * as React from 'react';
import moment from 'moment';
import intersection from 'lodash/intersection';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { Badge } from 'antd';

function splitDatesInFilterObject(_filter: Object): Object {
  const filter = _filter;
  Object.keys(filter).forEach((key: string) => {
    if (key.substr(key.length - 5) === '_date' && filter[key] && filter[key].length === 2) {
      const startDate = moment(filter[key][0]);
      const endDate = moment(filter[key][1]);
      if (startDate.isValid() && endDate.isValid()) {
        filter[`${key}_gte`] = startDate.format('YYYY-MM-DD');
        filter[`${key}_lte`] = endDate.format('YYYY-MM-DD');
      }
      delete filter[key];
    }
  });

  return filter;
}

function makeFilterNameWithBadge(name: React.Node, filter: Object, ownFilters: string[]): React.Node {
  const filterArray = pickBy(filter, (v: mixed): boolean => !isEmpty(v));
  const count = intersection(ownFilters, Object.keys(filterArray)).length;

  return (
    <span>
      {name}
      {count > 0 && (
        <span>
          &nbsp;&nbsp;
          <Badge count={count} style={{ backgroundColor: '#1890ff' }} />
        </span>
      )}
    </span>
  );
}

function handleNestedFilter(_filter: Object): Object {
  const filter = _filter;
  Object.keys(filter).forEach((key: string) => {
    // We check if filter is nested filter, they contains -> symbol to store
    // to mark it in URL adress e.g. discoveries->chronology->id=22
    if (key.includes(',')) {
      let nestedFilter = {};
      // Then we split it to array and reverse
      // ['id', 'chronology', 'id']
      key
        .split(',')
        .reverse()
        .forEach((val: string, index: number) => {
          if (index === 0) {
            // For first value in the array we assign original filter value
            // { id: 22 }
            nestedFilter[val] = filter[key];
          } else {
            // For rest we pack previous value in new object to make nesting
            // { chronology: { id: 22 }} etc
            nestedFilter = { [val]: nestedFilter };
          }
        });

      // Delete previous nested object in -> format
      delete filter[key];

      // Add formatted nested filter to existing filter
      merge(filter, nestedFilter);
    }
  });

  return filter;
}

export { splitDatesInFilterObject, makeFilterNameWithBadge, handleNestedFilter };
