// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Col, Row, Empty } from 'antd';
import GET_SEPARATED_ARTIFACT from '../../../lib/queries/getSeparatedArtifact';
import { pageContainerStyle } from '../../../lib/style/pageStyle';
import { StyledCard, StyledDetailsName, StyledDetail } from '../../../components/StyledComponents/StyledCard';
import StyledH2 from '../../../components/StyledComponents/StyledH2';
import type { ArtifactType, UserType, QueryRenderProps } from '../../../lib/types';
import artifactDataTranslationsMessages from '../../../lib/constants/artifactDataTranslationsMessages';

import LoadingSpin from '../../../components/LoadingSpin';
import ArtifactsDetailsCtaSection from './ArtifactsDetailsCtaSection';
import WarehouseLocationSection from './Sections/WarehouseLocationSection';
import AcceptRejectBar from './AcceptRejectBar';
import ContentForLoggedInUsers from '../../../components/ContentForLoggedInUsers';
import RenderButtonInBredcrumb from '../../../components/RenderButtonInBredcrumb';
import PhotosSection from '../../../components/PhotosSection';
import BreadCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';
import logo from '../../../lib/images/logo.svg';
import TranslatedValue from '../../../components/TranslatedValue/TranslatedValue';
import USER_LEVEL from '../../../lib/constants/userLevel';
import ARTIFACT_STATUS_TYPE from '../../../lib/constants/artifactStatus';
import LinkTo from '../../../components/LinkTo/LinkTo';
import NoDataBecauseError from '../../../components/NoDataBecauseError';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  artifactIdUrl: number,
  handlePrint: Function,
  intl: typeof IntlShape,
  params: { artifactId: number, shortCatalogId?: number, type: number },
  prepareNotes: Function,
  prepareRentals: Function,
  setChecbkoxValue: Function,
  user: ?UserType,
};

class ArtifactsSeparatedDetails extends React.PureComponent<PropsType, void> {
  render(): React.Node {
    const {
      artifactIdUrl,
      handlePrint,
      user,
      prepareNotes,
      setChecbkoxValue,
      prepareRentals,
      params: { shortCatalogId: shortCatalogIdUrl, type: typeUrl },
    } = this.props;

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['artifactsArchaeologicalList', 'artifactDetails']} />
        <StyledPageContainer>
          <Query
            query={GET_SEPARATED_ARTIFACT}
            variables={{
              id: artifactIdUrl,
              isAdmin: user && !!user.email,
              shortCatalogId: shortCatalogIdUrl && typeUrl + shortCatalogIdUrl,
            }}
          >
            {({
              loading,
              error,
              data,
            }: QueryRenderProps<{
              separatedArtifact: ArtifactType,
            }>): React.Node => {
              if (loading) return <LoadingSpin tip="Loading..." />;
              if (error) return <NoDataBecauseError />;
              if (!data || !data.separatedArtifact) {
                return <Empty />;
              }

              const {
                artifact_type: artifactHistoricalType,
                natural_resource: naturalResource,
                archaeological_culture: archaeoCulture,
                are_quarter: areQuarter,
                are,
                azp,
                catalog_id: catalogId,
                chronology,
                city,
                community,
                coordinates,
                county,
                description,
                dimensions,
                excavation,
                field_inventory_number: fieldInventoryNumber,
                hectare,
                id,
                implementation_technique: implementationTechnique,
                literature,
                main_photo: mainPhoto,
                mechanical_layer: mechanicalLayer,
                natural_layer: naturalLayer,
                notes,
                object_layer: objectLayer,
                object_number: objectNumber,
                object_part: objectPart,
                ordinal_number_in_azp: ordinalNumberInAzp,
                ordinal_number_in_city: ordinalNumberInCity,
                photos,
                preservation_state: preservationState,
                preserved,
                rentals,
                research_date: researchDate,
                research_executive: researchExecutive,
                section,
                title,
                type,
                value,
                voivodeship,
                shelving_number: shelvingNumber,
                shelf_number: shelfNumber,
              } = data.separatedArtifact;

              const preparedNotes = notes && prepareNotes(notes);
              const preparedRentals = rentals && prepareRentals(rentals);

              const emptySeparatedValue = <FormattedMessage id="general.emptyValue" defaultMessage="brak" />;

              const artifactType = type && type.toLowerCase();

              const showAcceptRejectBar = user
                && user.level === USER_LEVEL.superAdmin
                && (data.separatedArtifact && data.separatedArtifact.status === ARTIFACT_STATUS_TYPE.ready);

              const url = window.location.href;

              return (
                <React.Fragment>
                  <RenderButtonInBredcrumb>
                    <ArtifactsDetailsCtaSection
                      artifactId={artifactIdUrl || id}
                      type={artifactType}
                      handlePrint={handlePrint}
                    />
                  </RenderButtonInBredcrumb>
                  <StyledH2 tabIndex="0">
                    <img src={logo} alt="Logo" />
                    <span>
                      {title} {catalogId && `- ${catalogId}`}
                    </span>
                  </StyledH2>
                  <Row gutter={12}>
                    <Col span={24}>
                      <StyledCard
                        className="show-print"
                        bordered={false}
                        title={<FormattedMessage id="ArtifactsDetailsContainer.url" defaultMessage="Link url" />}
                        tabIndex="0"
                      >
                        {url}
                      </StyledCard>
                    </Col>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="ArtifactsSeparatedDetails.sectionDiscoveryPlaceTitle"
                            defaultMessage="Miejsce odkrycia - informacje ogólne"
                          />
)}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.voivodeship} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(voivodeship && <TranslatedValue value={voivodeship} />) || emptySeparatedValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.county} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(county && <TranslatedValue value={county} />) || emptySeparatedValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.community} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(community && <TranslatedValue value={community} />) || emptySeparatedValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.city} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(city && <TranslatedValue value={city} />) || emptySeparatedValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.ordinal_number_in_city} />
                        </StyledDetailsName>
                        <StyledDetail>: {ordinalNumberInCity || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.azp} />
                        </StyledDetailsName>
                        <StyledDetail>: {(azp && <TranslatedValue value={azp} />) || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.ordinal_number_in_azp} />
                        </StyledDetailsName>
                        <StyledDetail>: {ordinalNumberInAzp || emptySeparatedValue}</StyledDetail>
                        <br />
                        {azp
                          && azp.value_pl
                          && ordinalNumberInAzp && (
                            <LinkTo
                              serviceUrl={process.env.REACT_APP_PLATFORM_ADDRESS}
                              linkPattern="sites/:azp/:ordinalNumberInAzp"
                              linkParams={{
                                azp: azp.value_pl,
                                ordinalNumberInAzp,
                              }}
                            >
                              <FormattedMessage
                                id="artifactsDetails.showAzpLink"
                                defaultMessage="Zobacz stanowisko archeologiczne"
                              />
                            </LinkTo>
                        )}
                      </StyledCard>
                    </Col>

                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="ArtifactsSeparatedDetails.sectionDiscoveryDetailsTitle"
                            defaultMessage="Miejsce odkrycia - informacje szczegółowe"
                          />
)}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.excavation} />
                        </StyledDetailsName>
                        <StyledDetail>: {excavation || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.section} />
                        </StyledDetailsName>
                        <StyledDetail>: {section || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.hectare} />
                        </StyledDetailsName>
                        <StyledDetail>: {hectare || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.are} />
                        </StyledDetailsName>
                        <StyledDetail>: {are || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.are_quarter} />
                        </StyledDetailsName>
                        <StyledDetail>: {areQuarter || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.natural_layer} />
                        </StyledDetailsName>
                        <StyledDetail>: {naturalLayer || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.mechanical_layer} />
                        </StyledDetailsName>
                        <StyledDetail>: {mechanicalLayer || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...artifactDataTranslationsMessages.coordinates} />
                        </StyledDetailsName>
                        <StyledDetail>: {coordinates || emptySeparatedValue}</StyledDetail>
                      </StyledCard>
                    </Col>
                  </Row>
                  <ContentForLoggedInUsers>
                    <WarehouseLocationSection
                      shelvingNumber={shelvingNumber}
                      shelfNumber={shelfNumber}
                      emptyValue={emptySeparatedValue}
                    />
                  </ContentForLoggedInUsers>
                  <Row gutter={12}>
                    <Col lg={24}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="ArtifactsSeparatedDetails.sectionArtifactTitle"
                            defaultMessage="Zabytek"
                          />
)}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <Row gutter={72}>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage id="KZMMonumentSection.type" defaultMessage="Typ" />
                            </StyledDetailsName>
                            <StyledDetail>
                              : <FormattedMessage {...artifactDataTranslationsMessages[type]} />
                            </StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.artifactType"
                                defaultMessage="Rodzaj zabytku"
                              />
                            </StyledDetailsName>
                            <StyledDetail>
                              :{' '}
                              {(artifactHistoricalType && <TranslatedValue value={artifactHistoricalType} />)
                                || emptySeparatedValue}
                            </StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsHistoricalDetails.naturalResource"
                                defaultMessage="Surowiec"
                              />
                            </StyledDetailsName>
                            <StyledDetail>
                              :{' '}
                              {(naturalResource && <TranslatedValue value={naturalResource} />) || emptySeparatedValue}
                            </StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage id="KZMMonumentSection.preserved" defaultMessage="Konserwacja" />
                            </StyledDetailsName>
                            <StyledDetail>: {setChecbkoxValue(!!preserved)}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage id="KZMMonumentSection.value" defaultMessage="Wartość" />
                            </StyledDetailsName>
                            <StyledDetail>: {value || emptySeparatedValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage id="KZMMonumentSection.description" defaultMessage="Opis" />
                            </StyledDetailsName>
                            <StyledDetail>: {description || emptySeparatedValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage id="KZMMonumentSection.dimensions" defaultMessage="Wymiary" />
                            </StyledDetailsName>
                            <StyledDetail>: {dimensions || emptySeparatedValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage
                                id="KZMMonumentSection.implementationTechnique"
                                defaultMessage="Technika wykonania"
                              />
                            </StyledDetailsName>
                            <StyledDetail>: {implementationTechnique || emptySeparatedValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage
                                id="KZMMonumentSection.preservationState"
                                defaultMessage="Stan zachowania"
                              />
                            </StyledDetailsName>
                            <StyledDetail>: {preservationState || emptySeparatedValue}</StyledDetail>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage id="KZMMonumentSection.literature" defaultMessage="Literatura" />
                            </StyledDetailsName>
                            <StyledDetail>: {literature || emptySeparatedValue}</StyledDetail>
                          </Col>
                        </Row>
                      </StyledCard>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={
                          <FormattedMessage id="ArtifactsSeparatedDetails.sectionObjectTitle" defaultMessage="Obiekt" />
                        }
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage id="ArtifactsSeparatedDetails.objectNumber" defaultMessage="Nr obiektu" />
                        </StyledDetailsName>
                        <StyledDetail>: {objectNumber || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage id="ArtifactsSeparatedDetails.objectPart" defaultMessage="Część obiektu" />
                        </StyledDetailsName>
                        <StyledDetail>: {objectPart || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage
                            id="ArtifactsSeparatedDetails.objectLayer"
                            defaultMessage="Warstwa obiektu"
                          />
                        </StyledDetailsName>
                        <StyledDetail>: {objectLayer || emptySeparatedValue}</StyledDetail>
                        <br />
                      </StyledCard>
                    </Col>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="ArtifactsSeparatedDetails.sectionResearchesTitle"
                            defaultMessage="Badania"
                          />
)}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetailsName>
                          <FormattedMessage id="ArtifactsSeparatedDetails.researchesDate" defaultMessage="Data badań" />
                        </StyledDetailsName>
                        <StyledDetail>: {researchDate || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage
                            id="ArtifactsSeparatedDetails.researchesExecutive"
                            defaultMessage="Wykonawca badań"
                          />
                        </StyledDetailsName>
                        <StyledDetail>: {researchExecutive || emptySeparatedValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage
                            id="ArtifactsSeparatedDetails.researchesFieldInventoryNumbers"
                            defaultMessage="Nr inwentarza polowego"
                          />
                        </StyledDetailsName>
                        <StyledDetail>: {fieldInventoryNumber || emptySeparatedValue}</StyledDetail>
                        <br />
                      </StyledCard>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col lg={24}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="ArtifactsSeparatedDetails.sectionOriginTitle"
                            defaultMessage="Pochodzenie"
                          />
)}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <Row gutter={72}>
                          <Col xs={24} sm={12} md={12} lg={12}>
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsSeparatedDetails.archeoCultures"
                                defaultMessage="Kultura"
                              />
                            </StyledDetailsName>
                            <StyledDetail>
                              : {(archaeoCulture && <TranslatedValue value={archaeoCulture} />) || emptySeparatedValue}
                            </StyledDetail>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} className="col-print">
                            <StyledDetailsName>
                              <FormattedMessage
                                id="ArtifactsSeparatedDetails.chronology"
                                defaultMessage="Chronologia"
                              />
                            </StyledDetailsName>
                            <StyledDetail>
                              : {(chronology && <TranslatedValue value={chronology} />) || emptySeparatedValue}
                            </StyledDetail>
                          </Col>
                        </Row>
                      </StyledCard>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col lg={24}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="ArtifactsSeparatedDetails.sectionRentalsTitle"
                            defaultMessage="Wypożyczenia"
                          />
)}
                        tabIndex="0"
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                      >
                        <StyledDetail>{preparedRentals}</StyledDetail>
                      </StyledCard>
                    </Col>
                  </Row>
                  <StyledCard
                    className="no-print"
                    tabIndex="0"
                    bordered={false}
                    title={<FormattedMessage id="ArtifactsSeparatedDetails.photos" defaultMessage="Zdjęcia" />}
                  >
                    <Row type="flex" gutter={12}>
                      <PhotosSection photos={mainPhoto ? [mainPhoto, ...photos] : [...photos]} />
                    </Row>
                  </StyledCard>
                  <ContentForLoggedInUsers>
                    <StyledCard
                      className="no-print"
                      bordered={false}
                      title={<FormattedMessage id="ArtifactsSeparatedDetails.creationTime" defaultMessage="Notatki" />}
                      headStyle={{ padding: '0 32px' }}
                      bodyStyle={{ padding: '24px 32px' }}
                    >
                      {preparedNotes}
                    </StyledCard>
                    {showAcceptRejectBar && (
                      <StyledCard>
                        <AcceptRejectBar type="SEPARATED" />
                      </StyledCard>
                    )}
                  </ContentForLoggedInUsers>
                </React.Fragment>
              );
            }}
          </Query>
        </StyledPageContainer>
      </React.Fragment>
    );
  }
}

const StyledPageContainer = styled.div`
  ${pageContainerStyle};

  @media print {
    .ant-col-lg-12 {
      width: 50% !important;
      float: left;
    }
  }
`;

export default (withRouter(
  withUser(injectIntl(ArtifactsSeparatedDetails)),
): React$ComponentType<$Diff<React$ElementConfig<any>, any>>);
