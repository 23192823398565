// @flow
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Card, Row, Col, Input, Radio,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
// Own Component
import DictionarySelect from '../../DictionarySelect';
import dictionariesId from '../../../lib/constants/dictionariesId';
import { required } from '../../../lib/formValidation';

const FormItem = Form.Item;
const { TextArea } = Input;
const RadioGroup = Radio.Group;

type PropTypes = {
  artifactType: string,
  creationTime: string,
  creator: string,
  description: string,
  dimensions: string,
  formType: string,
  getFieldDecorator: Function,
  implementationTechnique: string,
  literature: string,
  naturalResource: string,
  placeOfOrigin: string,
  preservationState: string,
  preserved: string,
  setFieldsValue: Function,
  value: string,
};

const KZMMonumentSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator,
    setFieldsValue,
    formType,
    preserved,
    value,
    description,
    dimensions,
    implementationTechnique,
    literature,
    preservationState,
    placeOfOrigin,
    creationTime,
    creator,
    artifactType,
    naturalResource,
  } = props;

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          <FormattedMessage id="KZMMonumentSection.title" defaultMessage="Zabytek" />
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col span={24}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.preserved" defaultMessage="Konserwacja" />}>
            {getFieldDecorator('preserved', {
              initialValue: preserved ? preserved.toString() : 'false',
            })(
              <RadioGroup>
                <Radio value="true">
                  <FormattedMessage id="general.yes" defaultMessage="Tak" />
                </Radio>
                <Radio value="false">
                  <FormattedMessage id="general.no" defaultMessage="Nie" />
                </Radio>
              </RadioGroup>,
            )}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.value" defaultMessage="Wartość" />}>
            {getFieldDecorator('value', {
              initialValue: value || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <DictionarySelect
            dictionaryId={dictionariesId.artifactTypeId}
            label={<FormattedMessage id="KZMMonumentSection.artifactType" defaultMessage="Rodzaj zabytku" />}
            name="artifact_type_id"
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            initialFieldValue={artifactType || null}
            validationRules={[required]}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <DictionarySelect
            dictionaryId={dictionariesId.naturalResourceId}
            label={<FormattedMessage id="KZMMonumentSection.naturalResource" defaultMessage="Surowiec" />}
            name="natural_resource_id"
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            initialFieldValue={naturalResource || null}
          />
        </Col>
        <Col span={24}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.description" defaultMessage="Opis" />}>
            {getFieldDecorator('description', {
              rules: [required],
              initialValue: description || null,
            })(<TextArea autoSize={{ minRows: 4, maxRows: 6 }} />)}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.dimensions" defaultMessage="Wymiary" />}>
            {getFieldDecorator('dimensions', {
              initialValue: dimensions || null,
            })(<TextArea autoSize={{ minRows: 4, maxRows: 6 }} />)}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label={
              <FormattedMessage id="KZMMonumentSection.implementation_technique" defaultMessage="Technika wykonania" />
            }
          >
            {getFieldDecorator('implementation_technique', {
              initialValue: implementationTechnique || null,
            })(<TextArea autoSize={{ minRows: 4, maxRows: 6 }} />)}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label={<FormattedMessage id="KZMMonumentSection.preservationState" defaultMessage="Stan zachowania" />}
          >
            {getFieldDecorator('preservation_state', {
              initialValue: preservationState || null,
            })(<TextArea autoSize={{ minRows: 4, maxRows: 6 }} />)}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.literature" defaultMessage="Literatura" />}>
            {getFieldDecorator('literature', {
              initialValue: literature || null,
            })(<TextArea autoSize={{ minRows: 4, maxRows: 6 }} />)}
          </FormItem>
        </Col>
        {formType === 'KZH' && (
          <React.Fragment>
            <Col xs={24} sm={12} md={8} lg={8}>
              <FormItem
                label={<FormattedMessage id="KZMMonumentSection.placeOfOrigin" defaultMessage="Miejsce powstania" />}
              >
                {getFieldDecorator('place_of_origin', {
                  initialValue: placeOfOrigin || null,
                })(<Input type="text" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <FormItem
                label={<FormattedMessage id="KZMMonumentSection.creationTime" defaultMessage="Czas powstania" />}
              >
                {getFieldDecorator('creation_time', {
                  initialValue: creationTime || null,
                })(<Input type="text" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <FormItem label={<FormattedMessage id="KZMMonumentSection.creator" defaultMessage="Autor/wytwórca" />}>
                {getFieldDecorator('creator', {
                  initialValue: creator || null,
                })(<Input type="text" />)}
              </FormItem>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default (injectIntl(KZMMonumentSection): any);
