// @flow
import gql from 'graphql-tag';

export default (gql`
  query GetArtifactsMyWorkListColumns {
    artifactsMyWorkListColumns {
      selectedColumns @client
      orderedColumns @client
      columnSortOrder @client
      __typename
    }
  }
`: any);
