// @flow
// React
import * as React from 'react';
import { Link } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { Table, Button } from 'antd';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import styled from 'styled-components';
// Queries
import ALL_DICTIONARIES_QUERY from '../../../lib/queries/allDictionaries';
// Own Types
import type { DictionaryEntryType } from '../../../lib/types';
// Componnent
import BreadCrumb from '../../../components/BreadCrumb';
// style
import { pageContainerStyle } from '../../../lib/style/pageStyle';
import NoDataBecauseError from '../../../components/NoDataBecauseError';

type PropsType = {
  AllDictionariesQUERY: {
    allDictionaries: { data: DictionaryEntryType[] },
    error: string,
    loading: boolean,
  },
  className: string,
  intl: typeof IntlShape,
};

type SortedColumnType = {
  value_de: string,
  value_en: string,
  value_pl: string,
};

const DictionariesList = (props: PropsType): React.Node => {
  const { className, AllDictionariesQUERY, intl } = props;
  const { allDictionaries, loading, error } = AllDictionariesQUERY;

  const columns = [
    {
      title: <FormattedMessage id="DictionariesList.name" defaultMessage="Nazwa Słownika" />,
      dataIndex: `value_${intl.locale}`,
      render: (rowText: string, record: DictionaryEntryType): React.Node => (
        <div className="dictionaries-row" key={record.id}>
          <span className="dictionaries-text">{rowText}</span>
          <FormattedMessage id="general.edit" defaultMessage="Edytuj">
            {(buttonText: string): React.Node => (
              <Link to={`/admin/dictionary/${record.id}`}>
                <Button>{buttonText}</Button>
              </Link>
            )}
          </FormattedMessage>
        </div>
      ),
      sorter: (currentValue: SortedColumnType, nextValue: SortedColumnType): number => {
        return currentValue[`value_${intl.locale}`].localeCompare(nextValue[`value_${intl.locale}`]);
      },
    },
  ];

  if (error) return <NoDataBecauseError />;

  return (
    <React.Fragment>
      <BreadCrumb breadcrumbs={['admin', 'dictionaries']} />
      <StyledPageContainer className={className}>
        <StyledPageContent>
          <Table
            pagination={false}
            columns={columns}
            dataSource={allDictionaries}
            bordered
            loading={loading}
            role="table"
            rowKey={(record: DictionaryEntryType): React.Node => record.id}
          />
        </StyledPageContent>
      </StyledPageContainer>
    </React.Fragment>
  );
};

const StyledPageContainer = styled.div`
  ${pageContainerStyle};
`;

const StyledPageContent = styled.div`
  padding: 24px 32px;
  background-color: #fff;
  flex: 1;
`;

const StyledDictionariesList = styled(DictionariesList)`
  .dictionaries-row {
    display: flex;
  }

  .dictionaries-text {
    flex: 1;
  }
`;

export default (injectIntl(
  graphql(ALL_DICTIONARIES_QUERY, { name: 'AllDictionariesQUERY' })(
    StyledDictionariesList,
  ),
): any);
