import gql from 'graphql-tag';

export default gql`
  query HistoricalArtifact($id: ID, $isAdmin: Boolean = false, $shortCatalogId: String) {
    historicalArtifact(id: $id, short_catalog_id: $shortCatalogId) {
      id
      status @include(if: $isAdmin)
      created_at @include(if: $isAdmin)
      updated_at @include(if: $isAdmin)
      published_at @include(if: $isAdmin)
      created_by_email @include(if: $isAdmin)
      comment @include(if: $isAdmin)
      catalog_id
      short_catalog_id
      title
      artifact_type {
        id
        value_pl
        value_en
        value_de
      }
      natural_resource {
        id
        value_pl
        value_en
        value_de
      }
      description
      place_of_origin
      dimensions
      implementation_technique
      preserved
      preservation_state
      value
      literature
      creation_time
      creator
      acquisition_method
      acquisition_date
      inventory_entry_at
      type
      rentals {
        id
        rental_date
        renter
        return_date
      }
      created_by_id
      voivodeship {
        id
        value_pl
        value_en
        value_de
      }
      county {
        id
        value_pl
        value_en
        value_de
      }
      community {
        id
        value_pl
        value_en
        value_de
      }
      city {
        id
        value_pl
        value_en
        value_de
      }
      notes {
        id
        created_by_email
        content
        created_at
      }
      main_photo {
        id
        path
        description
      }
      photos {
        id
        path
        description
      }
      shelving_number
      shelf_number
    }
  }
`;
