// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';

type PropsType = {
  children: React.Element<typeof React.Component>,
};

const RenderButtonInBredcrumb = (props: PropsType): React.Node => {
  const { children } = props;
  const breadcrumbButton = document.getElementById('breadcrumb-button');

  return breadcrumbButton && ReactDOM.createPortal(children, breadcrumbButton);
};

export default RenderButtonInBredcrumb;
