// @flow
const dictionariesId = {
  voivodeshipId: '1',
  countyId: '2',
  communityId: '3',
  cityId: '4',
  azpId: '5',
  archaeologicalCultureId: '6',
  chronologyId: '7',
  naturalResourceId: '8',
  artifactTypeId: '9',
};

export default dictionariesId;
