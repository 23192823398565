// @flow
import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import queryString from 'query-string';
import ArtifactsHistoricalFilters from './ArtifactsHistoricalFilters';
import ArtifactsArchaeologicalFilters from './ArtifactsArchaeologicalFilters';
import ArtifactsAdminFilters from './ArtifactsAdminFilters';
import { splitDatesInFilterObject } from './ArtifactsFiltersUtils';
import withRouter from '../../../../lib/utils/withRouter';

type PropsType = {
  form: Object,
  location: Location,
  navigate: any,
  toggleFullWidthTable: () => void,
  type: string,
};

type StateType = {
  filter: Object,
};

class ArtifactsFiltersContainer extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super();

    const {
      location: { search },
    } = props;
    const filter = queryString.parse(search);

    this.state = { filter };
  }

  filterArtifacts = (_filter: Object) => {
    const { navigate } = this.props;
    const filter = splitDatesInFilterObject(_filter);

    this.setState({ filter });
    const search = queryString.stringify(filter, { encode: false });
    navigate({ search });
  };

  render(): React$Node {
    const { filter } = this.state;
    const { toggleFullWidthTable, type } = this.props;

    const adminTypes = ['my-work', 'moderation', 'administration'];

    return (
      <React.Fragment>
        {type === 'archaeological' && (
          <ArtifactsArchaeologicalFilters
            {...this.props}
            toggleFullWidthTable={toggleFullWidthTable}
            filterArtifacts={this.filterArtifacts}
            filter={filter}
          />
        )}
        {type === 'historical' && (
          <ArtifactsHistoricalFilters
            {...this.props}
            toggleFullWidthTable={toggleFullWidthTable}
            filterArtifacts={this.filterArtifacts}
            filter={filter}
          />
        )}
        {adminTypes.includes(type) && (
          <ArtifactsAdminFilters
            {...this.props}
            toggleFullWidthTable={toggleFullWidthTable}
            filterArtifacts={this.filterArtifacts}
            filter={filter}
          />
        )}
      </React.Fragment>
    );
  }
}

export default (Form.create()(withRouter(ArtifactsFiltersContainer)): any);
