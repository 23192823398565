// @flow
import gql from 'graphql-tag';

export default (gql`
  query GetArtifactsArchaeologicalListColumns {
    artifactsArchaeologicalListColumns {
      selectedColumns @client
      orderedColumns @client
      columnSortOrder @client
      __typename
    }
  }
`: any);
