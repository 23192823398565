// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Card, Col, Row } from 'antd';
import type { NotesType, RentalsShowType } from '../../../lib/types';
import { accessibility } from '../../../lib/constants/themeModes';
import { darkBlueColor, smallerFontSize, biggerFontSize } from '../../../lib/style/themes';
import withUser from '../../../components/withUser';
import ArtifactsHistoricalDetails from './ArtifactsHistoricalDetails';
import ArtifactsMassDetails from './ArtifactsMassDetails';
import ArtifactsSeparatedDetails from './ArtifactsSeparatedDetails';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  params: { artifactId: number, shortCatalogId: number, type: string },
};

const prepareNotes = (notes: NotesType[] | []): React.Node => {
  if (!notes || notes.length < 1) return <FormattedMessage id="general.emptyValue" defaultMessage="brak" />;

  return notes.map(
    (note: NotesType): React.Node => {
      const {
        created_at: createdAt, content, id, created_by_email: author,
      } = note;

      const title = (
        <StyledTitle>
          <span>{author}</span>
          <span>{createdAt}</span>
        </StyledTitle>
      );

      return (
        <StyledCard title={title} key={id}>
          {content}
        </StyledCard>
      );
    },
  );
};

const prepareRentals = (rentals: RentalsShowType[] | []): React.Node => {
  if (!rentals || rentals.length < 1) return <FormattedMessage id="general.emptyValue" defaultMessage="brak" />;

  return rentals.map(
    (rental: RentalsShowType): React.Node => {
      const {
        renter, rental_date: rentalDate, return_date: returnDate, id,
      } = rental;

      return (
        <StyledSectionElement key={id}>
          <Col xs={24} sm={10} md={10} lg={10}>
            <StyledDetailsName>
              <FormattedMessage id="ArtifactsDetailsContainer.person" defaultMessage="Osoba/Instytucja" />
            </StyledDetailsName>
            : {renter}
          </Col>
          <Col xs={12} sm={7} md={7} lg={7}>
            <StyledDetailsName>
              <FormattedMessage
                id="ArtifactsDetailsContainer.sectionRentalRetalDate"
                defaultMessage="Data wypożyczenia"
              />
            </StyledDetailsName>
            : {rentalDate}
          </Col>
          <Col xs={12} sm={7} md={7} lg={7}>
            <StyledDetailsName>
              <FormattedMessage id="ArtifactsDetailsContainer.sectionRentalReturnDate" defaultMessage="Data zwrotu" />
              :{' '}
            </StyledDetailsName>
            {returnDate}
          </Col>
        </StyledSectionElement>
      );
    },
  );
};

const setChecbkoxValue = (isChecked: boolean): React.Node => {
  return isChecked ? (
    <FormattedMessage id="general.yes" defaultMessage="Tak" />
  ) : (
    <FormattedMessage id="general.no" defaultMessage="Nie" />
  );
};

const print = () => {
  setTimeout(window.print, 500);
};

class ArtifactsDetailsContainer extends React.PureComponent<PropsType, void> {
  render(): React.Node {
    const {
      params: { artifactId: artifactIdUrl, type, shortCatalogId },
    } = this.props;

    return (
      <React.Fragment>
        {(type === 'mass' || type === '01') && (
          <ArtifactsMassDetails
            artifactIdUrl={artifactIdUrl}
            shortCatalogId={shortCatalogId}
            prepareNotes={prepareNotes}
            prepareRentals={prepareRentals}
            handlePrint={print}
          />
        )}
        {(type === 'separated' || type === '02') && (
          <ArtifactsSeparatedDetails
            artifactIdUrl={artifactIdUrl}
            shortCatalogId={shortCatalogId}
            setChecbkoxValue={setChecbkoxValue}
            prepareNotes={prepareNotes}
            prepareRentals={prepareRentals}
            handlePrint={print}
          />
        )}
        {(type === 'historical' || type === '03') && (
          <ArtifactsHistoricalDetails
            artifactIdUrl={artifactIdUrl}
            shortCatalogId={shortCatalogId}
            setChecbkoxValue={setChecbkoxValue}
            prepareRentals={prepareRentals}
            prepareNotes={prepareNotes}
            handlePrint={print}
          />
        )}
      </React.Fragment>
    );
  }
}

const StyledSectionElement = styled(Row)`
  color: rgba(0, 0, 0, 0.65);
  font-size: ${smallerFontSize};
  width: 100%;
  font-weight: 400;

  @media print {
    font-size: 12px;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: ${darkBlueColor} !important;

      .ant-card-head-title {
        color: yellow !important;
        font-size: 20px;
      }

      &:focus {
        border: 3px solid red !important;
      }
    `};

  @media print {
    padding: 0 !important;
    page-break-inside: avoid;

    .ant-card-bordered {
      border: 0;
    }

    .ant-card-head,
    .ant-card-wider-padding,
    .ant-card-body {
      padding: 0 !important;
    }

    .ant-card-head {
      min-height: 0;
      margin-bottom: 10px;
      page-break-after: avoid;
      font-size: ${smallerFontSize} !important;
    }

    .ant-card-body {
      font-size: 12px !important;
    }

    .ant-card-head-title {
      padding: 0;
    }

    .ant-card-body > .ant-card > .ant-card-head {
      border: 0;
    }
  }
`;

const StyledTitle = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-size: ${smallerFontSize};
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
`;

const StyledDetailsName = styled.span`
  font-weight: 600;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${biggerFontSize};
    `};
`;

export default (withRouter(withUser(ArtifactsDetailsContainer)): any);
