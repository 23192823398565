import theme from 'styled-theming';

export const navigationHeight = theme('mode', {
  accessibility: '0px',
  standard: '74px',
});

export const darkBlueColor = '#001529';
export const generalBlueColor = '#188ee0';

export const lightGreyColor = 'rgba(255, 255, 255, 0.65)';
export const darkGreyColor = 'rgba(0, 0, 0, 0.85)';

export const generalFontSize = '16px';
export const biggerFontSize = '18px';
export const smallerFontSize = '14px';
