// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import CURRENT_USER_QUERY from '../../lib/queries/CurrentUser';
import type { UserType, QueryRenderProps } from '../../lib/types';

type PropsType = {
  children: React.Element<any>,
  subMenu?: boolean,
};

const ContentForLoggedInUsers = (props: PropsType): React.Node => {
  return (
    <Query query={CURRENT_USER_QUERY} fetchPolicy="cache-first">
      {({ loading, error, data }: QueryRenderProps<{ current_user: UserType }>): React.Node => {
        if (loading || error) return null;

        if (data && data.current_user && data.current_user.email) {
          const { children, subMenu, ...restProps } = props;

          // antd menu passes props down to it's children, SubMenu  component crashes without adding props manually
          if (subMenu) {
            return React.cloneElement(children, restProps);
          }

          return children;
        }

        return null;
      }}
    </Query>
  );
};

ContentForLoggedInUsers.defaultProps = {
  subMenu: false,
};

export default ContentForLoggedInUsers;
