// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Card } from 'antd';
import Breakpoints from '../../lib/constants/breakpoints';

const LowResolutionBaner = (): React.Node => {
  return (
    <StyledMobileCard>
      <FormattedMessage
        id="LowResolutionBaner.Message"
        defaultMessage="Widok jest dostępny dla urządzeń o większej rozdzielczości ekranu.
        Po zmianie orientacji urządzenia ten widok będzie dostępny."
      />
    </StyledMobileCard>
  );
};

const StyledMobileCard = styled(Card)`
margin-bottom: 20px;
display: none;

@media (max-width: ${Breakpoints.mobile}) {
  display: block;
}
`;

export default LowResolutionBaner;
