// @flow
import * as React from 'react';
import {
  Card, Table, Button, Drawer,
} from 'antd';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { pageContainerStyle } from '../../../../lib/style/pageStyle';
import { accessibility } from '../../../../lib/constants/themeModes';
import { darkBlueColor } from '../../../../lib/style/themes';
import ArtifactsChangeLogDisplayer from '../ArtifactsChangeLogDisplayer';

import type { ArtifactChangesHeaderType } from '../../../../lib/types';

type PropsType = {
  artifactChangesHeaders: ArtifactChangesHeaderType[],
  loading: boolean,
};

type StateType = {
  currentChangeId: ?string,
  showDrawer: boolean,
};

class ArtifactsChangeLogTable extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    showDrawer: false,
    currentChangeId: null,
  };

  showDrawer: ((id: string) => void) = (id: string) => {
    this.setState({
      currentChangeId: id,
      showDrawer: true,
    });
  };

  onClose: (() => void) = () => {
    this.setState({
      currentChangeId: null,
      showDrawer: false,
    });
  };

  prepareColumns: (() => Array<any>) = (): Array<Object> => [
    {
      title: <FormattedMessage id="ArtifactsChangeLogTable.title" defaultMessage="Data zmiany" />,
      dataIndex: 'created_at',
      width: 200,
    },
    {
      title: <FormattedMessage id="ArtifactsChangeLogTable.author" defaultMessage="Autor" />,
      dataIndex: 'changed_by_email',
    },
    {
      dataIndex: 'id',
      align: 'center',
      width: 50,
      render: (id: string): React.Node => (
        <Button type="primary" onClick={(): void => this.showDrawer(id)}>
          <FormattedMessage id="ArtifactsChangeLogTable.showChange" defaultMessage="Pokaż zmiany" />
        </Button>
      ),
    },
  ];

  render(): React.Node {
    const { artifactChangesHeaders, loading } = this.props;
    const { showDrawer, currentChangeId } = this.state;

    const columns = this.prepareColumns();

    return (
      <StyledPageContainer>
        <Card>
          <Table
            bordered
            dataSource={artifactChangesHeaders}
            columns={columns}
            rowKey={(record: ArtifactChangesHeaderType): React.Node => record.id}
            loading={loading}
          />
        </Card>
        <Drawer
          visible={showDrawer}
          zIndex={1002}
          onClose={this.onClose}
          width={800}
          style={{ maxWidth: '100%' }}
          title={<FormattedMessage id="ArtifactsChangeLogDrawer.title" defaultMessage="Log zmiany" />}
        >
          {currentChangeId && <ArtifactsChangeLogDisplayer id={currentChangeId} />}
        </Drawer>
      </StyledPageContainer>
    );
  }
}

const StyledPageContainer = styled.div`
  ${pageContainerStyle};
  transition: max-width 0.3s ease;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-card-body {
        background-color: ${darkBlueColor};
      }
    `};
`;

export default ArtifactsChangeLogTable;
