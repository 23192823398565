// @flow
import messagesDE from '../lib/translate/de.json';
import messagesEN from '../lib/translate/en.json';

const messages = {
  de: messagesDE,
  en: messagesEN,
};

export default messages;
