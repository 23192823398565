export default {
  mass: '01',
  separated: '02',
  historical: '03',
};

const ARTIFACT_STRING_TYPE = {
  mass: 'mass',
  separated: 'separated',
  historical: 'historical',
};

export { ARTIFACT_STRING_TYPE };
