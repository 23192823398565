// @flow
import * as React from 'react';
import { Select, Spin } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import debounce from 'lodash/debounce';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withApollo } from 'react-apollo';
import styled, { css } from 'styled-components';

import formItemLayout from '../../lib/constants/formItemLayout';
import ALL_DICTIONARY_ENTRIES_QUERY from '../../lib/queries/allDictionaryEntries';
import type { DictionaryEntryType, ValidationRulesType } from '../../lib/types';
import TranslatedValue from '../TranslatedValue/TranslatedValue';

import { accessibility } from '../../lib/constants/themeModes';
import uniqArray from '../../lib/utils/uniqArray/uniqArray';

const { Option } = Select;

type initialFieldValueType = DictionaryEntryType | Array<string> | string;

type PropsType = {|
  className: string,
  client: any,
  dictionaryId: string,
  getFieldDecorator: Function,
  initialFieldValue: initialFieldValueType,
  intl: typeof intlShape,
  label: string,
  name: string,
  relatedFieldName: string,
  relatedFielsdName: string[],
  setFieldsValue: Function,
  validateFields: Function,
  validationRules: ValidationRulesType,
|};

type StateType = {
  data: DictionaryEntryType[],
  fetchingData: boolean,
};

type HandleSearchType = (_typedValue: string) => Promise<void>;
type HandleOnChangeType = (idValue: string) => void;
type NormalizeValueForSiteEditionType = (value: string, prevValue: string) => null | string;

interface DictionarySelectInterface {
  handleOnChange: HandleOnChangeType,
  handleSearchMethod: HandleSearchType,
  normalizeValueForSiteEditionMethod: NormalizeValueForSiteEditionType,
}

const azpValuePattern = /^\d{2}-\d{1,2}$/;

export class DictionarySelect extends React.PureComponent<PropsType, StateType> implements DictionarySelectInterface {
  static getIdFromInitialValue(
    initialFieldValue: initialFieldValueType,
    data: DictionaryEntryType[],
  ): ?string | ?(string[]) {
    if (!initialFieldValue) return undefined;
    if (initialFieldValue.id) return String(initialFieldValue.id);

    const initialValueIsArray = Array.isArray(initialFieldValue);
    const dataIsArray = data && data.length > 0;
    const initialValueIsNotId = ((): boolean => {
      if (Array.isArray(initialFieldValue)) {
        return azpValuePattern.test(initialFieldValue[0]) || Number.isNaN(initialFieldValue[0]);
      }

      return azpValuePattern.test(String(initialFieldValue)) || Number.isNaN(initialFieldValue);
    })();

    // we have single azp number in URL
    if (initialValueIsNotId && !initialValueIsArray && dataIsArray) {
      const foundValue = data.find((v: DictionaryEntryType): boolean => v.value_pl === initialFieldValue);

      return foundValue && foundValue.id;
    }

    // we have multiple azp numbers in URL
    if (initialValueIsNotId && initialValueIsArray && dataIsArray) {
      return data
        .filter(
          (v: DictionaryEntryType): boolean => {
            return Array.isArray(initialFieldValue) && initialFieldValue.includes(v.value_pl || '');
          },
        )
        .map((v: DictionaryEntryType): string => String(v.id));
    }

    if (typeof initialFieldValue === 'string') return initialFieldValue;

    // we have multiple ids in URL
    if (Array.isArray(initialFieldValue) && dataIsArray) {
      const foundedValues = data
        .filter(
          (v: DictionaryEntryType): boolean => {
            return Array.isArray(initialFieldValue) && initialFieldValue.includes(v.id);
          },
        )
        .map((v: DictionaryEntryType): string => String(v.id));

      const mergedValues = [...initialFieldValue, ...foundedValues];

      return uniqArray(mergedValues);
    }

    // $FlowFixMe[incompatible-return]
    return initialFieldValue;
  }

  constructor() {
    super();
    this.handleSearchMethod = debounce(this.handleSearchMethod, 650);
  }

  state: StateType = {
    data: [],
    fetchingData: false,
  };

  async componentDidMount(): Promise<void> {
    const { initialFieldValue, client } = this.props;

    // Do nothing when don't have initial value
    if (!initialFieldValue) return;
    let properInitialValues: any = initialFieldValue;

    // First let makes array from single int
    if (!Array.isArray(properInitialValues)) {
      // check if disctionarySelect id value (returned from antDesigneForm object)
      if (properInitialValues.id && !properInitialValues.value_pl) {
        properInitialValues = [properInitialValues.id];
      } else properInitialValues = [properInitialValues];
    }

    // Then add values if we only have array of ids
    if (properInitialValues && !properInitialValues[0].value_pl) {
      this.setState({ fetchingData: true });

      // If we are redirect from the platform, then we have only value
      // in format '12-34' or 'lubuskie' so we need to find id instead of value
      let filter;

      if (Number.isNaN(properInitialValues[0]) || azpValuePattern.test(String(properInitialValues[0]))) {
        filter = { valuePl: properInitialValues };
      } else {
        filter = { id: properInitialValues };
      }

      const entries = await client.query({
        query: ALL_DICTIONARY_ENTRIES_QUERY,
        variables: { filter },
        fetchPolicy: 'cache-first',
      });

      this.setState({
        data: entries.data.allDictionaryEntries.data,
        fetchingData: false,
      });

      return;
    }

    this.setState({
      data: properInitialValues,
    });
  }

  setFilters: ((value: string) => any) = (value: string): Object => {
    const {
      intl: { locale },
      dictionaryId,
    } = this.props;

    if (value === '' || !locale) return {};

    // We have locale = 'pl', and need output like 'valuePl_contains'
    const filterString = `value${locale[0].toUpperCase()}${locale[1]}_contains`;

    return { filter: { dictionaryId, [filterString]: value } };
  };

  handleSearchMethod: HandleSearchType = async (_typedValue = '') => {
    if (_typedValue.length < 3) return;

    const { client } = this.props;

    this.setState({ fetchingData: true });

    const entries = await client.query({
      query: ALL_DICTIONARY_ENTRIES_QUERY,
      variables: this.setFilters(_typedValue),
      fetchPolicy: 'cache-first',
    });

    this.setState({
      data: entries.data.allDictionaryEntries.data,
      fetchingData: false,
    });
  };

  handleOnChange: HandleOnChangeType = (_idValue) => {
    const {
      name, relatedFieldName, validateFields, setFieldsValue, relatedFielsdName,
    } = this.props;

    // delete options on clear value in select
    if (!_idValue) {
      this.setState({
        data: [],
      });

      if (relatedFieldName) {
        setFieldsValue({
          [relatedFieldName]: null,
        });
      }
    }

    setFieldsValue({
      [name]: _idValue,
    });

    if (relatedFielsdName) {
      for (let i = 0; i < relatedFielsdName.length; i += 1) {
        validateFields([relatedFielsdName[i]], { force: true }); // force: true - validate related field again
      }
    }

    if (relatedFieldName && validateFields) {
      validateFields([relatedFieldName], { force: true }); // force: true - validate related field again
    }
  };

  normalizeValueForSiteEditionMethod: NormalizeValueForSiteEditionType = (_value, _prevValue) => {
    const { initialFieldValue } = this.props;

    // Set null value for site edition
    if (initialFieldValue && _prevValue === null) {
      return null;
    }

    return _value;
  };

  prepareOptionsFieldsMethod(data: DictionaryEntryType[]): React.Node {
    const {
      intl: { locale },
    } = this.props;
    if (!data && !Array.isArray(data) && !data[0].value_pl) return null;

    const { className } = this.props;

    return data.map(
      (option: Object): React.Node => (
        <Option key={option.id} title={option[`value_${locale}`]} className={className}>
          <TranslatedValue value={option} />
        </Option>
      ),
    );
  }

  render(): React.Node {
    const { fetchingData, data } = this.state;
    const {
      label, getFieldDecorator, name, validationRules, initialFieldValue,
    } = this.props;

    const options = this.prepareOptionsFieldsMethod(data);
    const FormItem = Form.Item;
    const initialFieldValueId = DictionarySelect.getIdFromInitialValue(initialFieldValue, data);

    // Hack to handle situation when user have azp,valuePl in URL and then want to
    // and more azp, without click "Filter the results" first. Feel free to fix it.
    const azpDisabled = name === 'azpId' && window.location.search.search(',valuePl') > 0;

    return (
      <FormItem label={label} {...formItemLayout} tabIndex="0">
        {getFieldDecorator(name, {
          initialValue: initialFieldValueId,
          rules: validationRules,
          normalize: this.normalizeValueForSiteEditionMethod,
        })(
          <Select
            disabled={azpDisabled}
            showSearch
            showArrow={false}
            defaultActiveFirstOption={false}
            filterOption={false}
            allowClear
            optionLabelProp="title"
            onSearch={this.handleSearchMethod}
            onChange={this.handleOnChange}
            notFoundContent={
              fetchingData ? (
                <Spin size="small" />
              ) : (
                <FormattedMessage id="DictionarySelect.notFoundContent" defaultMessage="Wpisz pierwsze trzy litery" />
              )
            }
            {...this.props}
          >
            {options}
          </Select>,
        )}
      </FormItem>
    );
  }
}

const StyledDictionarySelect = styled(DictionarySelect)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      border: 2px solid black;
      font-size: 18px;
    `};
`;

export default (injectIntl(withApollo(StyledDictionarySelect)): any);
