// @flow
import * as React from 'react';
import {
  Row, Col, Button, Input, Tabs,
} from 'antd';
import { Form, Icon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';
import styled, { css, withTheme } from 'styled-components';
import type { Theme } from 'styled-components';
import dictionariesId from '../../../../lib/constants/dictionariesId';
import artifactDataTranslationsMessages from '../../../../lib/constants/artifactDataTranslationsMessages';
import DictionarySelect from '../../../../components/DictionarySelect';
import DateRangePickerField from '../../../../components/DateRangePickerField';
import { accessibility } from '../../../../lib/constants/themeModes';
import FallbackInA11y from '../../../../components/FallbackInA11y';
import withUser from '../../../../components/withUser';
import { makeFilterNameWithBadge } from './ArtifactsFiltersUtils';
import formItemLayout from '../../../../lib/constants/formItemLayout';
import type { ArtifactFilterType, UserType, ArtifactFilterUrlType } from '../../../../lib/types';
import { darkBlueColor, biggerFontSize } from '../../../../lib/style/themes';

const FormItem = Form.Item;
const { TabPane } = Tabs;

type PropsType = {
  filter: ArtifactFilterUrlType,
  filterArtifacts: (ArtifactFilterType | {}) => void,
  form: Object,
  theme: Theme,
  toggleFullWidthTable: () => void,
  user: UserType,
};

const ArtifactsArchaeologicalFilters = (props: PropsType): React.Node => {
  const {
    form: {
      getFieldDecorator, validateFields, setFieldsValue, getFieldValue, resetFields,
    },
    toggleFullWidthTable,
    filter,
    filterArtifacts,
    user,
  } = props;

  const handleSubmit = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();

    validateFields((errors: any, values: ArtifactFilterType) => {
      // remove empty filter from filters
      Object.keys(values).forEach((key: string) => {
        if (!values[key]) {
          // eslint-disable-next-line
          delete values[key];
        }
      });

      if (!errors) {
        filterArtifacts(values);
      }
    });
  };

  const handleReset = () => {
    filterArtifacts({});
    resetFields();
  };

  const originPlaceFilter = <FormattedMessage id="ArtifactsFilter.originPlace" defaultMessage="Miejsce pochodzenia" />;
  const originPlaceFilterName = makeFilterNameWithBadge(originPlaceFilter, filter, [
    'cityId',
    'voivodeshipId',
    'countyId',
    'communityId',
    'azp,valuePl',
    'azpId',
    'ordinalNumberInAzp',
    'ordinalNumberInCity',
    'shortCatalogId',
  ]);

  const originFilter = <FormattedMessage id="ArtifactsFilter.origin" defaultMessage="Pochodzenie" />;
  const originFilterName = makeFilterNameWithBadge(originFilter, filter, ['archaeologicalCultureId', 'chronologyId']);

  const artifactFilter = <FormattedMessage id="ArtifactsFilter.artifact" defaultMessage="Zabytek" />;
  const artifactFilterName = makeFilterNameWithBadge(artifactFilter, filter, ['artifactTypeId', 'naturalResourceId']);

  const moderationFilter = <FormattedMessage id="ArtifactsFilter.moderation" defaultMessage="Moderacja" />;
  const moderationFilterName = makeFilterNameWithBadge(moderationFilter, filter, [
    'status',
    'createdByEmail',
    'createdAt_date_gte',
    'updatedAt_date_gte',
    'publishedAt_date_gte',
  ]);

  const warehouseFilter = <FormattedMessage id="ArtifactsFilter.warehouse" defaultMessage="Lokalizacja w magazynie" />;
  const warehouseFilterName = makeFilterNameWithBadge(warehouseFilter, filter, [
    'shelvingNumber',
    'shelfNumber',
  ]);

  return (
    <StyledForm className="ant-advanced-search-form" onSubmit={handleSubmit}>
      <FallbackInA11y component={Tabs} type="card" tabBarGutter={10}>
        <FallbackInA11y component={TabPane} tab={originPlaceFilterName} key="1" forceRender>
          <Row gutter={24}>
            <Col xs={8} sm={8} md={6} ld={6}>
              <FormItem
                label={<FormattedMessage {...artifactDataTranslationsMessages.shortCatalog} />}
                {...formItemLayout}
              >
                {getFieldDecorator('shortCatalogId', {
                  initialValue: filter.shortCatalogId,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col xs={8} sm={8} md={6} ld={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.voivodeshipId}
                label={<FormattedMessage {...artifactDataTranslationsMessages.voivodeship} />}
                name="voivodeshipId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.voivodeshipId}
                mode="multiple"
              />
            </Col>
            <Col xs={8} sm={8} md={6} ld={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.countyId}
                label={<FormattedMessage {...artifactDataTranslationsMessages.county} />}
                name="countyId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.countyId}
                mode="multiple"
              />
            </Col>
            <Col xs={8} sm={8} md={6} ld={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.communityId}
                label={<FormattedMessage {...artifactDataTranslationsMessages.community} />}
                name="communityId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.communityId}
                mode="multiple"
              />
            </Col>
            <Col xs={8} sm={8} md={6} ld={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.cityId}
                label={<FormattedMessage {...artifactDataTranslationsMessages.city} />}
                name="cityId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.cityId}
                mode="multiple"
              />
            </Col>
            <Col xs={8} sm={8} md={6} ld={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.azpId}
                label={<FormattedMessage {...artifactDataTranslationsMessages.azp} />}
                name="azpId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.azpId || filter['azp,valuePl']}
                mode="multiple"
              />
            </Col>
            <Col xs={8} sm={8} md={6} ld={6}>
              <FormItem
                label={<FormattedMessage {...artifactDataTranslationsMessages.ordinal_number_in_azp} />}
                {...formItemLayout}
              >
                {getFieldDecorator('ordinalNumberInAzp', {
                  initialValue: filter.ordinalNumberInAzp,
                })(<Input name="ordinalNumberInAzp" />)}
              </FormItem>
            </Col>
            <Col xs={8} sm={8} md={6} ld={6}>
              <FormItem
                label={<FormattedMessage {...artifactDataTranslationsMessages.ordinal_number_in_city} />}
                {...formItemLayout}
              >
                {getFieldDecorator('ordinalNumberInCity', {
                  initialValue: filter.ordinalNumberInCity,
                })(<Input name="ordinalNumberInCity" />)}
              </FormItem>
            </Col>
          </Row>
        </FallbackInA11y>
        <FallbackInA11y component={TabPane} tab={originFilterName} key="2" forceRender>
          <Row gutter={24}>
            <Col span={6}>
              <DictionarySelect
                label={<FormattedMessage {...artifactDataTranslationsMessages.archaeological_culture} />}
                dictionaryId={dictionariesId.archaeologicalCultureId}
                name="archaeologicalCultureId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.archaeologicalCultureId}
                mode="multiple"
              />
            </Col>
            <Col span={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.chronologyId}
                label={<FormattedMessage {...artifactDataTranslationsMessages.chronology} />}
                name="chronologyId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.chronologyId}
                mode="multiple"
              />
            </Col>
          </Row>
        </FallbackInA11y>
        <FallbackInA11y component={TabPane} tab={artifactFilterName} key="3" forceRender>
          <Row gutter={24}>
            <Col span={6}>
              <DictionarySelect
                label={<FormattedMessage {...artifactDataTranslationsMessages.artifact_type} />}
                dictionaryId={dictionariesId.artifactTypeId}
                name="artifactTypeId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.artifactTypeId}
                mode="multiple"
              />
            </Col>
            <Col span={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.naturalResourceId}
                label={<FormattedMessage {...artifactDataTranslationsMessages.natural_resource} />}
                name="naturalResourceId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.naturalResourceId}
                mode="multiple"
              />
            </Col>
          </Row>
        </FallbackInA11y>
        {user.email && (
          <FallbackInA11y component={TabPane} tab={warehouseFilterName} key="5" forceRender>
            <Row gutter={24}>
              <Col span={6}>
                <FormItem
                  label={<FormattedMessage {...artifactDataTranslationsMessages.shelving_number} />}
                  {...formItemLayout}
                >
                  {getFieldDecorator('shelvingNumber', {
                    initialValue: filter.shelvingNumber,
                  })(<Input type="number" name="shelvingNumber" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label={<FormattedMessage {...artifactDataTranslationsMessages.shelf_number} />}
                  {...formItemLayout}
                >
                  {getFieldDecorator('shelfNumber', {
                    initialValue: filter.shelfNumber,
                  })(<Input type="number" name="shelfNumber" />)}
                </FormItem>
              </Col>
            </Row>
          </FallbackInA11y>
        )}
        {user.email && (
          <FallbackInA11y component={TabPane} tab={moderationFilterName} key="4" forceRender>
            <Row gutter={24}>
              <Col span={6}>
                <FormItem
                  label={<FormattedMessage {...artifactDataTranslationsMessages.created_by_email} />}
                  {...formItemLayout}
                >
                  {getFieldDecorator('createdByEmail', {
                    initialValue: filter.createdByEmail,
                  })(<Input name="createdByEmail" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <DateRangePickerField
                  label={<FormattedMessage {...artifactDataTranslationsMessages.created_at} />}
                  name="createdAt_date"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  isRequired={false}
                  initialFieldValue={[filter.createdAt_date_gte, filter.createdAt_date_lte]}
                />
              </Col>
              <Col span={6}>
                <DateRangePickerField
                  label={<FormattedMessage {...artifactDataTranslationsMessages.updated_at} />}
                  name="updatedAt_date"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  isRequired={false}
                  initialFieldValue={[filter.updatedAt_date_gte, filter.updatedAt_date_lte]}
                />
              </Col>
              <Col span={6}>
                <DateRangePickerField
                  label={<FormattedMessage {...artifactDataTranslationsMessages.published_at} />}
                  name="publishedAt_date"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  isRequired={false}
                  initialFieldValue={[filter.publishedAt_date_gte, filter.publishedAt_date_lte]}
                />
              </Col>
            </Row>
          </FallbackInA11y>
        )}
      </FallbackInA11y>
      <ButtonsRow type="flex">
        <Col span={6}>
          <Button onClick={(): void => toggleFullWidthTable()}>
            <Icon type="fullscreen" />
            <FormattedMessage id="ArtifactsFilter.fullScreen" defaultMessage="Szerokość tabeli" />
          </Button>
        </Col>
        <Col span={18} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
            <Icon type="filter" /> <FormattedMessage id="ArtifactsFilter.filter" defaultMessage="Filtruj wyniki" />
          </Button>
          <Button style={{ marginLeft: 8 }} onClick={handleReset}>
            <Icon type="delete" /> <FormattedMessage id="ArtifactsFilter.clear" defaultMessage="Wyczyść filtry" />
          </Button>
        </Col>
      </ButtonsRow>
    </StyledForm>
  );
};

const ButtonsRow = styled(Row)`
  margin-top: 16px;
  background: #fafafa;
  padding: 10px;
  border-top: 1px solid #e8e8e8;
`;

const StyledForm = styled(Form)`
  max-width: 1130px;
  margin: 0 auto !important;
  .ant-row {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }
  .ant-legacy-form-item-label {
    text-align: left;
    user-select: none;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: ${darkBlueColor};

      .ant-input,
      .ant-select-selection {
        height: 46px !important;
        font-size: ${biggerFontSize};
        color: black;
        background-color: white !important;
        border: 2px solid yellow;
      }

      .ant-input:focus,
      .ant-select-focused .ant-select-selection {
        border-color: red !important;
        box-shadow: 0 0 0 2px red !important;
      }

      .ant-legacy-form-item-label label {
        color: yellow;
        font-size: ${biggerFontSize};
      }

      .ant-btn,
      .ant-btn-primary {
        color: black;
        background-color: yellow;
        border: 2px solid black;
        font-size: ${biggerFontSize};
      }

      .ant-btn:focus,
      .ant-btn-primary:focus {
        color: white;
        background-color: black;
        border: 2px solid yellow;
      }
    `};
`;

export default (withTheme(
  withUser(ArtifactsArchaeologicalFilters),
): React$ComponentType<$Diff<React$ElementConfig<any>, any>>);
