// @flow
import styled, { css } from 'styled-components';
import { accessibility } from '../../lib/constants/themeModes';
import { darkBlueColor } from '../../lib/style/themes';

const StyledH2: any = styled.h2`
  background-color: #fff;
  line-height: 50px;
  margin-bottom: 20px;
  text-align: center;

  img {
    display: none;
  }

  @media print {
    margin: 0;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    background: ${darkBlueColor};
    color: white;
    margin-bottom: 10px;

    img {
      display: block;
      float: left;
      width: 2cm;
      padding: 10px;
    }

    span {
      display: block;
      text-align: center;
      margin: 0 auto;
      padding: 10px;
    }
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: ${darkBlueColor} !important;
      color: yellow;

      &:focus {
        border: 3px solid red;
      }
    `};
`;

export default StyledH2;
