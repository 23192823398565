import gql from 'graphql-tag';

export default gql`
  query massArtifact($id: ID, $isAdmin: Boolean = false, $shortCatalogId: String) {
    massArtifact(id: $id, short_catalog_id: $shortCatalogId) {
      id
      status @include(if: $isAdmin)
      created_at @include(if: $isAdmin)
      updated_at @include(if: $isAdmin)
      published_at @include(if: $isAdmin)
      created_by_email @include(if: $isAdmin)
      comment @include(if: $isAdmin)
      catalog_id
      short_catalog_id
      title
      inventory_entry_at
      type
      rentals {
        id
        rental_date
        renter
        return_date
      }
      created_by_id
      voivodeship {
        id
        value_pl
        value_en
        value_de
      }
      county {
        id
        value_pl
        value_en
        value_de
      }
      community {
        id
        value_pl
        value_en
        value_de
      }
      city {
        id
        value_pl
        value_en
        value_de
      }
      notes {
        id
        created_by_email
        content
        created_at
      }
      animal_bones
      archaeological_culture {
        id
        value_pl
        value_en
        value_de
      }
      are
      are_quarter
      azp {
        id
        value_pl
        value_en
        value_de
      }
      building_ceramics_fragments
      chronology {
        id
        value_pl
        value_en
        value_de
      }
      clay_dishes_fragments
      created_by_email
      excavation
      field_inventory_number
      flints
      glass_fragments
      glass_slag
      hectare
      human_bones
      iron_slag
      leather_object_fragments
      mechanical_layer
      metal_objects
      natural_layer
      object_layer
      object_number
      object_part
      ordinal_number_in_azp
      ordinal_number_in_city
      other_mass_artifact_elements {
        id
        description
        quantity
      }
      research_date
      research_executive
      section
      shells
      stove_tiles_fragments
      threshing_floors
      shelving_number
      shelf_number
    }
  }
`;
