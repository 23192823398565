// @flow
import * as React from 'react';
import {
  FormattedMessage, injectIntl,
} from 'react-intl';
import {
  Row, Col,
} from 'antd';
import artifactDataTranslationsMessages from '../../../../lib/constants/artifactDataTranslationsMessages';
import { StyledCard, StyledDetailsName, StyledDetail } from '../../../../components/StyledComponents/StyledCard';

type PropTypes = {
  emptyValue: React.Node,
  shelfNumber: number,
  shelvingNumber: number,
};

const WarehouseLocationSection = (props: PropTypes): React.Node => {
  const {
    shelfNumber,
    shelvingNumber,
    emptyValue,
  } = props;

  return (
    <Row gutter={12}>
      <Col lg={24}>
        <StyledCard
          bordered={false}
          title={(
            <FormattedMessage
              id="ArtifactsDetails.sectionWarehouseLocationTitle"
              defaultMessage="Lokalizacja w magazynie"
            />
          )}
          tabIndex="0"
          headStyle={{ padding: '0 32px' }}
          bodyStyle={{ padding: '24px 32px' }}
        >
          <Row gutter={72}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <StyledDetailsName>
                <FormattedMessage {...artifactDataTranslationsMessages.shelving_number} />
              </StyledDetailsName>
              <StyledDetail>
                : {shelvingNumber || emptyValue}
              </StyledDetail>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} className="col-print">
              <StyledDetailsName>
                <FormattedMessage {...artifactDataTranslationsMessages.shelf_number} />
              </StyledDetailsName>
              <StyledDetail>
                : {shelfNumber || emptyValue}
              </StyledDetail>
            </Col>
          </Row>
        </StyledCard>
      </Col>
    </Row>
  );
};

export default (injectIntl(WarehouseLocationSection): any);
