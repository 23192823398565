// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';

type PropsType = {
  children: React.Element<typeof React.Component>,
};

const RenderButtonInTable = (props: PropsType): React.Node => {
  const { children } = props;
  const tableButton = document.getElementById('edit-columns-button');

  return tableButton && ReactDOM.createPortal(children, tableButton);
};

export default RenderButtonInTable;
