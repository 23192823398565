import gql from 'graphql-tag';

export default gql`
  mutation SetArtifactsMyWorkListColumns(
    $selectedColumns: Array, $orderedColumns: Array, $columnSortOrder: String) {
    setArtifactsMyWorkListColumns(
      selectedColumns: $selectedColumns
      orderedColumns: $orderedColumns
      columnSortOrder: $columnSortOrder
      __typename: "artifactsMyWorkListColumns"
    ) @client
  }
`;
