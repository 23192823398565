// @flow
// React
import * as React from 'react';
import { withApollo, Query } from 'react-apollo';
import { message } from 'antd';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
// Queries and mutation
import UPDATE_MASS_ARTIFACT from '../../../lib/mutations/updateMassArtifact';
import GET_MASS_ARTIFACT from '../../../lib/queries/getMassArtifact';
import REMOVE_MASS_ARTIFACT from '../../../lib/mutations/removeMassArtifact';
import CHANGE_ARTIFACT_STATUS from '../../../lib/mutations/changeArtifactStatus';
// Components
import KZMForm from '../../../components/Forms/KZMForm';
import LoadingSpin from '../../../components/LoadingSpin';
import BreadCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';
// Own Types
import type { KZMType, UserType, QueryRenderProps } from '../../../lib/types';

import parseNotes from '../../../lib/utils/parseNotes';
import parseRentals from '../../../lib/utils/parseRentals';
import parseOther from '../../../lib/utils/parseOther';
import parseDictionaryValue from '../../../lib/utils/parseDictionaryValue';
import ARTIFACT_STATUS from '../../../lib/constants/artifactStatus';
import NoDataBecauseError from '../../../components/NoDataBecauseError';
import withRouter from '../../../lib/utils/withRouter';

type ResponseType = {
  massArtifact: {
    concat: Function,
    data: KZMType[],
  },
};

type PropsType = {
  client: any,
  intl: typeof IntlShape,
  location: {
    state: { prevPath?: string },
  },
  navigate: any,
  params: { artifactId: string },
  user: ?UserType,
};

type StateType = {
  updating: boolean,
};

export class KZMEdit extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    updating: false,
  };

  mutationUpdater: ((cache: any, mutationResult: any) => void) = (cache: any, mutationResult: any) => {
    const {
      params: { artifactId },
    } = this.props;

    try {
      const response: ?ResponseType = cache.readQuery({ query: GET_MASS_ARTIFACT, variables: { id: artifactId } });
      const { data } = mutationResult;

      if (response && data) {
        cache.writeQuery({
          query: GET_MASS_ARTIFACT,
          variables: { id: artifactId },
          data: { massArtifact: data.updateMassArtifact },
        });
      }
    } catch (e) {
      // https://github.com/apollographql/apollo-client/issues/1701
    }
  };

  editKZM: (formValues: KZMType, afterSave: string) => Promise<void> = async (
    formValues: KZMType,
    afterSave: string,
  ): Promise<void> => {
    const {
      intl,
      client,
      params: { artifactId },
      navigate,
    } = this.props;

    this.setState({ updating: true });
    const response = await client.mutate({
      mutation: UPDATE_MASS_ARTIFACT,
      variables: {
        id: artifactId,
        ...formValues,
        other_mass_artifact_elements: parseOther(formValues.other_mass_artifact_elements),
        notes: parseNotes(formValues.notes),
        rentals: parseRentals(formValues.rentals),
        shelf_number: parseInt(formValues.shelf_number, 10),
        shelving_number: parseInt(formValues.shelving_number, 10),
        are: parseInt(formValues.are, 10),
        object_number: parseInt(formValues.object_number, 10),
        glass_fragments: parseInt(formValues.glass_fragments, 10),
        clay_dishes_fragments: parseInt(formValues.clay_dishes_fragments, 10),
        stove_tiles_fragments: parseInt(formValues.stove_tiles_fragments, 10),
        building_ceramics_fragments: parseInt(formValues.building_ceramics_fragments, 10),
        threshing_floors: parseInt(formValues.threshing_floors, 10),
        human_bones: parseInt(formValues.human_bones, 10),
        animal_bones: parseInt(formValues.animal_bones, 10),
        shells: parseInt(formValues.shells, 10),
        iron_slag: parseInt(formValues.iron_slag, 10),
        glass_slag: parseInt(formValues.glass_slag, 10),
        metal_objects: parseInt(formValues.metal_objects, 10),
        flints: parseInt(formValues.flints, 10),
        leather_object_fragments: parseInt(formValues.leather_object_fragments, 10),
        research_date: formValues.research_date && formValues.research_date.format('YYYY-MM-DD'),
        voivodeship_id: parseDictionaryValue(formValues.voivodeship_id),
        county_id: parseDictionaryValue(formValues.county_id),
        community_id: parseDictionaryValue(formValues.community_id),
        city_id: parseDictionaryValue(formValues.city_id),
        azp_id: parseDictionaryValue(formValues.azp_id),
        archaeological_culture_id: parseDictionaryValue(formValues.archaeological_culture_id),
        chronology_id: parseInt(parseDictionaryValue(formValues.chronology_id), 10),
      },
      update: this.mutationUpdater,
    });
    this.setState({ updating: false });

    if ('errors' in response) {
      return;
    }

    const translatedSuccessMessage = intl.formatMessage(KZMEdit.translations.submitSuccessMessage);
    if (afterSave === 'list') {
      navigate(-1);
    }
    message.success(translatedSuccessMessage);
  };

  submitMassArtifactToReview: (() => Promise<void>) = async (): Promise<void> => {
    const {
      intl,
      client,
      params: { artifactId },
    } = this.props;

    this.setState({ updating: true });
    const response = await client.mutate({
      mutation: CHANGE_ARTIFACT_STATUS,
      variables: {
        id: artifactId,
        status: ARTIFACT_STATUS.ready,
      },
      update: this.mutationUpdater,
    });
    this.setState({ updating: false });

    if ('errors' in response) {
      return;
    }

    const translatedSuccessMessage = intl.formatMessage(KZMEdit.translations.sendMassArtifactToReviewSuccessMessage);
    message.success(translatedSuccessMessage);
  };

  removeKZM: ((removeId: string) => Promise<void>) = async (removeId: string): Promise<void> => {
    const { client, location, navigate } = this.props;

    await client.mutate({
      mutation: REMOVE_MASS_ARTIFACT,
      variables: {
        id: removeId,
      },
    });

    if (location.state && location.state.prevPath) {
      navigate('/artifacts/list/archaeological');
    } else {
      navigate(-1);
    }
  };

  static translations: any = defineMessages({
    submitSuccessMessage: {
      defaultMessage: 'Zabytek masowy został zaktualizowany',
      id: 'KZMEdit.submitSuccessMessage',
    },
    submitErrorMessage: {
      defaultMessage: 'Błąd walidacji formularza, sprawdź wszystkie pola',
      id: 'general.validation.errorMessage',
    },
    sendMassArtifactToReviewSuccessMessage: {
      defaultMessage: 'Znalezisko masowe zostało wysłane do akceptacji',
      id: 'KZMEdit.sendMassArtifactToReviewSuccessMessage',
    },
  });

  render(): React.Node {
    const {
      intl,
      params: { artifactId },
      user,
    } = this.props;
    const { updating } = this.state;
    const submitErrorMessage = intl.formatMessage(KZMEdit.translations.submitErrorMessage);

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['admin', 'editMassArtifact']} />
        <Query query={GET_MASS_ARTIFACT} variables={{ id: artifactId, isAdmin: user && !!user.email }}>
          {({ loading, error, data }: QueryRenderProps<{ massArtifact: KZMType }>): React.Node => {
            if (loading || updating) return <LoadingSpin tip="Loading..." />;
            if (error) return <NoDataBecauseError />;
            if (!data || !data.massArtifact) return 'Brak danych';

            return (
              <KZMForm
                data={data.massArtifact}
                onSubmit={this.editKZM}
                submitErrorMessage={submitErrorMessage}
                removeKZM={this.removeKZM}
                submitMassArtifactToReview={this.submitMassArtifactToReview}
              />
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

export default (withRouter(withApollo(injectIntl(withUser(KZMEdit)))): any);
