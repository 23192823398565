// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  FormattedMessage, injectIntl,
} from 'react-intl';
import {
  Card, Row, Col, Input,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import withUser from '../../withUser';
import artifactDataTranslationsMessages from '../../../lib/constants/artifactDataTranslationsMessages';

const FormItem = Form.Item;

type PropTypes = {
  getFieldDecorator: Function,
  shelfNumber: number,
  shelvingNumber: number,
};

const WarehouseLocationSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator, shelvingNumber, shelfNumber,
  } = props;

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          <FormattedMessage id="WarehouseLocationSection.title" defaultMessage="Lokalizacja w magazynie" />
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col span={8}>
          <FormItem
            label={<FormattedMessage {...artifactDataTranslationsMessages.shelving_number} />}
          >
            {getFieldDecorator('shelving_number', {
              initialValue: shelvingNumber || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label={<FormattedMessage {...artifactDataTranslationsMessages.shelf_number} />}>
            {getFieldDecorator('shelf_number', {
              initialValue: shelfNumber || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default (withUser(injectIntl(WarehouseLocationSection)): any);
