import gql from 'graphql-tag';

export default gql`
  query GetArtifactChange($filter: ArtifactChangeFilter, $paginator: Paginator) {
    allArtifactChanges(filter: $filter, paginator: $paginator) {
      data {
        id
        changed_by_email
        created_at
        old {
          ...ArtifactRevisionBaseFields
          ...HistoricalArtifactRevisionFields
          ...MassArtifactRevisionFields
          ...SeparatedArtifactRevisionFields
        }
        new {
          ...ArtifactRevisionBaseFields
          ...HistoricalArtifactRevisionFields
          ...MassArtifactRevisionFields
          ...SeparatedArtifactRevisionFields
        }
      }
    }
  }

  fragment ArtifactRevisionBaseFields on ArtifactRevisionInterface {
    catalog_id
    city
    community
    county
    notes
    rentals
    shelf_number
    shelving_number
    short_catalog_id
    status
    title
    type
    voivodeship
  }

  fragment MassArtifactRevisionFields on MassArtifactRevision {
    animal_bones
    are_quarter
    are
    azp
    building_ceramics_fragments
    clay_dishes_fragments
    excavation
    field_inventory_number
    flints
    glass_fragments
    glass_slag
    hectare
    human_bones
    iron_slag
    leather_object_fragments
    mechanical_layer
    metal_objects
    natural_layer
    notes
    object_layer
    object_number
    object_part
    ordinal_number_in_azp
    ordinal_number_in_city
    other_mass_artifact_elements
    research_date
    research_executive
    section
    shells
    stove_tiles_fragments
    threshing_floors
  }

  fragment SeparatedArtifactRevisionFields on SeparatedArtifactRevision {
    archaeological_culture
    are_quarter
    are
    artifact_type
    azp
    chronology
    coordinates
    description
    dimensions
    excavation
    field_inventory_number
    hectare
    implementation_technique
    literature
    mechanical_layer
    natural_layer
    natural_resource
    object_layer
    object_number
    object_part
    ordinal_number_in_azp
    ordinal_number_in_city
    preservation_state
    preserved
    research_date
    research_executive
    section
    value
  }

  fragment HistoricalArtifactRevisionFields on HistoricalArtifactRevision {
    acquisition_date
    acquisition_method
    artifact_type
    creation_time
    creator
    description
    dimensions
    implementation_technique
    literature
    natural_resource
    place_of_origin
    preservation_state
    preserved
    value
  }
`;
