// @flow
import * as React from 'react';
import { Layout, message } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import NavigationContainer from '../Navigation';
import Routes from '../../pages/routes';
import 'antd/dist/antd.min.css';
import { navigationHeight } from '../../lib/style/themes';
import Breakpoints from '../../lib/constants/breakpoints';
import Footer from '../Footer';
import QuestionnaireModal from '../QuestionnaireModal';
import CookieBanner from '../CookieBanner';

const { Content } = Layout;

const AppLayout = (): React.Node => (
  <React.Fragment>
    <GlobalStyle />
    <FullHeightLayout>
      <NavigationContainer />
      <Content>
        <Routes />
      </Content>
      <QuestionnaireModal />
      <Footer />
    </FullHeightLayout>
    <CookieBanner />
  </React.Fragment>
);

// global styles for antd messages
message.config({
  top: 84,
});

const FullHeightLayout = styled(Layout)`
  padding-top: ${navigationHeight};
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  @media print {
    padding-top: 0;
    background-color: #ffffff !important;
  }
  .ant-layout-content {
    display: flex;
    flex-direction: column;
  }
  @page {
    margin: 0.5cm;
    size: portrait;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: scroll;
  }

  @media print{
    .show-print {
      width: 100% !important;
      display: block !important;
      max-width: 20cm !important;
      margin: 15px auto !important;
    }
  .ant-menu-dark.ant-menu-submenu-popup {
    position: fixed !important;
  }

  @media print{
    .no-print, .no-print *
    {
        display: none !important;
    }
  }

  @media (max-width: ${Breakpoints.tablet}) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
`;

export default AppLayout;
