// @flow
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Card, Row, Col, Input,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import dictionariesId from '../../../lib/constants/dictionariesId';
// Form Validation
import { required } from '../../../lib/formValidation';
// Own Component
import DictionarySelect from '../../DictionarySelect';

const FormItem = Form.Item;

type PropTypes = {
  azp: ?string,
  city: ?string,
  community: ?string,
  county: ?string,
  formType: ?string,
  getFieldDecorator: Function,
  ordinalNumberInAzp: ?string,
  ordinalNumberInCity: ?string,
  setFieldsValue: Function,
  voivodeship: ?string,
};

const DiscoveryBasicSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator,
    setFieldsValue,
    formType,
    voivodeship,
    county,
    community,
    city,
    ordinalNumberInCity,
    azp,
    ordinalNumberInAzp,
  } = props;
  const {
    voivodeshipId, countyId, communityId, cityId, azpId,
  } = dictionariesId;

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          {formType === 'KZH' ? (
            <FormattedMessage
              id="OriginPlaceSection.originPlace.title"
              defaultMessage="Miejsce pochodzenia"
            />
          ) : (
            <FormattedMessage
              id="DiscoveryBasicSection.discoveryPlace.title"
              defaultMessage="Miejsce odkrycia - informacje ogólne"
            />
          )}
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col xs={24} sm={12} lg={8}>
          <DictionarySelect
            dictionaryId={voivodeshipId}
            label={
              <FormattedMessage id="DiscoveryBasicSection.discoveryPlace.voivodeship" defaultMessage="Województwo" />
            }
            name="voivodeship_id"
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            validationRules={[required]}
            initialFieldValue={voivodeship || null}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <DictionarySelect
            dictionaryId={countyId}
            label={<FormattedMessage id="DiscoveryBasicSection.discoveryPlace.county" defaultMessage="Powiat" />}
            name="county_id"
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            validationRules={[required]}
            initialFieldValue={county || null}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <DictionarySelect
            dictionaryId={communityId}
            label={<FormattedMessage id="DiscoveryBasicSection.discoveryPlace.community" defaultMessage="Gmina" />}
            name="community_id"
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            validationRules={[required]}
            initialFieldValue={community || null}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <DictionarySelect
            dictionaryId={cityId}
            label={<FormattedMessage id="DiscoveryBasicSection.discoveryPlace.city" defaultMessage="Miejscowość" />}
            name="city_id"
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            validationRules={[required]}
            initialFieldValue={city || null}
          />
        </Col>
        {formType !== 'KZH' && (
        <React.Fragment>
          <Col xs={24} sm={12} lg={8}>
            <FormItem
              label={(
                <FormattedMessage
                  id="DiscoveryBasicSection.discoveryPlace.ordinalNumberInCity"
                  defaultMessage="Nr stanowiska w miejscowości"
                />
  )}
            >
              {getFieldDecorator('ordinal_number_in_city', {
                rules: [required],
                initialValue: ordinalNumberInCity || null,
              })(<Input />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <DictionarySelect
              dictionaryId={azpId}
              label={<FormattedMessage id="DiscoveryBasicSection.discoveryPlace.azpId" defaultMessage="Nr AZP" />}
              name="azp_id"
              getFieldDecorator={getFieldDecorator}
              setFieldsValue={setFieldsValue}
              validationRules={[required]}
              initialFieldValue={azp || null}
            />
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <FormItem
              label={(
                <FormattedMessage
                  id="DiscoveryBasicSection.discoveryPlace.ordinalNumberInAZP"
                  defaultMessage="Nr stanowiska na obszarze AZP"
                />
  )}
            >
              {getFieldDecorator('ordinal_number_in_azp', {
                rules: [required],
                initialValue: ordinalNumberInAzp || null,
              })(<Input />)}
            </FormItem>
          </Col>
        </React.Fragment>
        )}
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default (injectIntl(DiscoveryBasicSection): any);
