// @flow
import * as React from 'react';
import {
  Col, Input, Row, Button, Popconfirm, DatePicker,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  FormattedMessage, defineMessages, injectIntl, IntlShape,
} from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';
// Form Validation
import { required } from '../../../../lib/formValidation';
// Own Types
import type { RentalsType, MomentType } from '../../../../lib/types';

const FormItem = Form.Item;

type PropsTypes = {
  getFieldDecorator: Function,
  getFieldValue: Function,
  handleRemoveRentals: (rental: RentalsType) => Promise<void>,
  intl: typeof IntlShape,
  isFieldTouched: Function,
  name: string,
  rental: Object,
  resetFields: Function,
  setFieldsValue: Function,
};

class RentalsField extends React.Component<PropsTypes, null> {
  disabledEndDate = (returnDate: MomentType): any => {
    const { getFieldValue, name } = this.props;

    const rentalDate = getFieldValue(`${name}.rental_date`);

    if (!returnDate || !rentalDate) {
      return false;
    }

    const isDateBeforeRentalDate = returnDate.valueOf() < rentalDate.valueOf();
    const today = moment().valueOf();
    const isReturnDateInFuture = returnDate.valueOf() > today;

    return isDateBeforeRentalDate || isReturnDateInFuture;
  };

  onStartChange = (value: SyntheticInputEvent<HTMLInputElement>, name: string) => {
    const {
      resetFields,
      setFieldsValue,
      rental: { return_date: returnDate },
    } = this.props;

    const newValueIsAfterReturnDate = value && value.valueOf() > moment(returnDate).valueOf();

    if (!value || newValueIsAfterReturnDate) {
      resetFields(`${name}.return_date`);
      setFieldsValue({ [`${name}.return_date`]: null });
    }
  };

  render(): React.Node {
    const {
      getFieldDecorator, getFieldValue, handleRemoveRentals, intl, rental, name, isFieldTouched,
    } = this.props;

    const initialRentalDate = rental.rental_date && !isFieldTouched(`${name}.rental_date`);
    const rentalDate = getFieldValue(`${name}.rental_date`) || initialRentalDate;

    const translations = defineMessages({
      removeQuestion: {
        defaultMessage: 'Czy chcesz usunąć wypożyczenie?',
        id: 'RentalsField.removeQuestion',
      },
      yes: {
        defaultMessage: 'Tak',
        id: 'general.yes',
      },
      no: {
        defaultMessage: 'Nie',
        id: 'general.no',
      },
      placeholder: {
        defaultMessage: 'RRRR-MM-DD',
        id: 'RentalsField.placeholder',
      },
    });

    return (
      <React.Fragment>
        <Row gutter={24} type="flex" align="middle" justify="space-between">
          <HiddenFormItem>
            {getFieldDecorator(`${name}.localId`, {
              initialValue: rental.localId,
            })(<Input />)}
          </HiddenFormItem>
          {rental.id && (
            <HiddenFormItem>
              {getFieldDecorator(`${name}.id`, {
                initialValue: rental.id,
              })(<Input />)}
            </HiddenFormItem>
          )}
          <Col span={12}>
            <FormItem
              label={<FormattedMessage id="RentalsField.personInstitution" defaultMessage="Osoba/Instytucja" />}
            >
              {getFieldDecorator(`${name}.renter`, {
                initialValue: rental.renter,
                rules: [required],
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label={<FormattedMessage id="RentalsField.rentalDate" defaultMessage="Data wypożyczenia" />}>
              {getFieldDecorator(`${name}.rental_date`, {
                rules: [required],
                initialValue: (rental.rental_date && moment(rental.rental_date)) || null,
              })(
                <DatePicker
                  format="YYYY-MM-DD"
                  placeholder={intl.formatMessage(translations.placeholder)}
                  onChange={(event: SyntheticInputEvent<HTMLInputElement>): void => this.onStartChange(event, `${name}`)
                  }
                />,
              )}
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label={<FormattedMessage id="RentalsField.returnDate" defaultMessage="Data zwrotu" />}>
              {getFieldDecorator(`${name}.return_date`, {
                initialValue: (rental.return_date && moment(rental.return_date)) || null,
              })(
                <DatePicker
                  format="YYYY-MM-DD"
                  disabledDate={this.disabledEndDate}
                  placeholder={intl.formatMessage(translations.placeholder)}
                  disabled={!rentalDate}
                />,
              )}
            </FormItem>
          </Col>
          <StyledCol span={2}>
            {!rental.id && (
              <FormattedMessage id="general.remove" defaultMessage="Usuń">
                {(buttonText: string): React.Node => (
                  <Popconfirm
                    title={intl.formatMessage(translations.removeQuestion)}
                    onConfirm={(): Promise<void> => handleRemoveRentals(rental)}
                    okText={intl.formatMessage(translations.yes)}
                    cancelText={intl.formatMessage(translations.no)}
                  >
                    <StyledButton>{buttonText}</StyledButton>
                  </Popconfirm>
                )}
              </FormattedMessage>
            )}
          </StyledCol>
        </Row>
      </React.Fragment>
    );
  }
}

const StyledButton = styled(Button)`
  margin-right: 15px;
`;

const HiddenFormItem = styled(FormItem)`
  display: none !important;
`;

const StyledCol = styled(Col)`
  display: flex !important;
  justify-content: flex-end;
`;

export default (injectIntl(RentalsField): any);
