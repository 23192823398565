// @flow
import store from 'store';
import CURRENT_USER from '../queries/CurrentUser';
import decodeJWT from '../utils/decodeJWT';
import type { UserType } from '../types';

function setUserOrEmptyUser(user: ?UserType): UserType {
  return {
    __typename: 'User',
    id: (user && user.id) || null,
    name: (user && user.name) || null,
    level: (user && user.level) || null,
    email: (user && user.email) || null,
  };
}

function getUserFromStore(): ?UserType {
  const token: string = store.get('authToken');
  const { user } = decodeJWT(token);

  return user;
}

export default {
  defaults: {
    current_user: (setUserOrEmptyUser(getUserFromStore()): UserType),
  },
  resolvers: {
    Mutation: {
      CurrentUser: (_: any, { token }: { token: string }, { cache }: { cache: Object }): null => {
        const { user } = decodeJWT(token);

        cache.writeQuery({
          query: CURRENT_USER,
          data: {
            current_user: setUserOrEmptyUser(user),
          },
        });

        if (!user) {
          store.remove('authToken');

          return null;
        }
        store.set('authToken', token);

        return null;
      },
    },
  },
};
