// @flow
import GET_THEME_QUERY from '../queries/getTheme';
import { standard } from '../constants/themeModes';

const userThemeMode: any | string = localStorage.getItem('theme') || standard;

const changeTheme = {
  defaults: {
    theme: { mode: userThemeMode, __typename: 'theme' },
  },
  resolvers: {
    Mutation: {
      ChangeTheme: (_: any, { theme }: { theme: Object }, { cache }: { cache: Object }): null => {
        cache.writeQuery({
          query: GET_THEME_QUERY,
          data: {
            theme: {
              mode: theme.mode,
              __typename: 'theme',
            },
          },
        });
        localStorage.setItem('theme', theme.mode);

        return null;
      },
    },
  },
};

export default changeTheme;
