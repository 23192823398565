// @flow
import * as React from 'react';
import { Menu } from 'antd';
import { EyeOutlined, TableOutlined, UnlockOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import NavigationDrawer from './NavigationDrawer';
import AccessibilitySwitch from '../AccessibilitySwitch';
import LanguageSwitch from '../LanguageSwitch';
import LoginButton from '../LoginButton';
import ContentForLoggedInUsers from '../ContentForLoggedInUsers';
import Logo from '../Logo';
import GET_LANGUAGE_QUERY from '../../lib/queries/getLanguage';
import withUser from '../withUser';
import type { UserType, QueryRenderProps } from '../../lib/types';
import Breakpoints from '../../lib/constants/breakpoints';
import { darkBlueColor } from '../../lib/style/themes';
import ARTIFACT_STATUS from '../../lib/constants/artifactStatus';
import USER_LEVEL from '../../lib/constants/userLevel';
import routePaths from '../../lib/constants/routePaths';
import withRouter from '../../lib/utils/withRouter';

const MenuItem = Menu.Item;
const { SubMenu } = Menu;

type PropsType = {
  location: {
    pathname: string,
  },
  user: UserType,
};

const Navigation = (props: PropsType): React.Node => {
  const {
    location: { pathname },
    user: { email, level },
  } = props;

  const artifactMassEditRegExp = pathToRegexp(routePaths.adminMassArtifactEdit);
  const isArtifactMassEditPath = artifactMassEditRegExp.exec(pathname);

  const artifactSeparatedEditRegExp = pathToRegexp(routePaths.adminSeparatedArtifactEdit);
  const isArtifactSeparatedEditPath = artifactSeparatedEditRegExp.exec(pathname);

  const artifactHistoricalEditRegExp = pathToRegexp(routePaths.adminHistoricalArtifactEdit);
  const isArtifactHistoricalEditPath = artifactHistoricalEditRegExp.exec(pathname);


  const artifactDetailRegExp = pathToRegexp(routePaths.artifactsDetails);
  const isArtifactDetailPath = artifactDetailRegExp.exec(pathname);

  const artifactsByCatalogIdDetailsRegExp = pathToRegexp(routePaths.artifactsByCatalogIdDetails);
  const isArtifactByCatalogIdDetailPath = artifactsByCatalogIdDetailsRegExp.exec(pathname);

  const dictionaryRegExp = pathToRegexp(routePaths.adminDictionary);
  const isDictionaryPath = dictionaryRegExp.exec(pathname);

  let selectedKeys: [string | null] = [pathname];

  if (
    isArtifactMassEditPath
    || isArtifactSeparatedEditPath
    || isArtifactHistoricalEditPath
    || isArtifactDetailPath
    || isArtifactByCatalogIdDetailPath
    || isDictionaryPath) {
    selectedKeys = [null];
  }

  return (
    <React.Fragment>
      <NavigationWrapper>
        <NavigationContainer>
          <StyledMenuItemLogoMobile to="/" tabIndex="-1">
            <Logo />
          </StyledMenuItemLogoMobile>
          <StyledMainMenu mode="horizontal" defaultSelectedKeys={selectedKeys} selectedKeys={selectedKeys}>
            <StyledMenuItemLogo key={routePaths.home} tabIndex="0">
              <Link to={routePaths.home} tabIndex="-1">
                <Logo />
              </Link>
            </StyledMenuItemLogo>
            <StyledSubMenu
              title={(
                <span>
                  <EyeOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                  <FormattedMessage id="Navigation.about.subMenu" defaultMessage="O archiwum" />
                </span>
)}
              tabIndex="0"
              key="/about"
            >
              <MenuItem key={routePaths.aboutFaq}>
                <Link to={routePaths.aboutFaq}>
                  <FormattedMessage id="Navigation.about.faq" defaultMessage="Jak korzystać" />
                </Link>
              </MenuItem>
              <MenuItem key={routePaths.contact}>
                <a
                  href={routePaths.contact}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FormattedMessage id="Navigation.about.contact" defaultMessage="Formularz kontaktowy" />
                </a>
              </MenuItem>
            </StyledSubMenu>
            <StyledMenuItem key={routePaths.artifactsListHistorical} tabIndex="0">
              <Link to={routePaths.artifactsListHistorical} tabIndex="-1">
                <TableOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                <FormattedMessage id="Navigation.listHistoricalView" defaultMessage="Katalog historyczny" />
              </Link>
            </StyledMenuItem>
            <StyledMenuItem key={routePaths.artifactsListArchaeological} tabIndex="0">
              <Link to={routePaths.artifactsListArchaeological} tabIndex="-1">
                <TableOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                <FormattedMessage id="Navigation.listArchaeologicalView" defaultMessage="Katalog archeologiczny" />
              </Link>
            </StyledMenuItem>
            <StyledContentForLoggedInUsers subMenu>
              <StyledSubMenu
                title={(
                  <span>
                    <UnlockOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                    <FormattedMessage id="Navigation.admin.subMenu" defaultMessage="Administrator" />
                  </span>
)}
                tabIndex="0"
              >
                <MenuItem key={routePaths.adminKzmNew}>
                  <Link to={routePaths.adminKzmNew}>
                    <FormattedMessage id="Navigation.admin.addKZM" defaultMessage="Dodaj zabytek masowy" />
                  </Link>
                </MenuItem>
                <MenuItem key={routePaths.adminKzwNew}>
                  <Link to={routePaths.adminKzwNew}>
                    <FormattedMessage id="Navigation.admin.addKZW" defaultMessage="Dodaj zabytek wydzielony" />
                  </Link>
                </MenuItem>
                <MenuItem key={routePaths.adminKzhNew}>
                  <Link to={routePaths.adminKzhNew}>
                    <FormattedMessage id="Navigation.admin.addKZH" defaultMessage="Dodaj zabytek historyczny" />
                  </Link>
                </MenuItem>
                <MenuItem key={routePaths.artifactsListAdministration}>
                  <Link to={routePaths.artifactsListAdministration}>
                    <FormattedMessage id="Navigation.admin.administration" defaultMessage="Widok administracji" />
                  </Link>
                </MenuItem>
                <MenuItem key={routePaths.artifactsListModeration} disabled={level !== USER_LEVEL.superAdmin}>
                  <Link to={`${routePaths.artifactsListModeration}?status=${String(ARTIFACT_STATUS.ready)}`}>
                    <FormattedMessage id="Navigation.admin.moderation" defaultMessage="Widok moderacji" />
                  </Link>
                </MenuItem>
                <MenuItem key={routePaths.artifactsListMyWork}>
                  <Link
                    to={
                      `${routePaths.artifactsListMyWork}?createdByEmail=${String(email)}`
                      + `&status=${String(ARTIFACT_STATUS.draft)}`
                      + `&status=${String(ARTIFACT_STATUS.ready)}`
                      + `&status=${String(ARTIFACT_STATUS.rejected)}`
                    }
                  >
                    <FormattedMessage id="Navigation.admin.work" defaultMessage="Moja praca" />
                  </Link>
                </MenuItem>
                <MenuItem key={routePaths.adminDictionaries}>
                  <Link to={routePaths.adminDictionaries}>
                    <FormattedMessage id="Navigation.admin.dictionaries" defaultMessage="Słowniki" />
                  </Link>
                </MenuItem>
                <MenuItem key={routePaths.adminDetails}>
                  <a href={routePaths.adminDetails} target="_blank" rel="noreferrer noopener">
                    <FormattedMessage id="Navigation.admin.details" defaultMessage="Szczegóły konta" />
                  </a>
                </MenuItem>
              </StyledSubMenu>
            </StyledContentForLoggedInUsers>
          </StyledMainMenu>
          <Query query={GET_LANGUAGE_QUERY}>
            {({ loading, error, data }: QueryRenderProps<{ language: string }>): React.Node => {
              if (loading || error) return null;

              if (data && data.language) {
                const { language } = data;

                return (
                  <StyledUserMenu
                    mode="horizontal"
                    theme="light"
                    defaultSelectedKeys={[language]}
                    style={{ backgroundColor: '#001529' }}
                  >
                    <AccessibilitySwitch />
                    <LanguageSwitch />
                    <StyledMenuItemLoginButton tabIndex="0" aria-label="Logowanie">
                      <LoginButton tabIndex="-1" />
                    </StyledMenuItemLoginButton>
                  </StyledUserMenu>
                );
              }

              return null;
            }}
          </Query>
          <NavigationDrawer />
        </NavigationContainer>
      </NavigationWrapper>
    </React.Fragment>
  );
};

const NavigationWrapper = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1001;
  background-color: ${darkBlueColor};
  padding: 0 15px !important;

  @media print {
    display: none;
  }
`;

const NavigationContainer = styled.div`
  position: relative;
  max-width: ${Breakpoints.desktop};
  margin: 0 auto;

  @media (max-width: ${Breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledMenuItemLogoMobile = styled(Link)`
  display: none;
  @media (max-width: ${Breakpoints.tablet}) {
    display: flex;
  }
`;

const StyledContentForLoggedInUsers = styled(ContentForLoggedInUsers)`
  @media (max-width: ${Breakpoints.mobile}) {
    display: none;
  }
}`;

const StyledMainMenu = styled((props: any) => <Menu theme="dark" {...props} />)`
  width: calc(100% - 260px);

  li {
    min-height: 74px;

    a, .ant-menu-submenu-title {
      min-height: 74px;
      display: flex !important;
      align-items: center !important;
    }
  }

  @media (max-width: ${Breakpoints.tablet}) {
    display: none !important;
  }
`;

const StyledUserMenu = styled(Menu)`
  position: absolute;
  right: 0;
  top: 0;
  width: 260px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${darkBlueColor} !important;

  .ant-menu-item, .ant-menu-submenu-title {
    padding-right: 10px !important;
  }

  @media (max-width: ${Breakpoints.tablet}) {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const StyledMenuItemLogo = styled(MenuItem)`
  padding-left: 0 !important;
  background-color: ${darkBlueColor} !important;
`;

const StyledSubMenu = styled(SubMenu)`
  font-weight: 600;
`;

const StyledMenuItem = styled(MenuItem)`
  font-weight: 600;
  @media (max-width: ${Breakpoints.tablet}) {
    display: none;
  }
`;

const StyledMenuItemLoginButton = styled(MenuItem)`
  padding: 13px 0 !important;
  background-color: ${darkBlueColor} !important;
  border-color: ${darkBlueColor} !important;
  @media (max-width: ${Breakpoints.tablet}) {
    display: none !important;
  }
`;

export default (withRouter(withUser(Navigation)): any);
