import gql from 'graphql-tag';

export default gql`
  mutation ChangeArtifactStatus($id: ID!, $status: ArtifactStatus!, $comment: String) {
    changeArtifactStatus(id: $id, status: $status, comment: $comment) {
      id
      status
      comment
    }
  }
`;

export const changeArtifactStatusMock = {
  data: {
    changeArtifactStatus: {
      id: '1', status: 'HIDDEN', comment: null, __typename: 'MassArtifact',
    },
  },
};
