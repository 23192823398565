// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import styled, { css } from 'styled-components';
import { Breadcrumb, Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import { accessibility } from '../../lib/constants/themeModes';
import { biggerFontSize, generalFontSize } from '../../lib/style/themes';
import Breakpoints from '../../lib/constants/breakpoints';
// routePaths is deprecated please use pageConfig instead
import routePaths from '../../lib/constants/routePaths';
import useMatch from '../../lib/hooks/useMatch';
import withRouter from '../../lib/utils/withRouter';
import { getPageConfig } from './BreadCrumbUtils';

const breadcrumbsTitleMap = {
  about: <FormattedMessage id="Navigation.about.subMenu" defaultMessage="O archiwum" />,
  artifactsHistoricalList: (
    <Link to={routePaths.artifactsListHistorical}>
      <FormattedMessage id="Navigation.artifacts.listHistorical" defaultMessage="Katalog historyczny" />
    </Link>
  ),
  artifactsArchaeologicalList: (
    <Link to={routePaths.artifactsListArchaeological}>
      <FormattedMessage id="Navigation.artifacts.listArchaeological" defaultMessage="Katalog archeologiczny" />
    </Link>
  ),
  artifactsMyWorkList: (
    <Link to={routePaths.artifactsListMyWork}>
      <FormattedMessage id="Navigation.artifacts.listMyWork" defaultMessage="Moja praca" />
    </Link>
  ),
  artifactsModerationList: (
    <Link to={routePaths.artifactsListModeration}>
      <FormattedMessage id="Navigation.artifacts.listModeration" defaultMessage="Widok moderacji" />
    </Link>
  ),
  artifactsAdministrationList: (
    <Link to={routePaths.artifactsListAdministration}>
      <FormattedMessage id="Navigation.artifacts.listAdministration" defaultMessage="Widok administracji" />
    </Link>
  ),
  artifactDetails: <FormattedMessage id="BreadCrumb.artifactDetails" defaultMessage="Szczegóły zabytku" />,
  admin: <FormattedMessage id="Navigation.admin.subMenu" defaultMessage="Administrator" />,
  addKZM: (
    <Link to={routePaths.adminKzmNew}>
      <FormattedMessage id="Navigation.admin.addKZM" defaultMessage="Dodaj zabytek masowy" />
    </Link>
  ),
  addKZH: (
    <Link to={routePaths.adminKzhNew}>
      <FormattedMessage id="Navigation.admin.addKZH" defaultMessage="Dodaj zabytek historyczny" />
    </Link>
  ),
  addKZW: (
    <Link to={routePaths.adminKzwNew}>
      <FormattedMessage id="Navigation.admin.addKZW" defaultMessage="Dodaj zabytek wydzielony" />
    </Link>
  ),
  dictionaries: (
    <Link to={routePaths.adminDictionaries}>
      <FormattedMessage id="Navigation.admin.dictionaries" defaultMessage="Słowniki" />
    </Link>
  ),
  dictionary: <FormattedMessage id="BreadCrumb.dictionary" defaultMessage="Słownik" />,
  faq: <FormattedMessage id="BreadCrumb.faq" defaultMessage="Jak korzystać" />,
  privacy: <FormattedMessage id="StaticPage.privacyTitle" defaultMessage="Polityka Prywatności" />,
  rules: <FormattedMessage id="StaticPage.rulesTitle" defaultMessage="Regulamin" />,
  editMassArtifact: <FormattedMessage id="Navigation.admin.editKZM" defaultMessage="Edycja zabytku masowego" />,
  editHistoricalArtifact: (
    <FormattedMessage id="Navigation.admin.editKZH" defaultMessage="Edycja zabytku historycznego" />
  ),
  editSeparatedArtifact: (
    <FormattedMessage id="Navigation.admin.editKZW" defaultMessage="Edycja zabytku wydzielonego" />
  ),
  artifactsChangeLog: <FormattedMessage id="BreadCrumb.artifactChangelog" defaultMessage="Historia zmian" />,
};

type PropsType = {
  breadcrumbs: string[],
  intl: typeof IntlShape,
  location: { pathname: string },
  match: Object,
  title: string,
};

const BreadCrumb = (props: PropsType): React.Node => {
  const match = useMatch();
  const {
    breadcrumbs = [],
    intl: { formatMessage },
    title,
  } = props;

  const { showBreadcrumbs, helmetPageTitle, pageTitle } = getPageConfig(match);

  const breadcrumbItems = breadcrumbs.map(
    (breadcrumb: string): React.Node => (
      <StyledBreadCrumbItem key={breadcrumb}>
        {breadcrumbsTitleMap[breadcrumb]}
      </StyledBreadCrumbItem>
    ),
  );

  if (!pageTitle) {
    return (
      <Helmet>
        <title>eArchiwum Archeologiczne</title>
      </Helmet>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{formatMessage(helmetPageTitle)} - eArchiwum Archeologiczne</title>
      </Helmet>
      <BreadCrumbWrapper>
        <StyledRow align="middle" type="flex">
          <StyledCol span={12}>
            <StyledBreadCrumb>{showBreadcrumbs && breadcrumbItems}</StyledBreadCrumb>
            <StyledPageTitle>{title || <FormattedMessage {...pageTitle} />}</StyledPageTitle>
          </StyledCol>
          <StyledCol span={12}>
            <StyledBreadcrumbButton id="breadcrumb-button" />
          </StyledCol>
        </StyledRow>
      </BreadCrumbWrapper>
    </React.Fragment>
  );
};

const StyledCol = styled(Col)`
  @media (max-width: ${Breakpoints.mobile}) {
    width: 100% !important;
  }
`;

const BreadCrumbWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 10px 0 !important;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: yellow;
      font-size: ${biggerFontSize};
    `};

  @media print {
    display: none;
  }
`;

const StyledRow = styled(Row)`
  max-width: ${Breakpoints.desktop};
  margin: 0 auto !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  @media all and (min-width: 1250px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
`;

const StyledBreadcrumbButton = styled.div`
  float: right;
  @media (max-width: ${Breakpoints.mobile}) {
    display: none;
  }
`;

const StyledBreadCrumbItem = styled(Breadcrumb.Item)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      font-size: ${generalFontSize};
      color: black;
      &:focus {
        border: 2px solid red;
      }
    `};
`;

const StyledBreadCrumb = styled(Breadcrumb)`
  min-height: 20px;
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      a span {
        color: black;
      }
    `};
`;

const StyledPageTitle = styled.h1`
  font-size: 21px;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      font-size: 26px;
    `};
`;

export default (withRouter(injectIntl(BreadCrumb)): any);
