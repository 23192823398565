// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  Row, Col, Table, Input, Tag, Button, Popconfirm,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';
import type { PhotoType, PhotoLocalIdType, TableColumnsType } from '../../lib/types';
import { max128Characters } from '../../lib/formValidation';

import FileUploadInput from './FileUploadInput';
import DragableBodyRow from '../DragableBodyRow';

const FormItem = Form.Item;

type PropsType = {
  getFieldDecorator: Function,
  getFieldValue: Function,
  handleRemoveFile: (photoIdToRemove: string) => Promise<void>,
  loading: boolean,
  mutate: Function,
  onDragSortingTableRowMethod: Function,
};

const FileUploadTable = (props: PropsType): React.Node => {
  const {
    mutate, loading, handleRemoveFile, getFieldDecorator, getFieldValue, onDragSortingTableRowMethod,
  } = props;

  const photosForm = getFieldValue('photosForm');

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const prepareColumns = (): TableColumnsType[] => {
    return [
      {
        title: <FormattedMessage id="FileUpload.photos" defaultMessage="Zdjęcie" />,
        dataIndex: 'path',
        key: 'path',
        width: 140,
        render: (rowText: string, record: PhotoLocalIdType): React.Node => {
          const storageUrl = process.env.REACT_APP_PHOTO_STORAGE;

          if (!storageUrl || !record) return <FormattedMessage id="general.noData" defaultMessage="Brak danych" />;

          return (
            <React.Fragment>
              <StyledFormItem>
                {getFieldDecorator(`photos[${record.localId}].path`, {
                  initialValue: rowText || null,
                })(<StyledImg alt={record.description} src={`${storageUrl}${rowText}`} />)}
              </StyledFormItem>
              <HiddenFormItem>
                {getFieldDecorator(`photos[${record.localId}].id`, {
                  initialValue: record.id || null,
                })(<Input />)}
              </HiddenFormItem>
              <HiddenFormItem>
                {getFieldDecorator(`photos[${record.localId}].localId`, {
                  initialValue: record.localId || 0,
                })(<Input />)}
              </HiddenFormItem>
            </React.Fragment>
          );
        },
      },
      {
        title: <FormattedMessage id="FileUpload.type" defaultMessage="Typ" />,
        dataIndex: 'main',
        key: 'id',
        width: 140,
        render: (rowText: string, record: string, index: number): React.Node => {
          return index === 0 ? (
            <Tag color="orange">
              <FormattedMessage id="FileUpload.mainPhoto" defaultMessage="Główne" />
            </Tag>
          ) : (
            <Tag color="blue">
              <FormattedMessage id="FileUpload.normalPhoto" defaultMessage="Normalne" />
            </Tag>
          );
        },
      },
      {
        title: <FormattedMessage id="FileUpload.description" defaultMessage="Opis" />,
        dataIndex: 'description',
        key: 'description',
        render: (rowText: string, record: PhotoLocalIdType): React.Node => {
          return (
            <StyledFormItem>
              {getFieldDecorator(`photos[${record.localId}].description`, {
                rules: [max128Characters],
                initialValue: record.description || null,
              })(<Input />)}
            </StyledFormItem>
          );
        },
      },
      {
        key: 'cta',
        dataIndex: 'cta',
        width: 140,
        render: (rowText: string, record: PhotoType): React.Node => {
          return (
            <FormattedMessage id="general.remove" defaultMessage="Usuń">
              {(buttonText: string): React.Node => (
                <Popconfirm
                  title={(
                    <FormattedMessage
                      id="FileUpload.removeQuestion"
                      defaultMessage="Czy na pewno chcesz usunąć zdjęcie?"
                    />
)}
                  onConfirm={(): Promise<void> => handleRemoveFile(record.id)}
                  okText={<FormattedMessage id="general.yes" defaultMessage="Tak" />}
                  cancelText={<FormattedMessage id="general.no" defaultMessage="Nie" />}
                >
                  <Button>{buttonText}</Button>
                </Popconfirm>
              )}
            </FormattedMessage>
          );
        },
      },
    ];
  };

  const columns = prepareColumns();

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <StyledTable
            columns={columns}
            pagination={false}
            dataSource={photosForm}
            components={components}
            onRow={onDragSortingTableRowMethod}
            rowKey={(photo: PhotoType): React.Node => photo.id}
          />
          {loading && (
            <UploadingRow>
              <FormattedMessage id="FileUpload.uploading" defaultMessage="Wgrywanie zdjęcia..." />
            </UploadingRow>
          )}
        </Col>
      </Row>
      <Col span={24}>
        <FileUploadInput mutate={mutate} />
      </Col>
    </React.Fragment>
  );
};

const StyledTable = styled(Table)`
  tbody tr[index='0'] {
    background-color: #fff7e6;
  }
`;

const UploadingRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f2f2;
  height: 93px;
`;

const StyledFormItem = styled(FormItem)`
  &.ant-form-item {
    margin-bottom: 0;
    padding-bottom: 0 !important;
  }
`;

const HiddenFormItem = styled(FormItem)`
  display: none !important;
`;

const StyledImg = styled.img`
  max-height: 60px;
  max-width: 60px;
`;

export default FileUploadTable;
