// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import type { PhotoType } from '../../lib/types';

type PropsType = {
  photos: PhotoType[],
};

const PhotosSection = (props: PropsType): React.Node => {
  const { photos } = props;
  const storageUrl = process.env.REACT_APP_PHOTO_STORAGE;

  const isEmptyArray = photos.length < 1;

  if (!photos || isEmptyArray || !storageUrl) return <FormattedMessage id="general.emptyValue" defaultMessage="brak" />;

  const galleryItems = photos.map(
    (photo: PhotoType): React.Node => {
      return (
        <GalleryItem key={photo.path} href={`${storageUrl}${photo.path}`} target="_blank">
          <ImageContainer>
            <Image alt={photo.description} src={`${storageUrl}${photo.path}`} tabIndex="0" />
          </ImageContainer>
          <Description>{photo.description}</Description>
        </GalleryItem>
      );
    },
  );

  return (
    <Gallery>
      {galleryItems}
    </Gallery>
  );
};

const Gallery = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  min-height: 252px;
`;

const GalleryItem = styled.a`
  display: flex;
  width: 100%;
  flex-flow: column;
  padding: 5px;
  border: 1px solid #ccc;
  background: #f0f2f559;
  transition: all .1s ease-in-out;
  position: relative;
  &:hover {
    transform: scale(1.05);
    div:nth-child(2) {
      overflow: visible;
      white-space: normal;
      position: absolute;
      bottom: 5px;
      width: calc(100% - 10px);
    }
  }
`;

const ImageContainer = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const Description = styled.div`
    min-height: 40px;
    background: #fff;
    padding: 10px 0;
    color: #595959;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .1s ease-in-out;
`;

export default PhotosSection;
