// @flow
import * as React from 'react';
import { Avatar, message } from 'antd';
import { Icon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { Query, withApollo } from 'react-apollo';
import CURRENT_USER_QUERY from '../../lib/queries/CurrentUser';
import CURRENT_USER_MUTATION from '../../lib/mutations/CurrentUser';
import type { UserType, QueryRenderProps } from '../../lib/types';
import { lightGreyColor, biggerFontSize } from '../../lib/style/themes';
import NoDataBecauseError from '../NoDataBecauseError';
import withRouter from '../../lib/utils/withRouter';

const translations = defineMessages({
  logout: {
    defaultMessage: 'Zostałeś wylogowany',
    id: 'LoginButton.logout',
  },
});

type PropsType = {
  client: any,
  intl: typeof IntlShape,
  navigate: any,
};

export class LoginButton extends React.PureComponent<PropsType, void> {
  static getAuthAddress: (() => string) = (): string => {
    let address = process.env.REACT_APP_AUTH_ADDRESS || '';
    address += '?response_type=code';
    address += '&client_id=';
    address += process.env.REACT_APP_AUTH_ID || '';
    address += '&redirect_uri=';
    address += window.location.origin;
    address += '/auth';

    return address;
  };

  logout: (() => void) = () => {
    const { client, intl, navigate } = this.props;

    client.mutate({
      mutation: CURRENT_USER_MUTATION,
      variables: {
        token: null,
      },
    });

    // here should be client.resetStore() but doesn't work correctly
    // client.cache.reset();
    navigate('/');
    message.success(intl.formatMessage(translations.logout));
  };

  render(): React.Node {
    const authAddress = LoginButton.getAuthAddress();

    return (
      <Query query={CURRENT_USER_QUERY} fetchPolicy="cache-first">
        {({ loading, error, data }: QueryRenderProps<{ current_user: UserType }>): React.Node => {
          if (loading) return 'Loading...';
          if (error) return <NoDataBecauseError />;

          if (data && data.current_user && data.current_user.name) {
            const { current_user: currentUser } = data;

            return (
              <Wrapper onClick={this.logout} tabIndex="0">
                <StyledAvatar shape="square" icon={<UserOutlined />} />
                <Description>{currentUser.name}</Description>
              </Wrapper>
            );
          }

          return (
            <a href={authAddress}>
              <Wrapper tabIndex="0">
                <StyledIcon type="login" />
              </Wrapper>
            </a>
          );
        }}
      </Query>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  cursor: pointer;
`;

const Description = styled.div`
  color: #188ee0;
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
  max-width: 100px;
`;

const StyledAvatar = styled(Avatar)`
  background-color: #188ee0 !important;
  .anticon-user {
    margin-right: 0 !important;
  }
`;

const StyledIcon = styled(Icon)`
  font-size: ${biggerFontSize} !important;
  padding: 14px 0;
  color: ${lightGreyColor} !important;
`;

export default (withApollo(withRouter(injectIntl(LoginButton))): any);
