import gql from 'graphql-tag';

export default gql`
  mutation UpdateDictionaryEntry(
    $value_pl: String!,
    $value_en: String,
    $value_de: String,
    $id: ID!,
    $code: String,
  ) {
    updateDictionaryEntry(
      value_pl: $value_pl,
      value_en: $value_en,
      value_de: $value_de,
      id: $id,
      code: $code,
    ) {
      id
      value_pl
      value_de
      value_en
      code
    }
  }
`;
