// @flow
import * as React from 'react';
import { Drawer } from 'antd';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
// Own Types
import type { DictionaryEntryType } from '../../lib/types';
// style
// Componnent
import DictionaryForm from '../DictionaryForm';

type PropsType = {
  dictionaryData: DictionaryEntryType,
  dictionaryId: string,
  handleAddNewDictionary: () => Promise<void>,
  handleCloseUpdateDictionaryDrawer: () => Promise<void>,
  handleUpdateDictionary: () => Promise<void>,
  intl: typeof IntlShape,
  showUpdateDictionaryDrawer: boolean,
  uniqueDictionaryValidator: () => Promise<void>,
};

const DictionaryDrawer = (props: PropsType): React.Node => {
  const {
    handleCloseUpdateDictionaryDrawer,
    handleAddNewDictionary,
    intl,
    showUpdateDictionaryDrawer,
    handleUpdateDictionary,
    dictionaryData,
    uniqueDictionaryValidator,
    dictionaryId,
  } = props;

  const translations = defineMessages({
    addEntryTitle: {
      defaultMessage: 'Dodaj wpis',
      id: 'DictionaryDrawer.addEntryTitle',
    },
    editEntryTitle: {
      defaultMessage: 'Edytuj wpis',
      id: 'DictionaryDrawer.editEntryTitle',
    },
  });

  return (
    <React.Fragment>
      <Drawer
        title={
          dictionaryData.id
            ? intl.formatMessage(translations.editEntryTitle)
            : intl.formatMessage(translations.addEntryTitle)
        }
        width={450}
        placement="right"
        onClose={handleCloseUpdateDictionaryDrawer}
        maskClosable={false}
        visible={showUpdateDictionaryDrawer}
        zIndex={1002}
      >
        {showUpdateDictionaryDrawer && (
          <DictionaryForm
            handleClose={handleCloseUpdateDictionaryDrawer}
            onSubmit={dictionaryData.id ? handleUpdateDictionary : handleAddNewDictionary}
            dictionaryData={dictionaryData}
            uniqueDictionaryValidator={uniqueDictionaryValidator}
            dictionaryId={dictionaryId}
          />
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default (injectIntl(DictionaryDrawer): any);
