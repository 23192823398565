// @flow
import * as React from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import ArtifactsChangeLogTable from './ArtifactsChangeLogTable';

const ArtifactsChangeLog = (): React.Node => {
  return (
    <React.Fragment>
      <BreadCrumb />
      <ArtifactsChangeLogTable />
    </React.Fragment>
  );
};

export default ArtifactsChangeLog;
