// @flow

// HISTORICAL ARTIFACTS LIST
export const artifactsHistoricalListFloatingColumns = [
  'created_at',
  'updated_at',
  'published_at',
  'title',
  'created_by_email',
  'catalog_id',
  'status',
  'short_catalog_id',
  'type',
  'voivodeship',
  'county',
  'community',
  'city',
  'artifact_type',
  'natural_resource',
  'shelving_number',
  'shelf_number',
];

export const artifactsHistoricalListDefaultColumns = ['catalog_id', 'status', 'type', 'city'];

// ARCHAEOLOGICAL ARTIFACTS LIST
export const artifactsArchaeologicalListFloatingColumns = [
  'created_at',
  'updated_at',
  'published_at',
  'created_by_email',
  'title',
  'catalog_id',
  'status',
  'short_catalog_id',
  'type',
  'voivodeship',
  'county',
  'community',
  'city',
  'ordinal_number_in_city',
  'azp',
  'ordinal_number_in_azp',
  'chronology',
  'archaeological_culture',
  'artifact_type',
  'natural_resource',
  'shelving_number',
  'shelf_number',
];

export const artifactsArchaeologicalListDefaultColumns = [
  'catalog_id',
  'status',
  'type',
  'azp',
  'city',
  'ordinal_number_in_city',
];

// MY WORK ARTIFACTS LIST
export const artifactsMyWorkListFloatingColumns = [
  'created_at',
  'updated_at',
  'status',
  'published_at',
  'type',
  'created_by_email',
  'title',
  'catalog_id',
  'short_catalog_id',
  'voivodeship',
  'county',
  'community',
  'city',
  'ordinal_number_in_city',
  'azp',
  'ordinal_number_in_azp',
  'chronology',
  'archaeological_culture',
  'artifact_type',
  'natural_resource',
  'shelving_number',
  'shelf_number',
];

export const artifactsMyWorkListDefaultColumns = ['created_at', 'updated_at', 'status', 'type', 'title'];

// MODERATION ARTIFACTS LIST
export const artifactsModerationListFloatingColumns = [
  'created_at',
  'updated_at',
  'status',
  'published_at',
  'type',
  'created_by_email',
  'title',
  'catalog_id',
  'short_catalog_id',
  'voivodeship',
  'county',
  'community',
  'city',
  'ordinal_number_in_city',
  'azp',
  'ordinal_number_in_azp',
  'chronology',
  'archaeological_culture',
  'artifact_type',
  'natural_resource',
  'shelving_number',
  'shelf_number',
];

export const artifactsModerationListDefaultColumns = [
  'created_at',
  'updated_at',
  'status',
  'type',
  'created_by_email',
  'title',
];

// ADMINISTRATION ARTIFACTS LIST
export const artifactsAdministrationListFloatingColumns = [
  'created_at',
  'updated_at',
  'status',
  'published_at',
  'type',
  'created_by_email',
  'title',
  'catalog_id',
  'short_catalog_id',
  'voivodeship',
  'county',
  'community',
  'city',
  'ordinal_number_in_city',
  'azp',
  'ordinal_number_in_azp',
  'chronology',
  'archaeological_culture',
  'artifact_type',
  'natural_resource',
  'shelving_number',
  'shelf_number',
];

export const artifactsAdministrationListDefaultColumns = [
  'created_at',
  'updated_at',
  'status',
  'published_at',
  'type',
  'catalog_id',
  'county',
  'community',
  'city',
];

// GENERAL COLUMNS SETTINGS
export const columnsSettings = {
  id: { onlyForAdmin: false, align: 'center' },
  title: { onlyForAdmin: false, align: 'left' },
  catalog_id: { onlyForAdmin: false, align: 'left' },
  type: { onlyForAdmin: false, align: 'left' },
  city: { onlyForAdmin: false, align: 'left' },
  voivodeship: { onlyForAdmin: false, align: 'left' },
  county: { onlyForAdmin: false, align: 'left' },
  community: { onlyForAdmin: false, align: 'left' },
  natural_resource: { onlyForAdmin: false, align: 'left' },
  ordinal_number_in_city: { onlyForAdmin: false, align: 'left' },
  azp: { onlyForAdmin: false, align: 'left' },
  chronology: { onlyForAdmin: false, align: 'left' },
  archaeological_culture: { onlyForAdmin: false, align: 'left' },
  artifact_type: { onlyForAdmin: false, align: 'left' },
  created_at: { onlyForAdmin: true, align: 'left' },
  updated_at: { onlyForAdmin: true, align: 'left' },
  published_at: { onlyForAdmin: true, align: 'left' },
  status: { onlyForAdmin: false, align: 'center' },
  created_by_email: { onlyForAdmin: true, align: 'left' },
  shelving_number: { onlyForAdmin: true, align: 'left' },
  shelf_number: { onlyForAdmin: true, align: 'left' },
};
