// @flow
import { Card } from 'antd';
import styled, { css } from 'styled-components';
import { accessibility } from '../../lib/constants/themeModes';
import { darkBlueColor, smallerFontSize, biggerFontSize } from '../../lib/style/themes';

export const StyledCard: any = styled(Card)`
  margin-bottom: 20px !important;

  .ant-card-head-wrapper {
    width: 100%;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: ${darkBlueColor} !important;

      .ant-card-head-title {
        color: yellow !important;
        font-size: 20px;
      }

      &:focus {
        border: 3px solid red !important;
      }
    `};

    &.show-print {
      display: none;
    }

    .section-other {
        & > div:nth-child(odd) {
          padding-left: 0 !important;
        }
      }

    @media print {
      padding: 0 !important;
      page-break-inside: avoid;

      .col-print {
        padding-left: 5px !important;
      }

      .section-other {
        & > div:nth-child(even) {
          padding-left: 5px !important;
        }
      }

      .ant-card-bordered {
        border: 0;
      }

      .ant-card-head,
      .ant-card-wider-padding, .ant-card-body{
         padding: 0 !important;
       }

      .ant-card-head {
        min-height: 0;
        margin-bottom: 10px;
        page-break-after: avoid;
        font-size: ${smallerFontSize} !important;
      }

      .ant-card-body {
        font-size: 12px !important;
      }

      .ant-card-head-title {
        padding: 0;
      }

      .ant-card-body > .ant-card > .ant-card-head {
        border: 0;
      }
    }
`;

export const StyledDetailsName: any = styled.span`
  font-weight: 600;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${biggerFontSize};
    `};
`;

export const StyledDetail: any = styled.span`
  line-height: 30px;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${biggerFontSize};
    `};
`;
