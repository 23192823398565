// @flow
import { useLocation, useParams } from 'react-router-dom';

const useMatch: any = () => {
  const { pathname } = useLocation();
  const params = useParams();

  const path = Object.entries(params).reduce((paramPath: any, [key, value]: [string, any]) => {
    return paramPath.replace(`/${value}`, `/:${key}`);
  }, pathname);

  return { path, url: pathname };
};

export default useMatch;
