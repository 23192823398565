import { formatters } from 'jsondiffpatch/dist/jsondiffpatch.umd';

const HTMLFormatter = formatters.html.default;

const thingsToRemovePattern = /"|\{|\}|,/g;
class SiteChangeLogFormatter extends HTMLFormatter {
  /* eslint-disable */
  formatValue(context, value) {
    const stringValue = JSON.stringify(value, null, 2);
    const cleanedValue = stringValue.replace(thingsToRemovePattern, '');

    context.out(`<pre>${cleanedValue}</pre>`);
  }
  /* eslint-enable */
}

export default SiteChangeLogFormatter;

let defaultInstance;

export function format(delta, left) {
  if (!defaultInstance) {
    defaultInstance = new SiteChangeLogFormatter();
  }

  return defaultInstance.format(delta, left);
}
