// @flow
import * as React from 'react';
import styled from 'styled-components';
import { QRCodeCanvas } from 'qrcode.react';
import { Query } from 'react-apollo';
import { Empty } from 'antd';
import { getLabelQueryPerType, getShortCatalogUrl } from './ArtifactLabelPrintUtils';
import NoDataBecauseError from '../../../components/NoDataBecauseError';
import type { QueryRenderProps, ArtifactType, ArtifactStringUnionType } from '../../../lib/types';
import LoadingSpin from '../../../components/LoadingSpin/LoadingSpin';
import logo from '../../../lib/images/logo.svg';
import withRouter from '../../../lib/utils/withRouter';

const { useEffect } = React;

type PropTypes = {
  location: {
    state: {
      prevPath: string,
    },
  },
  navigate: any,
  params: {
    artifactId: string,
    type: ArtifactStringUnionType,
  },
};

const ArtifactLabelPrint = (props: PropTypes): React.Node => {
  const {
    location: { state },
    params: { artifactId, type },
    navigate,
  } = props;

  const goBack = () => {
    if (state && state.prevPath) navigate(-1);
  };

  useEffect(
    (): Function => {
      window.addEventListener('afterprint', goBack);

      return function cleanup() {
        window.removeEventListener('afterprint', goBack);
      };
    },
  );

  const GET_LABEL_QUERY = getLabelQueryPerType(type);

  return (
    <Query query={GET_LABEL_QUERY} variables={{ id: artifactId }} fetchPolicy="cache-first">
      {({ loading, error, data }: QueryRenderProps<{ historicalArtifact: ArtifactType }>): React.Node => {
        if (error) return <NoDataBecauseError />;

        if (!loading && (!data || !data[Object.keys(data)[0]])) {
          return <Empty />;
        }

        const artifact = data && data[Object.keys(data)[0]];
        const {
          title,
          catalog_id: catalogId,
          short_catalog_id: shortCatalogId,
          shelving_number: shelvingNumber,
          shelf_number: shelfNumber,
        } = artifact || {};
        const {
          location: { origin },
        } = window;

        const url = getShortCatalogUrl({ shortCatalogId, origin });

        if (!loading) setTimeout(window.print, 500);

        return (
          <React.Fragment>
            {loading && <LoadingSpin tip="Loading..." />}
            {!loading && (
              <Label>
                <LogoContainer>
                  <Logo src={logo} />
                </LogoContainer>
                <Title>{title}</Title>
                <Shelving>
                  {shelvingNumber}/{shelfNumber}
                </Shelving>
                <Code>{catalogId}</Code>
                {url && <StyledQRCode value={url} size={100} />}
                <Url>{url}</Url>
              </Label>
            )}
          </React.Fragment>
        );
      }}
    </Query>
  );
};

const Label = styled.div`
  color: black;
  margin: 10px auto;
  border: 1px solid black;
  width: 21cm;
  background: white;
  display: grid;
  grid-template-columns: 2.5cm auto 1.5cm 3cm;
  grid-template-rows: 1cm 1cm 1cm;
  grid-column-gap: 0.5cm;
  grid-template-areas:
    'logo title shelving qrcode'
    'logo code code qrcode'
    'logo url url qrcode';
`;

const LogoContainer = styled.div`
  grid-area: logo;
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
  background: black;
  display: flex;
`;

const Logo = styled.img`
  width: 100%;
  padding: 10px;
`;

const Code = styled.div`
  grid-area: code;
  place-self: center;
  font-size: 16pt;
  font-weight: bold;
`;

const Title = styled.div`
  grid-area: title;
  place-self: center left;
  font-size: 12pt;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Url = styled.div`
  grid-area: url;
  place-self: center;
  font-size: 12pt;
`;

const Shelving = styled.div`
  grid-area: shelving;
  place-self: center;
  font-size: 12pt;
`;

const StyledQRCode = styled(QRCodeCanvas)`
  grid-area: qrcode;
  place-self: center;
`;

export default (withRouter(ArtifactLabelPrint): any);
