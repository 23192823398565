// @flow
import GET_LANGUAGE_QUERY from '../queries/getLanguage';

const navigatorLanguage: string = localStorage.getItem('userLanguage') || navigator.language.split(/[-_]/)[0] || 'en';

const changeLanguage = {
  defaults: {
    language: navigatorLanguage,
  },
  resolvers: {
    Mutation: {
      ChangeLanguage: (_: any, { language }: { language: string }, { cache }: { cache: Object }): null => {
        cache.writeQuery({
          query: GET_LANGUAGE_QUERY,
          data: {
            language,
          },
        });
        localStorage.setItem('userLanguage', language);

        return null;
      },
    },
  },
};

export default changeLanguage;
