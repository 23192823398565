// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';
import { withApollo } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

import ArtifactsArchaeologicalTable from './ArtifactsArchaeologicalTable';
import Breakpoints from '../../../../../lib/constants/breakpoints';

import type {
  ArtifactType, UserType, StringArtifactType, ArtifactFilterUrlType,
} from '../../../../../lib/types';
import artifactDataTranslationsMessages from '../../../../../lib/constants/artifactDataTranslationsMessages';
import { accessibility } from '../../../../../lib/constants/themeModes';
import StatusFormatter from '../../../../../components/StatusFormatter';

// Queries & Mutation
// eslint-disable-next-line max-len
import GET_ARTIFACTS_ARCHAEOLOGICAL_LIST_COLUMNS_QUERY from '../../../../../lib/queries/getArtifactsArchaeologicalListColumns';

import {
  saveColumnsInLocalState,
  filterColumnsNotForAdmin,
  makeValueTrueFromArrayOfString,
  switchDefaultColumnsBaseOnUrl,
} from '../ArtifactsTableUtils';
// eslint-disable-next-line max-len
import SET_ARCHAEOLOGICAL_ARTIFACTS_LIST_COLUMNS from '../../../../../lib/mutations/setArtifactsArchaeologicalListColumns';

import prepareColumnSortOrder from '../../../../../lib/utils/prepareColumnSortOrder';

import ColumnsList from '../../../../../components/ColumnsList';
import { columnsSettings } from '../../../../../lib/constants/artifactsListFloatingColumns';
import Buttons from '../Buttons';
import withUser from '../../../../../components/withUser';
import TranslatedValue from '../../../../../components/TranslatedValue/TranslatedValue';
import { darkBlueColor, biggerFontSize } from '../../../../../lib/style/themes';

type PropsType = {
  className: string,
  client: any,
  data: {
    allArtifacts: { data: ArtifactType[] },
    error: string,
    loading: boolean,
    orderedColumns: Array<string>,
    selectedColumns: Array<string>,
  },
  filter: ArtifactFilterUrlType,
  type: string,
  user: UserType,
};

export type DragSortingRecordType = {
  key: string,
  name: string,
};

type OnDragSortingTableSelectChangeType = (selectedRowKeys: string[]) => void;

export type DragSortingSelectedRowsType = {
  onChange: OnDragSortingTableSelectChangeType,
  selectedRowKeys: Array<string>,
};

type HandleDragSortingSubmitReturnType = Promise<void>;
export type HandleDragSortingSubmitType = ({
  orderedColumns: Array<string>,
  selectedColumns: Array<string>,
}) => HandleDragSortingSubmitReturnType;

export type OnDragSortingTableRowType = (
  record: DragSortingRecordType,
  _index: number
) => { index: number, moveRow: (dragIndex: number, hoverIndex: number) => void };

export type ArtifactsTableHeaderType = {
  dataIndex: string,
  key: string,
  render?: Function,
  title: React.Element<string>,
};

export type AllArtifactsQueryVariablesType = {
  azp?: boolean,
  city?: boolean,
  community?: boolean,
  county?: boolean,
  local_name?: boolean,
  ordinal_number_in_azp?: boolean,
  ordinal_number_in_city?: boolean,
  physicalgeographical_unit?: boolean,
  voivodeship?: boolean,
};

type StateType = {
  allArtifactsQueryVariables: {
    azp?: boolean,
    city?: boolean,
    community?: boolean,
    county?: boolean,
    local_name?: boolean,
    ordinal_number_in_azp?: boolean,
    ordinal_number_in_city?: boolean,
    physicalgeographical_unit?: boolean,
    voivodeship?: boolean,
  },
  artifactsTableHeader: ArtifactsTableHeaderType[],
  columnSortOrder: ?string,
  isInitialized: boolean,
  limit: number,
  orderedColumns: Array<string>,
  pageNumber: number,
  selectedColumns: Array<string>,
};

type TabIndexType = {
  tabIndex: string,
};

class ArtifactsArchaeologicalTableContainer extends React.PureComponent<PropsType, StateType> {
  static prepareArtifactsTableBody = (data: ArtifactType[]): StringArtifactType[] => {
    const artifactsTableBody = data.map(
      (artifact: ArtifactType): StringArtifactType => ({
        type: <FormattedMessage {...artifactDataTranslationsMessages[artifact.type]} />,
        catalog_id: artifact.catalog_id || '-',
        city: (artifact.city && <TranslatedValue value={artifact.city} />) || '-',
        id: artifact.id || '-',
        key: artifact.id || '-',
        title: artifact.title || '-',
        voivodeship: (artifact.voivodeship && <TranslatedValue value={artifact.voivodeship} />) || '-',
        county: (artifact.county && <TranslatedValue value={artifact.county} />) || '-',
        community: (artifact.community && <TranslatedValue value={artifact.community} />) || '-',
        natural_resource: (artifact.natural_resource && <TranslatedValue value={artifact.natural_resource} />) || '-',
        archaeological_culture:
          (artifact.archaeological_culture && <TranslatedValue value={artifact.archaeological_culture} />) || '-',
        artifact_type: (artifact.artifact_type && <TranslatedValue value={artifact.artifact_type} />) || '-',
        azp: (artifact.azp && <TranslatedValue value={artifact.azp} />) || '-',
        ordinal_number_in_azp: artifact.ordinal_number_in_azp || '-',
        chronology: artifact.chronology || '-',
        ordinal_number_in_city: artifact.ordinal_number_in_city || '-',
        created_at: artifact.created_at || '-',
        updated_at: artifact.updated_at || '-',
        published_at: artifact.published_at || '-',
        status: <StatusFormatter status={artifact.status} />,
        created_by_email: artifact.created_by_email || '-',
        short_catalog_id: artifact.short_catalog_id || '-',
        shelving_number: artifact.shelving_number || '-',
        shelf_number: artifact.shelf_number || '-',
      }),
    );

    return artifactsTableBody;
  };

  static prepareArtifactsTableHeader = (
    orderedColumns: Array<string>,
    selectedColumns: Array<string>,
    user: UserType,
  ): ArtifactsTableHeaderType[] => {
    const artifactsTableHeader = orderedColumns
      .filter((element: string): boolean => selectedColumns.includes(element))
      .map(
        (element: string): ArtifactsTableHeaderType => ({
          key: element,
          title: <FormattedMessage {...artifactDataTranslationsMessages[element]} />,
          dataIndex: element,
          sorter: true,
          ...(columnsSettings[element] && { align: columnsSettings[element].align }),
          onCell: (): TabIndexType => ({ tabIndex: '0' }),
          onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
        }),
      );

    artifactsTableHeader.unshift(ArtifactsArchaeologicalTableContainer.artifactsTableHeaderId);
    artifactsTableHeader.push(ArtifactsArchaeologicalTableContainer.artifactsTableHeaderCta(user));

    return artifactsTableHeader;
  };

  static artifactsTableHeaderCta = (user: UserType): Object => ({
    key: 'cta',
    title: <FormattedMessage {...artifactDataTranslationsMessages.cta} />,
    dataIndex: 'cta',
    render: (rowText: string, record: ArtifactType): React.Node => <Buttons record={record} user={user} />,
    onCell: (): TabIndexType => ({ tabIndex: '0' }),
    onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
  });

  state = {
    orderedColumns: [],
    selectedColumns: [],
    artifactsTableHeader: [],
    allArtifactsQueryVariables: {},
    columnSortOrder: null,
    limit: 50,
    pageNumber: 1,
    isInitialized: false,
  };

  async componentDidMount(): Promise<void> {
    this.initializeArtifactsTable();
  }

  getColumnsQuery = async (): Promise<{
    columnSortOrder: string,
    orderedColumns: Array<string>,
    selectedColumns: Array<string>,
  }> => {
    const { client } = this.props;

    const columnsList = await client.query({
      query: GET_ARTIFACTS_ARCHAEOLOGICAL_LIST_COLUMNS_QUERY,
      fetchPolicy: 'cache-only',
    });

    const {
      data: {
        artifactsArchaeologicalListColumns: { orderedColumns, selectedColumns, columnSortOrder },
      },
    } = columnsList;

    // We need to remove columns not for admin if user is logged out
    const filteredOrderedColumns = filterColumnsNotForAdmin(orderedColumns);
    const filteredSelectedColumns = filterColumnsNotForAdmin(selectedColumns);

    return {
      columnSortOrder,
      orderedColumns: filteredOrderedColumns,
      selectedColumns: filteredSelectedColumns,
    };
  };

  initializeArtifactsTable = async (): Promise<void> => {
    const { orderedColumns, selectedColumns, columnSortOrder } = await this.getColumnsQuery();

    const { user } = this.props;

    const selectedColumnsBaseOnUrl = switchDefaultColumnsBaseOnUrl(selectedColumns);
    const artifactsTableHeader = ArtifactsArchaeologicalTableContainer.prepareArtifactsTableHeader(
      orderedColumns,
      selectedColumnsBaseOnUrl,
      user,
    );

    this.setState({
      orderedColumns,
      selectedColumns: selectedColumnsBaseOnUrl,
      artifactsTableHeader,
      columnSortOrder,
      isInitialized: true,
    });
  };

  updateArtifactsTable: Function = async ({
    orderedColumns,
    selectedColumns,
  }: any): Promise<void> => {
    const { user } = this.props;

    const artifactsTableHeader = ArtifactsArchaeologicalTableContainer.prepareArtifactsTableHeader(
      orderedColumns,
      selectedColumns,
      user,
    );

    let allArtifactsQueryVariables = orderedColumns.filter(
      (element: string): boolean => selectedColumns.includes(element),
    );

    allArtifactsQueryVariables = makeValueTrueFromArrayOfString(allArtifactsQueryVariables);
    this.setState({
      artifactsTableHeader,
      allArtifactsQueryVariables,
      orderedColumns,
      selectedColumns,
      pageNumber: 1,
    });
  };

  updatePageNumber = () => {
    const { pageNumber } = this.state;

    this.setState({ pageNumber: pageNumber + 1 });
  };

  handleDragSortingSubmit = async ({
    orderedColumns,
    selectedColumns,
  }: any): HandleDragSortingSubmitReturnType => {
    if (window.location.pathname === '/artifacts/list/archaeological') {
      await saveColumnsInLocalState({ selectedColumns, orderedColumns }, SET_ARCHAEOLOGICAL_ARTIFACTS_LIST_COLUMNS);
    }
    this.updateArtifactsTable({ orderedColumns, selectedColumns });
  };

  handleArtifactsTableSort = async (_: Object, __: Object, sorter: { field: string, order: string }): Promise<void> => {
    const columnSortOrder = prepareColumnSortOrder(sorter);
    if (!columnSortOrder) return;

    if (window.location.pathname === '/artifacts/list/archaeological') {
      await saveColumnsInLocalState({ columnSortOrder }, SET_ARCHAEOLOGICAL_ARTIFACTS_LIST_COLUMNS);
    }

    this.setState({
      columnSortOrder,
      pageNumber: 1,
    });
  };

  static artifactsTableHeaderId = {
    key: 'id',
    title: <FormattedMessage {...artifactDataTranslationsMessages.id} />,
    dataIndex: 'id',
    onCell: (): TabIndexType => ({ tabIndex: '0' }),
    onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
  };

  render(): React.Node {
    const { className, filter, type } = this.props;
    const {
      selectedColumns,
      orderedColumns,
      artifactsTableHeader,
      allArtifactsQueryVariables,
      columnSortOrder,
      limit,
      pageNumber,
      isInitialized,
    } = this.state;

    return (
      <React.Fragment>
        <ColumnsList
          handleDragSortingSubmit={this.handleDragSortingSubmit}
          selectedColumns={selectedColumns}
          orderedColumns={orderedColumns}
        />
        <StyledEditColumnsButton id="edit-columns-button" />
        {isInitialized && (
          <ArtifactsArchaeologicalTable
            artifactsTableHeader={artifactsTableHeader}
            allArtifactsQueryVariables={allArtifactsQueryVariables}
            columnSortOrder={columnSortOrder}
            limit={limit}
            updatePageNumber={this.updatePageNumber}
            pageNumber={pageNumber}
            filter={filter}
            selectedColumns={selectedColumns}
            prepareArtifactsTableBody={ArtifactsArchaeologicalTableContainer.prepareArtifactsTableBody}
            className={className}
            handleArtifactsTableSort={this.handleArtifactsTableSort}
            orderedColumns={orderedColumns}
            type={type}
          />
        )}
      </React.Fragment>
    );
  }
}

const ArtifactsTableWithApollo = withUser(withApollo(ArtifactsArchaeologicalTableContainer));

const StyledEditColumnsButton = styled.div`
  float: right;
  margin-left: 16px;
  @media (max-width: ${Breakpoints.mobile}) {
    display: none;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow !important;
      background-color: ${darkBlueColor} !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: black !important;
        background-color: white !important;
        border: 2px solid red !important;
      }
    `};
`;

const StyledArtifactsTable: any = styled(ArtifactsTableWithApollo)`
  .ant-table td {
    white-space: nowrap;
  }

  td: focus, th: focus {
    outline: none;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-table-thead > tr > th {
        background: yellow;
        color: ${darkBlueColor};
        &:focus {
          border: 4px solid red !important;
        }
      }
      td,
      th {
        border: 2px solid black;
        font-size: ${biggerFontSize};
        color: black;
        background: white;
        &:focus {
          border: 4px solid red !important;
        }
      }
      ::selection {
        background: yellow;
        color: black;
      }

      .ant-table-tbody > tr > td.ant-table-column-sort {
        background: white;
      }
    `};
`;

export default StyledArtifactsTable;
