// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { message } from 'antd';
import AcceptRejectBar from './AcceptRejectBar';
import CHANGE_ARTIFACT_STATUS from '../../../../lib/mutations/changeArtifactStatus';
import ARTIFACT_STATUS from '../../../../lib/constants/artifactStatus';
import withRouter from '../../../../lib/utils/withRouter';

type PropsType = {
  client: any,
  intl: typeof IntlShape,
  navigate: any,
  params: {
    artifactId: string,
  },
};

class AcceptRejectBarContainer extends React.PureComponent<PropsType, null> {
  accept = async (): Promise<void> => {
    const {
      client,
      intl,
      params: { artifactId },
      navigate,
    } = this.props;

    await client.mutate({
      mutation: CHANGE_ARTIFACT_STATUS,
      variables: { id: artifactId, status: ARTIFACT_STATUS.archived },
    });

    const translatedMessage = intl.formatMessage(AcceptRejectBarContainer.translations.draftAccepted);
    message.success(translatedMessage);
    navigate(-1);
  };

  reject = async (values: {}): Promise<void> => {
    const {
      client,
      intl,
      params: { artifactId },
      navigate,
    } = this.props;

    await client.mutate({
      mutation: CHANGE_ARTIFACT_STATUS,
      variables: { ...values, id: artifactId, status: ARTIFACT_STATUS.rejected },
    });

    const translatedMessage = intl.formatMessage(AcceptRejectBarContainer.translations.draftRejected);
    message.success(translatedMessage);
    navigate(-1);
  };

  static translations = defineMessages({
    draftRejected: {
      defaultMessage: 'Zabytek został odrzucony',
      id: 'AcceptRejectBarContainer.draftRejected',
    },
    draftAccepted: {
      defaultMessage: 'Zabytek zaostał opublikowany i jest widoczne dla wszystkich',
      id: 'AcceptRejectBarContainer.draftAccepted',
    },
  });

  render(): React.Node {
    return <AcceptRejectBar accept={this.accept} reject={this.reject} />;
  }
}

export default (withApollo(withRouter(injectIntl(AcceptRejectBarContainer))): any);
