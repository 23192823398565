export default {
  new: 'NEW',
  draft: 'DRAFT',
  ready: 'READY',
  archived: 'ARCHIVED',
  rejected: 'REJECTED',
  deleted: 'DELETED',
  rented: 'RENTED',
  destroyed: 'DESTROYED',
  missing: 'MISSING',
  delegated: 'DELEGATED',
  hidden: 'HIDDEN',
};
