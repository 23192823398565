// @flow
import type { RentalsType, RentalsInputType } from '../../types';

const parseRentals = (rentals: RentalsType[]): RentalsInputType[] => {
  if (rentals) {
    return rentals.map(
      (rental: RentalsType): RentalsInputType => ({
        id: rental.id,
        rental_date: rental.rental_date.format('YYYY-MM-DD'),
        renter: rental.renter,
        return_date: rental.return_date && rental.return_date.format('YYYY-MM-DD'),
      }),
    );
  }

  return [];
};

export default parseRentals;
