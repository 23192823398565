// @flow
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  Card, Row, Col, Input,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import MonumentOtherSection from './MonumentOtherSection';
// Own Types
import type { OtherMassArtifactElementInputType } from '../../../lib/types';

const FormItem = Form.Item;

type PropTypes = {
  animalBones: number | null,
  buildingCeramicsFragments: number | null,
  clayDishesFragments: number | null,
  flints: number | null,
  getFieldDecorator: Function,
  getFieldsValue: Function,
  getFieldValue: Function,
  glassFragments: number | null,
  glassSlag: number | null,
  humanBones: number | null,
  ironSlag: number | null,
  leatherObjectFragments: number | null,
  metalObjects: number | null,
  otherMassArtifactElements: OtherMassArtifactElementInputType[],
  setFieldsValue: Function,
  shells: number | null,
  stoveTilesFragments: number | null,
  threshingFloors: number | null,
};

const KZMMonumentSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator,
    getFieldsValue,
    getFieldValue,
    setFieldsValue,
    animalBones,
    buildingCeramicsFragments,
    clayDishesFragments,
    flints,
    glassFragments,
    glassSlag,
    humanBones,
    ironSlag,
    leatherObjectFragments,
    metalObjects,
    otherMassArtifactElements,
    shells,
    stoveTilesFragments,
    threshingFloors,
  } = props;

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          <FormattedMessage id="KZMMonumentSection.title" defaultMessage="Zabytek" />
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem
            label={(
              <FormattedMessage
                id="KZMMonumentSection.fragmentsClayVessels"
                defaultMessage="Fragmenty naczyń glinianych"
              />
)}
          >
            {getFieldDecorator('clay_dishes_fragments', {
              initialValue: clayDishesFragments || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem
            label={(
              <FormattedMessage
                id="KZMMonumentSection.fragmentsStoveTiles"
                defaultMessage="Fragmenty kafli piecowych"
              />
)}
          >
            {getFieldDecorator('stove_tiles_fragments', {
              initialValue: stoveTilesFragments || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem
            label={(
              <FormattedMessage
                id="KZMMonumentSection.fragmentsBuildingCeramics"
                defaultMessage="Fragmenty ceramiki budowlanej"
              />
)}
          >
            {getFieldDecorator('building_ceramics_fragments', {
              initialValue: buildingCeramicsFragments || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.polepa" defaultMessage="Polepa" />}>
            {getFieldDecorator('threshing_floors', {
              initialValue: threshingFloors || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.humanBones" defaultMessage="Kości ludzkie" />}>
            {getFieldDecorator('human_bones', {
              initialValue: humanBones || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.animalBones" defaultMessage="Kości zwierzęce" />}>
            {getFieldDecorator('animal_bones', {
              initialValue: animalBones || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.shells" defaultMessage="Muszle" />}>
            {getFieldDecorator('shells', {
              initialValue: shells || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.ironSlag" defaultMessage="Żużle żelazne" />}>
            {getFieldDecorator('iron_slag', {
              initialValue: ironSlag || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.glassSlag" defaultMessage="Żużle szklane" />}>
            {getFieldDecorator('glass_slag', {
              initialValue: glassSlag || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem
            label={<FormattedMessage id="KZMMonumentSection.glassFragments" defaultMessage="Fragmenty szkła" />}
          >
            {getFieldDecorator('glass_fragments', {
              initialValue: glassFragments || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem
            label={<FormattedMessage id="KZMMonumentSection.metalObjects" defaultMessage="Przedmioty metalowe" />}
          >
            {getFieldDecorator('metal_objects', {
              initialValue: metalObjects || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem label={<FormattedMessage id="KZMMonumentSection.flints" defaultMessage="Krzemienie" />}>
            {getFieldDecorator('flints', {
              initialValue: flints || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <FormItem
            label={(
              <FormattedMessage
                id="KZMMonumentSection.leatherObjectFragments"
                defaultMessage="Fragmenty wyrobów skórzanych"
              />
)}
          >
            {getFieldDecorator('leather_object_fragments', {
              initialValue: leatherObjectFragments || null,
            })(<Input type="number" />)}
          </FormItem>
        </Col>
      </Row>
      <MonumentOtherSection
        getFieldsValue={getFieldsValue}
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        getFieldDecorator={getFieldDecorator}
        otherMassArtifactElements={otherMassArtifactElements}
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default KZMMonumentSection;
