// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  FormattedMessage, defineMessages, injectIntl, IntlShape,
} from 'react-intl';
import {
  Card, Row, Col, Input,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

const FormItem = Form.Item;

const translations = defineMessages({
  romanNumber: {
    defaultMessage: 'Cyfra rzymska',
    id: 'DiscoveryDetailsSection.placeholder.romanNumber',
  },
  oneNumber: {
    defaultMessage: 'Cyfra arabska lub mała litera alfabetu',
    id: 'DiscoveryDetailsSection.placeholder.oneNumber',
  },
  oneBigLetter: {
    defaultMessage: 'Wielka litera alfabetu',
    id: 'DiscoveryDetailsSection.placeholder.oneBigLetter',
  },
  numberOrLetter: {
    defaultMessage: 'Cyfra arabska lub litera alfabetu',
    id: 'DiscoveryDetailsSection.placeholder.numberOrLetter',
  },
  arabicNumber: {
    defaultMessage: 'Cyfra arabska',
    id: 'DiscoveryDetailsSection.placeholder.arabicNumber',
  },
  coordinates: {
    defaultMessage: 'Wartości w zapisie N/E (stopnie, minuty, sekundy)',
    id: 'DiscoveryDetailsSection.placeholder.coordinates',
  },
});

type PropTypes = {
  are: string,
  areQuarter: string,
  coordinates: string,
  excavation: string,
  formType: string,
  getFieldDecorator: Function,
  hectare: string,
  intl: typeof IntlShape,
  mechanicalLayer: string,
  naturalLayer: string,
  section: string,
};

const DiscoveryDetailsSection = (props: PropTypes): React.Node => {
  const {
    getFieldDecorator,
    intl,
    formType,
    excavation,
    section,
    hectare,
    are,
    areQuarter,
    naturalLayer,
    mechanicalLayer,
    coordinates,
  } = props;

  const romanNumberPlaceholder = intl.formatMessage(translations.romanNumber);
  const oneNumberPlaceholder = intl.formatMessage(translations.oneNumber);
  const oneBigLetterPlaceholder = intl.formatMessage(translations.oneBigLetter);
  const numberOrLetter = intl.formatMessage(translations.numberOrLetter);
  const arabicNumber = intl.formatMessage(translations.arabicNumber);
  const coordinatesPlaceholder = intl.formatMessage(translations.coordinates);

  return (
    <StyledCard
      bordered={false}
      title={(
        <div className="title">
          <FormattedMessage
            id="DiscoveryDetailsSection.title"
            defaultMessage="Miejsce odkrycia - informacje szczegółowe"
          />
        </div>
)}
    >
      <Row gutter={48} type="flex" align="middle">
        <Col xs={24} sm={12} lg={8}>
          <FormItem label={<FormattedMessage id="DiscoveryDetailsSection.excavation" defaultMessage="Wykop" />}>
            {getFieldDecorator('excavation', {
              initialValue: excavation || null,
            })(<Input placeholder={romanNumberPlaceholder} />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <FormItem label={<FormattedMessage id="DiscoveryDetailsSection.section" defaultMessage="Odcinek" />}>
            {getFieldDecorator('section', {
              initialValue: section || null,
            })(<Input placeholder={oneNumberPlaceholder} />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <FormItem label={<FormattedMessage id="DiscoveryDetailsSection.hectare" defaultMessage="Hektar" />}>
            {getFieldDecorator('hectare', {
              initialValue: hectare || null,
            })(<Input placeholder={romanNumberPlaceholder} />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <FormItem label={<FormattedMessage id="DiscoveryDetailsSection.are" defaultMessage="Ar" />}>
            {getFieldDecorator('are', {
              initialValue: are || null,
            })(<Input type="number" placeholder={arabicNumber} />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <FormItem label={<FormattedMessage id="DiscoveryDetailsSection.areQuarter" defaultMessage="Ćwiartka ara" />}>
            {getFieldDecorator('are_quarter', {
              initialValue: areQuarter || null,
            })(<Input placeholder={oneBigLetterPlaceholder} />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <FormItem
            label={<FormattedMessage id="DiscoveryDetailsSection.naturalLayer" defaultMessage="Warstwa naturalna" />}
          >
            {getFieldDecorator('natural_layer', {
              initialValue: naturalLayer || null,
            })(<Input placeholder={numberOrLetter} />)}
          </FormItem>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <FormItem
            label={
              <FormattedMessage id="DiscoveryDetailsSection.mechanicalLayer" defaultMessage="Warstwa mechaniczna" />
            }
          >
            {getFieldDecorator('mechanical_layer', {
              initialValue: mechanicalLayer || null,
            })(<Input placeholder={numberOrLetter} />)}
          </FormItem>
        </Col>
        {formType === 'KZW' && (
          <React.Fragment>
            <Col xs={24} lg={16}>
              <FormItem
                label={(
                  <FormattedMessage
                    id="DiscoveryDetailsSection.coordinates"
                    defaultMessage="Współrzędne geograficzne"
                  />
)}
              >
                {getFieldDecorator('coordinates', {
                  initialValue: coordinates || null,
                })(<Input placeholder={coordinatesPlaceholder} />)}
              </FormItem>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

export default (injectIntl(DiscoveryDetailsSection): any);
