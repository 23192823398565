// @flow
import * as React from 'react';
import queryString from 'query-string';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import ArtifactsList from './ArtifactsList';
import ARTIFACT_STATUS from '../../lib/constants/artifactStatus';
import ErrorPage from '../ErrorPage';
import USER_LEVEL from '../../lib/constants/userLevel';
import withRouter from '../../lib/utils/withRouter';

type PropsType = {
  location: Location,
  params: { type: string }
};

class Artifacts extends React.PureComponent<PropsType, void> {
  getFilterFromUrl: () => ?any = (): ?Object => {
    const {
      location: { search },
    } = this.props;

    const filter = this.addStatusPublishedFilterIfTableView();

    return { ...filter, ...queryString.parse(search) };
  };

  addStatusPublishedFilterIfTableView: () => any = (): Object => {
    const {
      location: { pathname },
    } = this.props;

    if (pathname === '/artifacts/list/historical' || pathname === '/artifacts/list/archaeological') {
      return {
        status: [ARTIFACT_STATUS.archived, ARTIFACT_STATUS.rented],
      };
    }

    return {};
  };

  renderRoute() {
    const filter = this.getFilterFromUrl();

    const {
      params: { type },
    } = this.props;

    switch (type) {
      case 'administration':
      case 'my-work':
        return (
          <Route
            index
            element={(
              <PrivateRoute>
                <ArtifactsList filter={filter} type={type} />
              </PrivateRoute>
            )}
          />
        );
      case 'moderation':
        return (
          <Route
            index
            element={(
              <PrivateRoute level={USER_LEVEL.superAdmin}>
                <ArtifactsList filter={filter} type={type} />
              </PrivateRoute>
            )}
          />
        );
      default:
        return <Route index element={<ArtifactsList filter={filter} type={type} />} />;
    }
  }

  render(): React.Node {
    return (
      <Routes>
        {this.renderRoute()}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
  }
}

export default (withRouter(Artifacts): any);
