// @flow
import type { PhotoType } from '../../types';

const parsePhotos = (
  photos: PhotoType[],
  photosForm: PhotoType[],
): { mainPhoto: PhotoType | null, photos: PhotoType[] } => {
  const sortedPhotos = [];
  if (photos && photosForm) {
    photos.forEach((photo: PhotoType) => {
      photosForm.forEach((photoForm: PhotoType, index: number) => {
        if (photo.id === photoForm.id) {
          sortedPhotos[index] = { id: photo.id, description: photo.description, path: photo.path };
        }
      });
    });

    return { mainPhoto: sortedPhotos[0], photos: sortedPhotos ? sortedPhotos.splice(1) : [] };
  }

  return { mainPhoto: null, photos: [] };
};

export default parsePhotos;
