// @flow
const tabKeyCode = 9;

export const blockTabbingBackwards = (event: KeyboardEvent) => {
  if (event.keyCode === tabKeyCode && event.shiftKey) {
    event.preventDefault();
  }
};

export const blockTabbingForward = (event: KeyboardEvent) => {
  if (event.keyCode === tabKeyCode && !event.shiftKey) {
    event.preventDefault();
  }
};
