// @flow
export const pageContainerStyle = `
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media print {
    max-width: 20cm;
    margin: 0 auto;
  }
`;

export const navigationHeight = `
  74px
`;
