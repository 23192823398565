// @flow
import * as React from 'react';
import { Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';
import formItemLayout from '../../lib/constants/formItemLayout';
// Own types
import type { ValidationRulesType } from '../../lib/types';
import ARTIFACT_STATUS from '../../lib/constants/artifactStatus';

type PropsType = {
  getFieldDecorator: Function,
  initialFieldValue: string,
  // eslint-disable-next-line react/require-default-props
  label?: string,
  // eslint-disable-next-line react/require-default-props
  mode?: string,
  name: string,
  // eslint-disable-next-line react/require-default-props
  validationRules?: ValidationRulesType,
};

const { Option } = Select;

const StatusSelect = (props: PropsType): React.Node => {
  const FormItem = Form.Item;
  const {
    label = '', name, getFieldDecorator, validationRules = [], initialFieldValue, mode = 'multiple',
  } = props;

  return (
    <FormItem {...formItemLayout} label={label}>
      {getFieldDecorator(name, {
        rules: validationRules,
        initialValue: initialFieldValue,
      })(
        <Select allowClear={mode === 'multiple'} mode={mode}>
          <Option value={ARTIFACT_STATUS.draft}>
            <FormattedMessage id="general.status.draft" defaultMessage="Szkic" />
          </Option>
          <Option value={ARTIFACT_STATUS.ready}>
            <FormattedMessage id="general.status.ready" defaultMessage="Do akceptacji" />
          </Option>
          <Option value={ARTIFACT_STATUS.rejected}>
            <FormattedMessage id="general.status.rejected" defaultMessage="Odrzucony" />
          </Option>
          <Option value={ARTIFACT_STATUS.archived}>
            <FormattedMessage id="general.status.archived" defaultMessage="Archiwum" />
          </Option>
          <Option value={ARTIFACT_STATUS.missing}>
            <FormattedMessage id="general.status.missing" defaultMessage="Zaginiony" />
          </Option>
          <Option value={ARTIFACT_STATUS.destroyed}>
            <FormattedMessage id="general.status.destroyed" defaultMessage="Zniszczony" />
          </Option>
          <Option value={ARTIFACT_STATUS.hidden}>
            <FormattedMessage id="general.status.hidden" defaultMessage="Ukryty" />
          </Option>
          <Option value={ARTIFACT_STATUS.rented}>
            <FormattedMessage id="general.status.rented" defaultMessage="Wypożyczony" />
          </Option>
          <Option value={ARTIFACT_STATUS.delegated}>
            <FormattedMessage id="general.status.delegated" defaultMessage="Przekazany" />
          </Option>
        </Select>,
      )}
    </FormItem>
  );
};

export default StatusSelect;
