// @flow
// React
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { message } from 'antd';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
// Queries and mutation
import ADD_MASS_ARITFACT from '../../../lib/mutations/addMassArtifact';
// Components
import KZMForm from '../../../components/Forms/KZMForm';
import LoadingSpin from '../../../components/LoadingSpin';
import BreadCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';
// Own Types
import type { KZMType } from '../../../lib/types';

import parseOther from '../../../lib/utils/parseOther';
import parseNotes from '../../../lib/utils/parseNotes';
import parseRentals from '../../../lib/utils/parseRentals';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  client: any,
  intl: typeof IntlShape,
  navigate: any,
};

type StateType = {
  updating: boolean,
};

export class KZMNew extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    updating: false,
  };

  addNewKZM: (formValues: KZMType, afterSave: string) => Promise<void> = async (
    formValues: KZMType,
    afterSave: string,
  ): Promise<void> => {
    const { intl, client, navigate } = this.props;

    this.setState({ updating: true });
    const response = await client.mutate({
      mutation: ADD_MASS_ARITFACT,
      variables: {
        ...formValues,
        other_mass_artifact_elements: parseOther(formValues.other_mass_artifact_elements),
        notes: parseNotes(formValues.notes),
        shelf_number: parseInt(formValues.shelf_number, 10),
        shelving_number: parseInt(formValues.shelving_number, 10),
        are: parseInt(formValues.are, 10),
        archaeological_culture_id: parseInt(formValues.archaeological_culture_id, 10),
        chronology_id: parseInt(formValues.chronology_id, 10),
        object_number: parseInt(formValues.object_number, 10),
        glass_fragments: parseInt(formValues.glass_fragments, 10),
        clay_dishes_fragments: parseInt(formValues.clay_dishes_fragments, 10),
        stove_tiles_fragments: parseInt(formValues.stove_tiles_fragments, 10),
        building_ceramics_fragments: parseInt(formValues.building_ceramics_fragments, 10),
        threshing_floors: parseInt(formValues.threshing_floors, 10),
        human_bones: parseInt(formValues.human_bones, 10),
        animal_bones: parseInt(formValues.animal_bones, 10),
        shells: parseInt(formValues.shells, 10),
        iron_slag: parseInt(formValues.iron_slag, 10),
        glass_slag: parseInt(formValues.glass_slag, 10),
        metal_objects: parseInt(formValues.metal_objects, 10),
        flints: parseInt(formValues.flints, 10),
        leather_object_fragments: parseInt(formValues.leather_object_fragments, 10),
        rentals: parseRentals(formValues.rentals),
        research_date: formValues.research_date && formValues.research_date.format('YYYY-MM-DD'),
      },
    });
    this.setState({ updating: false });

    if ('errors' in response) {
      return;
    }

    const translatedSuccessMessage = intl.formatMessage(KZMNew.translations.submitSuccessMessage);
    if (afterSave === 'list') {
      navigate('/artifacts/list/archaeological');
    } else if (response && response.data && response.data.addMassArtifact) {
      navigate(`/admin/massartifact/${String(response.data.addMassArtifact.id)}/edit`);
    }
    message.success(translatedSuccessMessage);
  };

  static translations: any = defineMessages({
    submitSuccessMessage: {
      defaultMessage: 'Zabytek masowy został prawidłowo dodany',
      id: 'KZMNew.submitSuccessMessage',
    },
    submitErrorMessage: {
      defaultMessage: 'Błąd walidacji formularza, sprawdź wszystkie pola',
      id: 'KZMNew.errorMessage',
    },
  });

  render(): React.Node {
    const { intl } = this.props;
    const { updating } = this.state;
    const submitErrorMessage = intl.formatMessage(KZMNew.translations.submitErrorMessage);

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['admin', 'addKZM']} />
        {updating && <LoadingSpin tip="Loading..." />}
        {!updating && <KZMForm onSubmit={this.addNewKZM} submitErrorMessage={submitErrorMessage} />}
      </React.Fragment>
    );
  }
}

export default (withRouter(withApollo(injectIntl(withUser(KZMNew)))): any);
