// @flow

const getFilterType = (type: string): string[] => {
  switch (type) {
    case 'historical':
      return ['HISTORICAL'];
    case 'archaeological':
      return ['SEPARATED', 'MASS'];
    default:
      return ['SEPARATED', 'MASS', 'HISTORICAL'];
  }
};

export default getFilterType;
